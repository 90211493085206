import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import CustomModal from '../../modules/CustomModal/modal'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DTemplateMakeReq, magicnum} from '../../../services/Request'
import {CDN_URL, DTemplateMake, DTemplateMakelistByUser} from '../../../services/main'
import {KTIcon} from '../../../_metronic/helpers'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {isNullOrEmpty} from '../../../services/Utils'
import ModalImage from 'react-modal-image'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const UsersTemplateCreated: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState<any>(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showFilter, setShowFilter] = useState(false)
  const [uid, setUid] = useState('')
  const [prevImage, setPrevImage] = useState('')
  const [imageModal, setImageModal] = useState(false)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DTemplateMakeReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.uid = uid
    DTemplateMakelistByUser(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const linkRowFormat = (cell: any, row: any) => {
    return (
      <Link to={'/user/' + row.uid}>
        <p className='text-center'>{cell}</p>
      </Link>
    )
  }

  const clearFilter = () => {
    setUid('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'name',
        dataField: 'name',
        dataSort: false,
        dataFormat: linkRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'niit',
        dataField: 'niit',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {props.type === 'component' ? null : (
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:TEMPLATE.templates')}</PageTitle>
      )}
      {imageModal ? (
        <CustomModal
          visible={imageModal}
          closeModal={() => setImageModal(false)}
          className='VideoModal'
        >
          <img style={{objectFit: 'cover'}} src={CDN_URL + item} width='100%' />
        </CustomModal>
      ) : null}
      {!isNullOrEmpty(prevImage) ? (
        <ModalImage small={prevImage} large={prevImage} alt='Hello World!' />
      ) : null}
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              style={{cursor: 'pointer'}}
              className='filter flex items-center justify-center gap-0'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <div onClick={list} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body flex gap-4 py-3'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Uid'
                  value={uid}
                  onChange={(e) => setUid(e.target.value)}
                />
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:TEMPLATE.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:TEMPLATE.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {UsersTemplateCreated}
