import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {SearchListReq, magicnum} from '../../../services/Request'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {SearchList} from '../../../services/main'
import {toast} from 'react-toastify'
import {dateTimeFormat} from '../../../services/Utils'
import {KTIcon} from '../../../_metronic/helpers'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}
const SearchPage: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(50)
  const [data, setData] = useState([])
  const [uid, setUid] = useState('')
  const [nodata, setNodata] = useState('')

  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = SearchListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.uid = props.type == 'component' ? props.uid : uid
    req.filter.result_count = nodata

    SearchList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>{cell}</div>
  }
  const userRowFormat = (cell: any, row: any) => {
    return (
      <Link to={`/user/${cell}`} className='flex justify-center'>
        {cell}
      </Link>
    )
  }
  const dateRowFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>{dateTimeFormat(cell)}</div>
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const clearFilter = () => {
    setUid('')
    setNodata('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'User',
        dataField: 'uid',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Text',
        dataField: 'text',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Date',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Result count',
        dataField: 'result_count',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Result_Search</PageTitle>

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            onClick={() => setShowFilter(!showFilter)}
            className=' cursor-pointer filter flex items-center justify-center gap-0'
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row flex items-center filter-wrap'>
              <div className='col'>
                <select onChange={(e)=>setNodata(e.target.value)} className='form-select form-select-sm form-select-solid' name='' id=''>
                  <option value=''>All</option>
                  <option value='0'>No result</option>
                </select>
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Result_Search list</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} searchs</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable data={data} table={table} PTotal={PTotal} PID={PID} loading={loading} />
        </div>
      </div>
    </>
  )
}

export {SearchPage}
