import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty, isValidEmail} from '../../../services/Utils'
import {DesignerAdd, DesignerBan, DesignerUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {DesignerAddReq, DesignerBanReq, DesignerUpdateReq} from '../../../services/Request'

interface IProps {
  t: any
  item: any
  onBanSuccess:any
  setBanShowModal: any
}
const UserBan: FC<IProps> = ({t, item, onBanSuccess, setBanShowModal}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [id, setId] = useState('')
  const [level, setLevel] = useState('')
  const [validate, setValidate] = useState<any>([])

  useEffect(() => {
    setId(item?.id)
  }, [item])

  const userBan = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = DesignerBanReq
      req.designer.id = item?.id
      req.designer.level = level

      DesignerBan(req, banSuccess, onFailed, 'PUT')
    }
  }
  const banSuccess = () => {
    setLaoding(false)
    onBanSuccess('Хэрэглэгч амжилттай бандлаа')
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(id)) {
      res.retType = 1
      validate['id'] = true
    }

    if (isNullOrEmpty(level)) {
      res.retType = 1
      validate['level'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:USERS.ban')}</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:USERS.ban')}
          </span>
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:USERS.uid')}
            onChange={(e) => {
              setId(e.target.value)
              validateChange('id', e)
            }}
            disabled
            value={id}
          />
        </div>
        <div className='mb-4'>
        <select
            onChange={(e) => {
              setLevel(e.target.value)
              validateChange('provider', e)
            }}
            className='form-select form-select-sm form-select-solid'
            name='level'
            id=''
            value={level}
          >
            <option value='0'>{t('common:USERS.unban')}</option>
            <option value='-1'>{t('common:USERS.uban')}</option>
          </select>
          
        </div>

        <div className='pt-10 flex gap-4' style={{justifyContent:"end"}}>
          <button
            className='btn btn-secondary align-self-center'
            onClick={()=>setBanShowModal(false)}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:cancel')}
          </button>
          <button
            className='btn btn-primary align-self-center'
            onClick={userBan}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:confirm')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {UserBan}
