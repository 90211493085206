import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {BaseRequest, EventUserListReq} from '../../../services/Request'
import {EventUserDelete, EventUserList} from '../../../services/main'
import {t} from 'i18next'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {encodeString, isNullOrEmpty} from '../../../services/Utils'
import {KTIcon} from '../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import { useParams } from 'react-router-dom'

const EventUsers: FC = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = EventUserListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.event_id = id?.toString()as string
    EventUserList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const idRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{encodeString(cell.toString())}</span>
  }
  const colorRowFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>
      <div style={{backgroundColor:cell , width:"40px", height:"26px", borderRadius:"4px"}}></div>
    </div>
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    EventUserDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        {/* <div
          onClick={() => {
            navigator.clipboard.writeText(templateId)
            return toast.success('Хуулагдлаа', {
              autoClose: 1000,
              hideProgressBar: true,
            })
          }}
        >
          <div
            onClick={() =>
              setTemplateId(
                'https://grado.mn/templates/CO' + Base64.encode(row.id?.toString(), true) + '-next'
              )
            }
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='copy' className='fs-3' />
          </div>
        </div> */}
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Id',
        dataField: 'id',
        dataSort: false,
        dataFormat: idRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Name',
        dataField: 'name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'E-mail',
        dataField: 'email',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
      },
      {
        title: 'Type name',
        dataField: 'type_name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Type color',
        dataField: 'type_color',
        dataSort: false,
        dataFormat: colorRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{t('common:TEMPLATE.list')}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {t('common:TEMPLATE.total', {count: PTotal})}
          </span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <DataTable
          data={data}
          table={table}
          PTotal={PTotal}
          pageChange={onChangePage}
          PID={PID}
          loading={loading}
        />
      </div>
    </div>
  )
}

export {EventUsers}
