import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";


const MonthlyStatsChart = ({ data }) => {
  const { t } = useTranslation();
  {t(`common:qr-analyses.daygraph`)}
  const options = {
    title: "QR уншуулалт хугацаагаар",
    curveType: "function",
    legend: "none",
    lineWidth: 3,
    colors: ["#ed6386"],
  };
  return (
    <Chart
      width={"100%"}
      height={"300px"}
      chartType="LineChart"
      loader={<div>{t(`common:qr-analysis.loading`)}</div>}
      data={data}
      options={options}
    />
  );
};

export default MonthlyStatsChart;
