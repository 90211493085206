import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {EmployeeCreate} from './add'
import {dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  EmployeeChangeStatusReq,
  NewsChangeStatusReq,
  NewsListReq,
  listEmployeeReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  EmployeeChangeStatus,
  EmployeeDelete,
  NewsChangeStatus,
  NewsDelete,
  NewsList,
  listEmployee,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Employee: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = listEmployeeReq
    req.pid = PID
    req.pSize = sizePerPage
    listEmployee(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.mEmployees)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <>{cell}</>
  }

  const imgRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center'>
        <img
          src={CDN_URL + cell}
          height={40}
          width={40}
          style={{borderRadius: '50%', objectFit: 'cover'}}
        />
      </div>
    )
  }

  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-end'>
        <Link to={`/employeemore/${cell}?title=${row.firstname}`}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <Link className='cursor-pointer' to={`/employeemore/${row.id}?title=${row.firstname}`}>
        {cell}
      </Link>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center cursor-pointer' onClick={() => submit(row, 'changeStaus')}>
        {cell == magicnum.Status.InActive ? (
          <span className='badge badge-light-warning'>{t('common:magicnum.Status.InActive')}</span>
        ) : (
          <span className='badge badge-light-success'>{t('common:magicnum.Status.Active')}</span>
        )}
      </div>
    )
  }

 

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    EmployeeDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = EmployeeChangeStatusReq
    req.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.status = magicnum.Status.Active
    }
    if (row.status == magicnum.Status.Active) {
      req.status = magicnum.Status.InActive
    }

    EmployeeChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:employee.photo'),
        dataField: 'photo',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:employee.email'),
        dataField: 'email',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
     
      {
        title: t('common:employee.firstname'),
        dataField: 'firstname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:employee.lastname'),
        dataField: 'lastname',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:employee.type'),
        dataField: 'typeid',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:employee.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateTimeFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:employee.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:employee.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:employee.pagetitle')}</PageTitle>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <EmployeeCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a href='#' className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? <div className='card-body py-3'>d</div> : null}
      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:employee.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:employee.total', {count: PTotal})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:employee.add')}
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Employee}
