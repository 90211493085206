import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  dateAgo,
  dateFormat,
  dateTimeFormat,
  isNullOrEmpty,
  moneyFormat,
} from '../../../services/Utils'
import {
  BaseRequest,
  LetterBannerListReq,
  NewsChangeStatusReq,
  magicnum,
} from '../../../services/Request'
import {LetterBannerDelete, LetterBannerList, NewsChangeStatus} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import {LetterEdit} from './add'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const LetterBannerLast: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [id, setId] = useState('')
  const [tolov, setTolov] = useState('')

  useEffect(() => {
    list()
  }, [PID, props])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = LetterBannerListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.pid = id
    if (!isNullOrEmpty(props.uid)) {
      req.filter.uid = props.uid
    } else {
      req.filter.uid = ''
    }
    LetterBannerList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const TimeRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{dateTimeFormat(cell)}</div>
  }
  
  const activeRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center'>
        {cell == 1 ? (
          <span className='badge badge-light-primary'>{t('common:LETTERBANNER.active')}</span>
        ) : (
          <span className='badge badge-light-warning'>{t('common:LETTERBANNER.inactive')}</span>
        )}
      </div>
    )
  }
  const templateRowFormat = (cell: any, row: any) => {
    return (
      <Link to={`/letterbanner/${row.id}`}>
        <div className='text-center'>
          <img width={50} height={50} src={`/letters/${cell}.jpg`} alt='' />
        </div>
      </Link>
    )
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: 105}}>
        <Link to={`/letterbanner/${cell}`}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    LetterBannerDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = NewsChangeStatusReq
    req.News.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.News.status = magicnum.Status.Active
    }
    if (row.status == magicnum.Status.Active) {
      req.News.status = magicnum.Status.InActive
    }

    NewsChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  const onAddSuccess = () => {
    updateShowModal(false)
    list()
  }
  const clearFilter = () => {
    setYear('')
    setMonth('')
    list()
  }

  const ugRowFormat = (cell: any, row: any) => {
    return <div style={{textAlign: 'left'}}>{cell}</div>
  }

  const userRowFormat = (cell: any, row: any) => {
    return (
      <div style={{textAlign: 'left'}}>
        <Link to={'/user/' + row.uid}>{cell}</Link>
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:LETTERBANNER.pid'),
        dataField: 'pid',
        dataSort: false,
        dataFormat: templateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.active'),
        dataField: 'tolov',
        dataSort: false,
        dataFormat: activeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.uname'),
        dataField: 'uname',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.ug'),
        dataField: 'ug',
        dataSort: false,
        dataFormat: ugRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: TimeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <LetterEdit t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {props.type === 'component' ? null : (
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>
          {t('common:LETTERBANNER.pagetitle')}
        </PageTitle>
      )}
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              className='filter flex items-center justify-center gap-0 cursor-pointer'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <a onClick={list} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    className='form-control form-control-solid'
                    id='name'
                    type='text'
                    onChange={(e) => setId(e.target.value)}
                    placeholder='Template id'
                    value={id}
                  />
                </div>
                <div className='col'>
                  <input
                    className='form-control form-control-solid'
                    id='name'
                    type='text'
                    onChange={(e) => setTolov(e.target.value)}
                    placeholder='Is Active'
                    value={tolov}
                  />
                </div>
                {/* <div className='col'>
              <select
                onChange={(e) => {
                  setDay(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='main_type'
                value={day}
                id=''
              >
                <option value=''>{t('common:all')}</option>
                <option value='1'>23</option>
                <option value='2'>22</option>
                <option value='2'>21</option>
                <option value='2'>20</option>
                <option value='2'>19</option>
              </select>
            </div>
            <div className='col flex gap-4 items-center'>
              <select
                onChange={(e) => {
                  setMonth(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='rel_type'
                value={month}
                id=''
              >
                <option value=''>{t('common:all')}</option>
                <option value='elements'>{t('common:FEEDBACK.element')}</option>
                <option value='template'>{t('common:FEEDBACK.template')}</option>
              </select>
            </div> */}
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:LETTERBANNER.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:LETTERBANNER.total', {count: PTotal})}
            </span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {LetterBannerLast}
