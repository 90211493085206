/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {AuthReq} from '../../../../services/Request'
import {loginGetToken} from '../../../../services/main'
import CryptoJS from 'crypto-js'
import config from '../../../../services/config.json'
import useCookie from '../../../../services/useCookie'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {isNullOrEmpty} from '../../../../services/Utils'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const [SystemToken, updateSystemToken] = useCookie('GASystemToken', null)
  const [Data1, updateData1] = useCookie('GAData1', null)
  const [EmployeeID, updateEmployeeID] = useCookie('GAEmployeeID', null)
  const [EmployeeTypeID, updateEmployeeTypeID] = useCookie('GAEmployeeTypeID', null)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        var request = AuthReq
        request.Username = CryptoJS.AES.encrypt(values.email, config.engryptPass).toString()
        request.Password = CryptoJS.AES.encrypt(values.password, config.engryptPass).toString()
        if (!isNullOrEmpty(values.email) && !isNullOrEmpty(values.password)) {
          loginGetToken(request, onSuccess, onFailed, 'POST')
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'AUTH.INCORRECT'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const onSuccess = (response: any) => {
    var priv = CryptoJS.AES.encrypt(JSON.stringify(response.privs), config.engryptPass).toString()
    var employeeData = CryptoJS.AES.encrypt(
      JSON.stringify(response.data),
      config.engryptPass
    ).toString()
    saveAuth({api_token: response.token, refreshToken: response.token})
    setCurrentUser(response.data)
    updateSystemToken(response?.token, 1)
    localStorage.setItem('GAdata2', priv)   
    updateData1(employeeData, 1)
    updateEmployeeID(response.data.id, 1)
    updateEmployeeTypeID(response.data.typeid, 1)
    // redirect()
    window.location.replace('/dashboard')
    setLoading(false)
  }

  // const redirect = () => {
  //   if(!isNullOrEmpty(SystemToken) && !isNullOrEmpty(EmployeeID)){
      
  //   }    
  // }

  const onFailed = (err: string) => {
    saveAuth(undefined)
    setLoading(false)
    return toast.error(err)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{t('main:login.email')}</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          {t('main:login.password')}
        </label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />       
      </div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{t('main:login.btn')}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {t('main:login.loading')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>    
    </form>
  )
}
