import {FC, useEffect, useRef, useState} from 'react'
import {encodeString, isNullOrEmpty, isValidEmail} from '../../../../services/Utils'
import {
  DesignerAdd,
  DesignerUpdate,
  QRTravelRouteAdd,
  QRTravelRoutePointDelete,
  QRTravelRoutePointList,
  QRTravelRoutePointUpdate,
  QRTravelRoutePointadd,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import {
  BaseRequest,
  DesignerAddReq,
  DesignerUpdateReq,
  QRTravelRouteListReq,
  QRTravelRoutePointListReq,
  QRTravelRoutePointUpdateReq,
  QRTravelRoutePointaddReq,
  magicnum,
} from '../../../../services/Request'
import {QRCode} from 'react-qrcode-logo'
import {toPng} from 'html-to-image'
import {confirmAlert} from 'react-confirm-alert'
import {Spinner} from 'react-bootstrap'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const AddStops: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [loading, setLoading] = useState(false)
  const [loadingtatah, setLoadingtatah] = useState(false)
  const [qrAppear, setQrAppear] = useState<any>(null)

  const [validate, setValidate] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [count, setCount] = useState(0)
  const elementRef2 = useRef<any>(null)

  const [rows, setRows] = useState<any>([
    {id: 1, rid: item?.id, daraalal: '', name: '', distance: 0, tid: ''},
  ])
  useEffect(() => {
    list()
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(qrAppear)) {
      htmlToImageConvert()
    }
  }, [qrAppear])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = QRTravelRoutePointListReq
    req.filter.rid = item?.id
    QRTravelRoutePointList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }
  const add = () => {
    setLoading(true)
    var req = QRTravelRoutePointaddReq
    req.route.id = item?.id
    req.route.points = rows
    QRTravelRoutePointadd(req, onRegisterSuccess, onFailed, 'POST')
  }
  const update = (item: any) => {
    setLoading(true)
    var req = QRTravelRoutePointUpdateReq
    req.point.id = item.id
    req.point.daraalal = item.daraalal
    req.point.name = item.name
    req.point.distance = item.distance
    req.point.tid = item.tid
    QRTravelRoutePointUpdate(req, onUpdateSuccess, onFailed, 'PUT')
  }

  const onRegisterSuccess = () => {
    list()
    setLoading(false)
    onAddSuccess('Шинэ хэрэглэгч амжилттай нэмлээ')
  }
  const onUpdateSuccess = () => {
    setLoading(false)
    toast.success('Амжилттай хадгалагдлаа')
  }

  const onFailed = (err: string) => {
    setLoading(false)
    return toast.error(err)
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }
  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      rid: item?.id,
      daraalal: '',
      name: '',
      distance: 0,
      tid: '',
    }
    setRows([...rows, newRow])
  }
  const changeStopsId = (value: any, id: any) => {
    var tempMas: any = []
    rows.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.daraalal = value
      }
      tempMas.push(tempobj)
    })
    setRows(tempMas)
  }
  const changeStopsNumber = (value: any, id: any) => {
    var tempMas: any = []
    rows.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.name = value
      }
      tempMas.push(tempobj)
    })
    setRows(tempMas)
  }
  const changeTravelId = (value: any, id: any) => {
    var tempMas: any = []
    rows.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.tid = value
      }
      tempMas.push(tempobj)
    })
    setRows(tempMas)
  }
  const changeDistance = (value: any, id: any) => {
    var tempMas: any = []
    rows.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.distance = value
      }
      tempMas.push(tempobj)
    })
    setRows(tempMas)
  }
  const deleteRow = (id: any) => {
    setRows(rows.filter((row: any) => row.id !== id))
  }
  const editdaraalal = (value: any, id: any) => {
    var tempMas: any = []
    data.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.daraalal = value
      }
      tempMas.push(tempobj)
    })
    setData(tempMas)
  }
  const editName = (value: any, id: any) => {
    var tempMas: any = []
    data.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.name = value
      }
      tempMas.push(tempobj)
    })
    setData(tempMas)
  }
  const editdistance = (value: any, id: any) => {
    var tempMas: any = []
    data.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.distance = value
      }
      tempMas.push(tempobj)
    })
    setData(tempMas)
  }
  const editStopId = (value: any, id: any) => {
    var tempMas: any = []
    data.map((uu: any) => {
      var tempobj = uu
      if (uu.id == id) {
        tempobj.tid = value
      }
      tempMas.push(tempobj)
    })
    setData(tempMas)
  }
  const deleteFunc = (id: any) => {
    QRTravelRoutePointDelete(id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const htmlToImageConvert = () => {
    setLoadingtatah(true)
    toPng(elementRef2.current, {cacheBust: false})
      .then((data: any) => {
        const link = document.createElement('a')
        link.download = 'e-guide.mn/' + item.name + '.png'
        link.href = data
        link.click()
        setLoadingtatah(false)
      })
      .catch((err: any) => {
        console.log(err)
        setLoadingtatah(false)
      })
  }
  return (
    <div>
      {!isNullOrEmpty(qrAppear) ? (
        <div ref={elementRef2} style={{zIndex: -1, left: '0', top: '0', position: 'absolute'}}>
          <QRCode
            value={
              'https://e-guide.mn/r/' +
              encodeString(item?.id.toString()) +
              '~' +
              encodeString(qrAppear?.tid.toString())
            }
            size={850}
            quietZone={25}
            ecLevel='M'
          />
          {/* <div className="bg-white w-full h-[600px] absolute left-0 top-0"></div> */}
        </div>
      ) : null}

      <div
        style={{backgroundColor: 'white', minHeight: '850px'}}
        className=' pt-10 pb-10 px-16 modal-width'
      >
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Add Stops</h1>
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>Route stops</span>
        </div>
        {data.map((i: any) => {
          return (
            <div className='flex gap-4 mb-2 cursor-pointer' style={{alignItems: 'center'}}>
              <button
                disabled={loadingtatah}
                onClick={() => {
                  setQrAppear(null)
                  setQrAppear(i)
                }}
                style={{padding: '0', border: 'none', backgroundColor: 'white'}}
              >
                {loadingtatah ? (
                  <Spinner animation='border' />
                ) : (
                  <QRCode
                    value={
                      'https://e-guide.mn/r/' +
                      encodeString(item?.id.toString()) +
                      '~' +
                      encodeString(i?.tid.toString())
                    }
                    size={50}
                    ecLevel='L'
                  />
                )}
              </button>

              <div className=''>
                <input
                  value={i.daraalal}
                  onChange={(e) => {
                    editdaraalal(e.target.value, i.id)
                  }}
                  type='number'
                  placeholder='Дараалал'
                  className='form-control form-control-solid w-1/5'
                />
              </div>

              <div className=''>
                <input
                  value={i.name}
                  onChange={(e) => {
                    editName(e.target.value, i.id)
                  }}
                  type='text'
                  placeholder='Зогсоолын код'
                  className='form-control form-control-solid w-1/5'
                />
              </div>

              <div style={{position: 'relative'}}>
                <input
                  value={i.distance}
                  onChange={(e) => {
                    editdistance(e.target.value, i.id)
                  }}
                  type='text'
                  placeholder='Зай'
                  className='form-control form-control-solid w-90px'
                />
                <p style={{position: 'absolute', top: '13px', right: '10px', fontSize: '14px'}}>
                  km
                </p>
              </div>

              <div className=''>
                <input
                  value={i.tid}
                  onChange={(e) => {
                    editStopId(e.target.value, i.id)
                  }}
                  type='number'
                  placeholder='Цэгийн ID'
                  className='form-control form-control-solid w-1/5'
                />
              </div>

              <div
                style={{backgroundColor: '#f9f9f9', borderRadius: '4px', height: '44px'}}
                className='flex justify-center items-center cursor-pointer w-60px'
                onClick={() => update(i)}
              >
                <i style={{fontSize: '18px'}} className='fa-solid fa-pencil'></i>
              </div>
              <div
                style={{backgroundColor: '#f9f9f9', borderRadius: '4px', height: '44px'}}
                className='flex justify-center items-center cursor-pointer w-60px'
                onClick={() => deleteFunc(i?.id)}
              >
                <i
                  className='fa-regular fa-trash-can '
                  style={{color: 'red', fontSize: '18px'}}
                ></i>
              </div>
            </div>
          )
        })}

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>add stops</span>
        </div>
        {rows.map((item: any) => {
          return (
            <div className='flex gap-4 mb-4' style={{width: '100%'}}>
              <div className=''>
                <input
                  value={item.daraalal}
                  onChange={(e) => {
                    changeStopsId(e.target.value, item.id)
                    validateChange('stopsId', e)
                  }}
                  type='number'
                  placeholder='Дараалал'
                  className='form-control form-control-solid w-1/5'
                />
              </div>

              <div className=''>
                <input
                  value={item.name}
                  onChange={(e) => {
                    changeStopsNumber(e.target.value, item.id)
                    validateChange('stopsNumber', e)
                  }}
                  type='text'
                  placeholder='Зогсоолын код'
                  className='form-control form-control-solid w-1/5'
                />
                {validate['stopsNumber'] ? (
                  <div className='fv-plugins-message-container'>
                    <p className='fv-help-block'>Маршрутын өнгө заавал оруулна оруулна уу !</p>
                  </div>
                ) : null}
              </div>

              <div style={{position: 'relative'}}>
                <input
                  value={item.distance}
                  onChange={(e) => {
                    changeDistance(e.target.value, item.id)
                  }}
                  type='text'
                  placeholder='маршрут дахь дугаар'
                  className='form-control form-control-solid w-1/5'
                />
                <p style={{position: 'absolute', top: '10px', right: '20px', fontSize: '16px'}}>
                  km
                </p>
              </div>

              <div className=''>
                <input
                  value={item.tid}
                  onChange={(e) => {
                    changeTravelId(e.target.value, item.id)
                    validateChange('travelId', e)
                  }}
                  type='number'
                  placeholder='Travel дахь id'
                  className='form-control form-control-solid w-150px'
                />
                {validate['travelId'] ? (
                  <div className='fv-plugins-message-container'>
                    <p className='fv-help-block'>Маршрутын өнгө заавал оруулна оруулна уу !</p>
                  </div>
                ) : null}
              </div>
              <div
                style={{backgroundColor: '#f9f9f9', borderRadius: '4px'}}
                className='flex justify-center w-60px items-center cursor-pointer'
                onClick={() => deleteRow(item.id)}
              >
                <i
                  className='fa-regular fa-trash-can '
                  style={{color: 'red', fontSize: '18px'}}
                ></i>
              </div>
            </div>
          )
        })}

        <div
          className='w-full mt-4 flex'
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <button onClick={addRow} className='btn btn-light-primary'>
            add row
          </button>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={add} disabled={loading}>
            {loading ? t('common:loading') : 'Save'}
          </button>
        </div>
      </div>
    </div>
  )
}

export {AddStops}
