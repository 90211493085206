import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {
  BaseRequest,
  OrgLogosChangeStatusReq,
  OrgLogosListReq,
  magicnum,
} from '../../../../services/Request'
import {
  CDN_URL,
  OrglogosChangeStatus,
  OrglogosDelete,
  OrglogosList,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import LogoAdd from './add'
import {KTIcon} from '../../../../_metronic/helpers'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, dateTimeFormat, isNullOrEmpty} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'
import {OrgLogoChange} from '../changelogo'
import ModalImage from 'react-modal-image'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Organization',
    path: '/orgs',
    isSeparator: false,
    isActive: false,
  },
]

interface Props {
  uid?: any
  type?: string
}

const OrgLogos: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState('')
  const [data, setData] = useState<any>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState(false)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [org_id, setOrg_id] = useState('')
  const [position, setPosition] = useState('')
  const [lang, setLang] = useState('')
  const [colorType, setColorType] = useState('')
  const [logoType, setLogoType] = useState('')
  const [changelogoModal, setChangelogoModal] = useState(false)

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = OrgLogosListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.org_id = isNullOrEmpty(props.uid) ? org_id : props.uid
    req.filter.position = position
    req.filter.lang = lang
    req.filter.logo_type = logoType
    req.filter.color_type = colorType

    OrglogosList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onAddSuccess = (response: any) => {
    setLoading(false)
    setShowModal(false)
    setChangelogoModal(false)
    list()
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    OrglogosDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = OrgLogosChangeStatusReq
    req.org.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.org.status = JSON.stringify(magicnum.Status.Active)
    }
    if (row.status == magicnum.Status.Active) {
      req.org.status = JSON.stringify(magicnum.Status.InActive)
    }
    OrglogosChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setOrg_id('')
    setPosition('')
    setLang('')
    setLogoType('')
    list()
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center'>
        <span>{row.logo.split('.')[1]}</span>
        {cell}
      </div>
    )
  }
  const logoRowFormat = (cell: any, row: any) => {
    return (
      <div className='w-80px max-h-80px text-center'>
        <ModalImage
          className='max-h-80px '
          small={CDN_URL + cell}
          large={CDN_URL + cell}
          alt={row.id}
        />
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        {' '}
        <span
          onClick={() => submit(row, 'changeStatus')}
          style={{cursor: 'pointer'}}
          className={`badge ${cell == '0' ? 'badge-light-warning' : 'badge-light-success'}`}
        >
          {t(`common:magicnum.BrandbookLogos.status.${cell}`)}
        </span>
      </div>
    )
  }
  const positionRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        {t(`common:magicnum.BrandbookLogos.position.${cell}`)}
      </div>
    )
  }
  const langRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>{t(`common:magicnum.BrandbookLogos.lang.${cell}`)}</div>
    )
  }
  const colorTypeRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        {t(`common:magicnum.BrandbookLogos.colorType.${cell}`)}
      </div>
    )
  }
  const logoTypeRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        {t(`common:magicnum.BrandbookLogos.logoType.${cell}`)}
      </div>
    )
  }
  const defaultdateFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>{dateFormat(cell)}</div>
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div style={{minWidth: '105px'}} className='text-center'>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setChangelogoModal(true)
          }}
        >
          <KTIcon iconName='picture' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'PrivNo',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:ORG.image'),
        dataField: 'logo',
        dataSort: false,
        dataFormat: logoRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      props.type != 'component'
        ? {
            title: t('common:ORG.org_id'),
            dataField: 'org_id',
            dataSort: false,
            dataFormat: defaultRowFormat,
            hidden: false,
            filter: {
              type: 'TextFilter',
              placeholder: '...',
            },
          }
        : '',

      {
        title: t('common:USERS.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:ORG.position'),
        dataField: 'position',
        dataSort: false,
        dataFormat: positionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:ORG.lang'),
        dataField: 'lang',
        dataSort: false,
        dataFormat: langRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:ORG.color_type'),
        dataField: 'color_type',
        dataSort: false,
        dataFormat: colorTypeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:ORG.logo_type'),
        dataField: 'logo_type',
        dataSort: false,
        dataFormat: logoTypeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: defaultdateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <LogoAdd t={t} id={props?.uid} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {changelogoModal ? (
        <CustomModal
          visible={changelogoModal}
          closeModal={() => setChangelogoModal(false)}
          className='VideoModal'
        >
          <OrgLogoChange t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('ORG.logo_list')}</PageTitle>
      {props.type != 'component' ? (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <a onClick={() => list()} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    type='number'
                    onChange={(e) => setOrg_id(e.target.value)}
                    placeholder='org id'
                    value={org_id}
                  />
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setPosition(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='position'
                    value={position}
                  >
                    <option value=''>{t('common:ORG.select_pos')}</option>
                    {magicnum.BrandbookLogos.position.map((pitem) => {
                      return (
                        <option value={pitem}>
                          {t(`common:magicnum.BrandbookLogos.position.${pitem}`)}
                        </option>
                      )
                    })}
                  </select>
                  {/* <input className='form-control form-control-solid' type='text' /> */}
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setLang(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='lang'
                    id=''
                    value={lang}
                  >
                    <option value=''>{t('common:ORG.select_lang')}</option>
                    {magicnum.BrandbookLogos.lang.map((pitem) => {
                      return (
                        <option value={pitem}>
                          {t(`common:magicnum.BrandbookLogos.lang.${pitem}`)}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setColorType(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='colorType'
                    value={colorType}
                  >
                    <option value=''>{t('common:ORG.select_colortype')}</option>
                    {magicnum.BrandbookLogos.colorType.map((pitem) => {
                      return (
                        <option value={pitem}>
                          {t(`common:magicnum.BrandbookLogos.colorType.${pitem}`)}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setLogoType(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='logoType'
                    value={logoType}
                  >
                    <option value=''>{t('common:ORG.select_logotype')}</option>
                    {magicnum.BrandbookLogos.logoType.map((pitem) => {
                      return (
                        <option value={pitem}>
                          {t(`common:magicnum.BrandbookLogos.logoType.${pitem}`)}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-sm btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:ORG.logo_list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:ORG.total_logos', {count: data.length})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem('')
                setShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:ORG.add_logo')}
            </a>
          </div>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {OrgLogos}
