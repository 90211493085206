import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  SizeUnits,
  dateFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
} from '../../../services/Utils'
import {
  BaseRequest,
  DTempleteChangeStatusReq,
  DTempleteListReq,
  FlipbookListReq,
  FlipbookStatListReq,
  QRListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  DDesigners,
  DTemapleteChangeStatus,
  DTemapleteDelete,
  DTemapleteListWithTracking,
  FlipbookList,
  FlipbookStatList,
  QRList,
  deleteFlipbook,
  deleteQRCode,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
import {FlipbookEdit} from './edit'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const Flipbook: FC<Props> = (props) => {
  const [params, setParams] = useSearchParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [orgModal, setOrgModal] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [sub_category, setSub_category] = useState('')
  const [status, setStatus] = useState<any>('')
  const [qr_type, setQr_type] = useState('')
  const [uid, setUid] = useState('')
  const [tagModal, setTagModal] = useState(false)
  const [designerList, setDesignerList] = useState([])
  const [templateId, setTemplateId] = useState('')
  useEffect(() => {
    list()
  }, [PID])
  console.log(data)
  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = FlipbookListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.flipbook_type = qr_type
    req.filter.status = status
    req.filter.uid = props.type == 'component' ? props.uid : uid
    FlipbookList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const userRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center'>
        <Link to={`/user/${cell}`} className='flex justify-center'>
          {cell}
        </Link>
        <span>{row.totalview} views</span>
      </div>
    )
  }
  const sizeRowFormat = (cell: string, row: any) => {
    return <div className='flex justify-center'>{SizeUnits(cell)}</div>
  }
  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <span>{cell}</span>
        <a target='_blank' href={'https://flipbook.grado.mn/v/' + encodeString(row.id.toString())}>
          {'https://flipbook.grado.mn/v/' + encodeString(row.id.toString())}
        </a>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center gap-2 justify-center'>
        <span
          onClick={() => submit(row, 'changeStatus')}
          style={{cursor: 'pointer'}}
          className={`badge ${cell == '0' ? 'badge-light-warning' : 'badge-light-success'}`}
        >
          {t(`common:magicnum.BrandbookLogos.status.${cell}`)}
        </span>
        <span>{dateFormat(row.created_at)}</span>
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <Link to={'/flipbook/' + row.id}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    deleteFlipbook(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = FlipbookStatListReq
    // req.desgin.id = row.id
    // if (row.status == 0) {
    //   req.desgin.status = JSON.stringify(1)
    // }
    // if (row.status == 1) {
    //   req.desgin.status = JSON.stringify(0)
    // }

    FlipbookStatList(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Flipbook name',
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'User',
        dataField: 'uid',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Total page',
        dataField: 'page',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'capacity',
        dataField: 'size',
        dataSort: false,
        dataFormat: sizeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  const closeModal = () => {
    updateShowModal(false)
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <FlipbookEdit t={t} item={item} type='editname' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {/* {orgModal ? (
        <CustomModal
          visible={orgModal}
          closeModal={() => setOrgModal(false)}
          className='VideoModal'
        >
          <TemplateEdit t={t} item={item} type="org" onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}

      {/* {tagModal ? (
        <CustomModal
          visible={tagModal}
          closeModal={() => setTagModal(false)}
          className='VideoModal'
        >
          <TemplateTag t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}
      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Flipbook</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Name'
                      value={name}
                    />
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={category}
                    >
                      <option value=''>{t('common:ELEMENT.select_cat')}</option>
                      {magicnum.Category.map((pitem) => {
                        return (
                          <option value={pitem}>{t(`common:magicnum.Category.${pitem}`)}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setSub_category(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='sub_category'
                      value={sub_category}
                    >
                      <option value=''>{t('common:ELEMENT.select_sub_cat')}</option>
                      {magicnum.SubCategory.filter(
                        (sub: any) => sub.parent.toString() === category
                      ).map((pitem) => {
                        return (
                          <option value={pitem.id}>
                            {t(`common:magicnum.SubCategory.${category}-${pitem.id}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setStatus(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      value={status}
                    >
                      <option value=''>{t('common:employee.selectStatus')}</option>
                      <option value={magicnum.Status.InActive}>
                        {t('common:magicnum.Status.InActive')}
                      </option>
                      <option value={magicnum.Status.Active}>
                        {t('common:magicnum.Status.Active')}
                      </option>
                    </select>
                  </div>
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Flipbook list</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} flipbooks</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Flipbook}
