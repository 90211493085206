import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

const DeviceScanChart = ({ data}) => {
  const { t } = useTranslation();
  return (
    <Chart
      width={"100%"}
      height={"400px"}
      chartType="BarChart"
      loader={<div>{t(`common:qr-analysis.loading`)}</div>}
      data={data}
      options={{
        chartArea: { width: "60%" },
        legend: { position: "none" },
      }}
    />
  );
};

export default DeviceScanChart;
