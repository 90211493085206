import {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {CDN_URL, DTemapleteTagsUpdate, DTemapleteUpdateInfo} from '../../../services/main'
import {toast} from 'react-toastify'
import {DTempleteTagsUpdateReq, DTempleteUpdateInfoReq} from '../../../services/Request'
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const TemplateTag: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [tag, setTag] = useState([])
  const [inptag, setInptag] = useState<any>('')

  console.log(item)
  useEffect(() => {
    setName(item?.name)
    setTag(item?.tags.split(','))
  }, [item])

  useEffect(() => {
    setInptag(tag?.toString())
  }, [tag])

  const update = () => {
    setLaoding(true)
    var req = DTempleteTagsUpdateReq
    req.desgin.tags = tag.toString()
    req.desgin.id = item.id
    DTemapleteTagsUpdate(req, onUpdateSuccess, onFailed, 'PUT')
  }
  const copy = () => {
    var arr:any = []
    inptag.split(',')?.map((item:any) => {
      arr.push(item)
    })
    setTag(arr)
  };

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const handleChange = (tags: any) => {
    setTag(tags)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 modal-width' style={{paddingInline: '50px'}}>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:TEMPLATE.tags', {name: name})}</h1>
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:TEMPLATE.tags', {name: name})}
          </span>
        </div>

        <TagsInput value={tag} onChange={handleChange} />
        <p style={{fontSize: 12, marginTop: 8}}>
          Хамгийн ихдээ 300 үсэг байна, одоо орсон үсгийн тоо : 300 / {tag.toString().length}
        </p>
        <div className='my-4'>
          <input
            className='form-control form-control-solid '
            placeholder={t('common:ORG.name')}
            onChange={(e) => {
              setInptag(e.target.value)
            }}
            value={inptag}
          ></input>
        </div>
        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-secondary align-self-center'
            onClick={copy}
            disabled={loading}
            style={{marginRight: '8px'}}
          >
            {loading ? t('common:loading') : t('common:TEMPLATE.copy')}
          </button>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : t('common:edit')}
          </button>
        </div>
        <div className="my-8 flex justify-center" >
          <img style={{width:"80%"}} src={CDN_URL + item?.template} alt="" />
        </div>
        
        
      </div>
    </div>
  )
}

export {TemplateTag}
