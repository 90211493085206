import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty, isValidEmail} from '../../../services/Utils'
import {DesignerAdd, DesignerChangeOrg, DesignerUpdate, OrgList} from '../../../services/main'
import {toast} from 'react-toastify'
import {DesignerAddReq, DesignerChangeOrgReq, DesignerUpdateReq, OrgListReq} from '../../../services/Request'

interface IProps {
  t: any
  item: any
  onBanSuccess:any
  setOrgidModal: any
}
const PutOrg: FC<IProps> = ({t, item, onBanSuccess, setOrgidModal}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [uid, setUid] = useState('')
  const [org_id, setOrg_id] = useState('')
  const [orglist, setOrglist] = useState([])
  const [validate, setValidate] = useState<any>([])

  useEffect(() => {
    setUid(item?.id)
    setOrg_id(item.org_id)
  }, [item])

  useEffect(()=>{
    list()
  },[])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = DesignerChangeOrgReq
      req.designer.id = uid
      req.designer.org_id = org_id
      DesignerChangeOrg(req, addSuccess, onFailed, 'PUT')
    }
  }
  const addSuccess = () => {
    setLaoding(false)
    onBanSuccess('Хэрэглэгчийн мэдээлэл шинэчлэгдлээ')
  }
  const list = () => {
    if (!loading) {
      setLaoding(true)
    }
    var req = OrgListReq
    OrgList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLaoding(false)
    setOrglist(response.data)
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(uid)) {
      res.retType = 1
      validate['uid'] = true
    }

    if (isNullOrEmpty(org_id)) {
      res.retType = 1
      validate['org_id'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:USERS.update')}</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:USERS.update')}
          </span>
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:USERS.uid')}
            onChange={(e) => {
              setUid(e.target.value)
              validateChange('uid', e)
            }}
            value={uid}
          />
        </div>
        <div className='mb-4'>
          {/* <input
            className='form-control form-control-solid'
            value={org_id}
            placeholder={t('common:ORG.org_id')}
            onChange={(e) => {
              setOrg_id(e.target.value)
              validateChange('org_id', e)
            }}
          /> */}
          <select
                onChange={(e) => {
                  setOrg_id(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                value={org_id}
              >
                <option value={''}>{t('common:validSelect')}</option>
                {orglist.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
        </div>

        <div className='pt-10 flex gap-4' style={{justifyContent:"end"}}>
          <button
            className='btn btn-secondary align-self-center'
            onClick={()=>setOrgidModal(false)}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:cancel')}
          </button>
          <button
            className='btn btn-primary align-self-center'
            onClick={add}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:confirm')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {PutOrg}
