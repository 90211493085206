import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {
  OrgLogosAddReq,
  OrgLogosUpdateReq,
  OrgPptUpdateReq,
  magicnum,
} from '../../../../services/Request'
import {
  CDN_URL,
  OrgPptAdd,
  OrgPptUpdate,
  OrglogosAdd,
  OrglogosUpdate,
} from '../../../../services/main'
import {isNullOrEmpty} from '../../../../services/Utils'
interface IProps {
  t: any
  id: any
  onAddSuccess: any
  item: any
}
const PptAdd: FC<IProps> = ({t, id, onAddSuccess, item}) => {
  const [org_id, setOrg_id] = useState('')
  const [validate, setValidate] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [version, setVersion] = useState('')
  const [ppt, setPpt] = useState<any>('')
  const [count, setCount] = useState(0)
  
  useEffect(() => {
    if (!isNullOrEmpty(item)) {
      setName(item?.name)
      setVersion(item?.version)
    }
  }, [item])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var formdata = new FormData()
      formdata.append('ppt', ppt)
      var org = {
        org_id: isNullOrEmpty(id) ? parseInt(org_id) : id,
        name: name,
        version: version,
      }
      formdata.append('org', JSON.stringify(org))
      OrgPptAdd(formdata, onaddSuccess, onFailed, 'POST', 'file')
    }
  }

  const onaddSuccess = (response: any) => {
    setLoading(false)
    onAddSuccess()
    return toast.success(t('common:successCreate'))
  }

  const edit = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = OrgPptUpdateReq
      req.org.id = item?.id
      req.org.version = version
      req.org.name = name
      OrgPptUpdate(req, onEditSuccess, onFailed, 'PUT')
    }
  }
  const onEditSuccess = (response: any) => {
    setLoading(false)
    onAddSuccess()
    return toast.success(t('common:successUpdate'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }
    if (isNullOrEmpty(version)) {
      res.retType = 1
      validate['version'] = true
    }
    if (isNullOrEmpty(ppt) && isNullOrEmpty(item)) {
      res.retType = 1
      validate['ppt'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  return (
    <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
      <span style={{visibility: 'hidden'}}></span>
      <div className='text-center mb-13'>
        {isNullOrEmpty(item) ? (
          <h1 className='mb-3'>{t('common:ORG.add_ppt')}</h1>
        ) : (
          <h1 className='mb-3'>{t('common:ORG.edit_ppt')}</h1>
        )}
      </div>
      <div className='separator mt-8 d-flex flex-center mb-8'>
        <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
          {isNullOrEmpty(item) ? t('common:ORG.add_ppt') : t('common:ORG.edit_ppt')}
        </span>
      </div>

      {isNullOrEmpty(item) ? (
        isNullOrEmpty(id) ? (
          <div className='mb-4'>
            <input
              className='form-control form-control-solid'
              value={org_id}
              placeholder={t('common:ORG.org_id')}
              onChange={(e) => {
                setOrg_id(e.target.value)
                validateChange('org_id', e)
              }}
            ></input>
            {validate['org_id'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}

      <div className='mb-4'>
        <input
          className='form-control form-control-solid'
          value={name}
          placeholder={t('common:ORG.pptname')}
          onChange={(e) => {
            setName(e.target.value)
            validateChange('name', e)
          }}
        ></input>
        {validate['name'] ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{t('common:validInsert')}</div>
          </div>
        ) : null}
      </div>

      <div className='mb-4'>
        <input
          className='form-control form-control-solid'
          value={version}
          placeholder={t('common:ORG.version')}
          onChange={(e) => {
            setVersion(e.target.value)
            validateChange('version', e)
          }}
        ></input>
        {validate['version'] ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{t('common:validInsert')}</div>
          </div>
        ) : null}
      </div>

      {isNullOrEmpty(item) ? (
        <div className='mb-4'>
          <input
            type='file'
            className='form-control form-control-solid'
            onChange={(e) => {
              setPpt(e.target.files?.[0])
              setValidate((validate['ppt'] = false))
            }}
            accept='.pptx'
          ></input>
          {validate['ppt'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className='pt-10' style={{textAlign: 'right'}}>
        {isNullOrEmpty(item) ? (
          <button className='btn btn-primary align-self-center' onClick={add} disabled={loading}>
            {loading ? t('common:loading') : t('common:save')}
          </button>
        ) : (
          <button className='btn btn-primary align-self-center' onClick={edit} disabled={loading}>
            {loading ? t('common:loading') : t('common:edit')}
          </button>
        )}
      </div>
    </div>
  )
}

export default PptAdd
