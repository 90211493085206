import {FC, memo, useEffect, useState, ReactNode} from 'react'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

interface IProps {
  closeModal: () => void
  visible: boolean
  className: string
  children: ReactNode
}

const CustomModal: FC<IProps> = ({closeModal, visible, className, children}) => {
  const [showModal, setShowModal] = useState<boolean>(visible || false)

  const openHandler = () => {
    setShowModal(true)
  }

  const closeHandler = () => {
    closeModal()
    setShowModal(false)
  }
  const clickOutSideHandler = () => {
    closeModal()
    setShowModal(false)
  }

  useEffect(() => {
    if (visible) openHandler()
  }, [visible])

  return (
    <Modal
      open={showModal}
      onClose={closeHandler}
      onOverlayClick={clickOutSideHandler}
      closeOnEsc
      classNames={{
        overlay: 'customOverlay',
        modal: className,
      }}
    >
      {children}
    </Modal>
  )
}

export default memo(CustomModal)
