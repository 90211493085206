import {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  BaseRequest,
  DesignerListReq,
  NewsChangeStatusReq,
  QRTravelRouteChangestatusReq,
  QRTravelRouteListReq,
  magicnum,
} from '../../../../services/Request'
import {
  CDN_URL,
  ChangePass,
  DesignerDelete,
  DesignerList,
  NewsChangeStatus,
  QRTravelRouteChangestatus,
  QRTravelRouteDelete,
  QRTravelRouteList,
} from '../../../../services/main'
import {KTIcon} from '../../../../_metronic/helpers'
import {dateAgo, dateFormat, isNullOrEmpty} from '../../../../services/Utils'
import {UserCreate} from '../../users/add'
import {Subscription} from '../../subscription'
import {UserBan} from '../../users/ban'
import {PutOrg} from '../../users/putorg'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../../modules/CustomModal/modal'
import {AddRoute} from './add'
import {AddStops} from './addStops'
import {AddMap} from './addmap'
import useCookie from '../../../../services/useCookie'
import {RouteEditCover} from './editCover'
import { EditRoute } from './edit'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
}

const TravelRoute: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [status, setStatus] = useState('')
  const [city, setCity] = useState('')
  const [district, setDistrict] = useState('')
  const [addStopModal, setAddStopModal] = useState(false)
  const [addmapModal, setAddmapModal] = useState(false)
  const [editCoverModal, setEditCoverModal] = useState(false)

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = QRTravelRouteListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.city = city
    req.filter.district = district
    req.filter.uid = ''
    req.filter.status = status
    QRTravelRouteList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const getCity = (id: any) => {
    var name = ''
    magicnum.address_city.map((el) => {
      if (el.id == id) {
        name = el.name
      }
    })
    return name
  }
  const getDistrict = (id: any, cityId: any) => {
    var name = ''
    magicnum.address_district.map((el) => {
      if (el.id == id && el.city_id == cityId) {
        name = el.name
      }
    })
    return name
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const userRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        <Link to={`/user/${cell}`}>{cell}</Link>
      </span>
    )
  }
  const dateRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{dateFormat(cell)}</span>
  }
  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-4'>
        <div style={{display: 'flex', gap: 10}}>
          {isNullOrEmpty(row.cover) ? (
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '10px',
                backgroundColor: '#ecf0f1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {cell.substring(0, 1)}
            </div>
          ) : (
            <div style={{position: 'relative'}}>
              <img
                src={CDN_URL + row.cover}
                width={53}
                height={53}
                style={{borderRadius: '6px', cursor: 'pointer'}}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-8px',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor: '#00000090',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <i
                  onClick={() => {
                    setEditCoverModal(true)
                    setItem(row)
                  }}
                  className='fa-solid fa-rotate fa-xl cursor-pointer '
                  style={{
                    color: 'white',
                  }}
                ></i>
              </div>
            </div>
          )}
          <div
            style={{display: 'flex', flexDirection: 'column', gap: '2px', justifyContent: 'center'}}
          >
            <span style={{fontWeight: '600', fontSize: '14px'}}>{cell}</span>
            <span>
              {getCity(row.city)}- {getDistrict(row.district, row.city)}
            </span>
          </div>
        </div>
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: 215}}>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setEditModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>

        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>

        <div
          title='add stops'
          onClick={() => {
            setItem(row)
            setAddStopModal(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='plus' className='fs-3' />
        </div>
        <div
          title='add map image'
          onClick={() => {
            setItem(row)
            setAddmapModal(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          {isNullOrEmpty(row.map) ? (
            <i className='fa-solid fa-map fa-lg'></i>
          ) : (
            <i className='fa-solid fa-map fa-lg' style={{color: '#63e6be'}}></i>
          )}
        </div>
      </div>
    )
  }
  const StatusRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer'}}>
        <span
          onClick={() => submit(row, 'changeStatus')}
          className={cell === 1 ? 'badge badge-light-primary' : 'badge badge-light-warning'}
        >
          {t(`common:magicnum.StatusS.${cell}`)}
        </span>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    QRTravelRouteDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = QRTravelRouteChangestatusReq
    req.route.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.route.status = magicnum.Status.Active.toString()
    }
    if (row.status == magicnum.Status.Active) {
      req.route.status = magicnum.Status.InActive.toString()
    }
    QRTravelRouteChangestatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const StopsRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-1 text-center'>
        <span> {cell} Stops</span>
        <span>{isNullOrEmpty(row.totaldistance) ? 0 : row.totaldistance}km</span>
      </div>
    )
  }
  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    setAddStopModal(false)
    setAddmapModal(false)
    setEditCoverModal(false)
    setEditModal(false)
    return toast.success(text)
  }
  const clearFilter = () => {
    setCity('')
    setDistrict('')
    setStatus('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Route name',
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Stops',
        dataField: 'totalpoint',
        dataSort: false,
        dataFormat: StopsRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Color',
        dataField: 'color',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Status',
        dataField: 'status',
        dataSort: false,
        dataFormat: StatusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Created by',
        dataField: 'created_by',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <AddRoute t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {addStopModal ? (
        <CustomModal
          visible={addStopModal}
          closeModal={() => setAddStopModal(false)}
          className='VideoModal'
        >
          <AddStops t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {addmapModal ? (
        <CustomModal
          visible={addmapModal}
          closeModal={() => setAddmapModal(false)}
          className='VideoModal'
        >
          <AddMap t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {editCoverModal ? (
        <CustomModal
          visible={editCoverModal}
          closeModal={() => setEditCoverModal(false)}
          className='VideoModal'
        >
          <RouteEditCover t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {editModal ? (
        <CustomModal
          visible={editModal}
          closeModal={() => setEditModal(false)}
          className='VideoModal'
        >
          <EditRoute t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Routes</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <a onClick={() => list()} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setCity(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                  >
                    <option value=''>Аймаг, хот</option>
                    {magicnum.address_city.map((el) => {
                      return <option value={el.id}>{el.name}</option>
                    })}
                  </select>
                </div>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setDistrict(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                  >
                    <option value=''>Сум, дүүрэг</option>
                    {magicnum.address_district
                      .filter((l: any) => l.city_id == city)
                      .map((el, index) => {
                        return (
                          <option value={el.id} key={index}>
                            {el.name}
                          </option>
                        )
                      })}
                  </select>
                </div>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value={magicnum.Status.InActive}>{t('common:USERS.inActive')}</option>
                    <option value={magicnum.Status.Active}>{t('common:USERS.active')}</option>
                  </select>
                </div>
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Travel routes list</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {'Total ' + PTotal + ' routes'}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a route'
          >
            <button
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add route
            </button>
          </div>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {TravelRoute}
