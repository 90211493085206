import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FlipbookStatListReq, QRStatListReq} from '../../../../services/Request'
import {FlipbookStatDeviceList, QRStatDeviceList} from '../../../../services/main'
import {dateFormat} from '../../../../services/Utils'
import DeviceScanChart from '../../../../_metronic/layout/components/Charts/deviceStats'

type Props = {
  flipID?: any
}

const ScanedDevices: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loadingList, setLoadingList] = useState(false)
  const [deviceArr, setDeviceArr] = useState<any>([])
  const colors = ['#519DE9', '#7CC674', '#73C5C5', '#8481DD', '#F6D173']
  useEffect(() => {
    statlist(props?.flipID)
  }, [props.flipID])

  const statlist = (id: any) => {
    setLoadingList(true)
    var req = QRStatListReq
    req.filter.qr.id = props.flipID
    QRStatDeviceList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    if (response.data.length > 0) {
      setDeviceArr([])
      deviceArr.push(['Төхөөрөмжийн төрөл', 'Уншуулалтын тоо', {role: 'style'}])
      response.data.map((el: any, i: any) => {
        return deviceArr.push([dateFormat(el.device), parseInt(el.niit), colors[i]])
      })
      setDeviceArr(deviceArr)
    }
    setLoadingList(false)
  }
  const onFailed = (error: any) => {
    setLoadingList(false)
    console.log('error:', error)
  }

  return (
    <div className='total-card shadow-smShadow flex flex-col cursor-pointer'>
      {deviceArr.length > 0 ? (
        <div className='top-20'>
          <DeviceScanChart data={deviceArr} />
        </div>
      ) : loadingList == true ? (
        <span className='text-center mt-5'>{t('common:loading')}</span>
      ) : !loadingList ? (
        <div className='no-stat'>
          <div className='ex-mark '>!</div>
          <span className='text-textTri text-lg w-[400px] font-medium'>Have not read yet</span>
        </div>
      ) : null}
    </div>
  )
}
export default ScanedDevices
