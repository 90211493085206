import React, { useState, useEffect } from 'react'
import "./index.scss";
import { isNullOrEmpty } from '../../../services/Utils';
import Pagination from './Pagination';
//import $ from "jquery";
// import Pagination from "./Pagination";
const DataTable = (props) => {
  const [data, setData] = useState([])
  const [headerColumn, setHeaderColumn] = useState(props.table.headerOtherColumn)
  const [keyColumn, setKeyColumn] = useState(props.table.headerKeyColumn)
  const [PTotal, setPTotal] = useState(props.PTotal)
  var load = false;
  load = props.loading;

  useEffect(() => {
    setData(props.data)
    setPTotal(props.PTotal)
  }, [props])

  const dataFormat = (cell, row) => {
    return <span title={cell}>{cell}</span>;
  }

  const onChangePage = (page) => {
    props.pageChange(page);
  }

  const indexN = (index) => {
    return props.PID == 1 ? (
      <div>{index + 1}</div>
    ) : (
      <div>
        {props.table.sizePerPage * (props.PID - 1) + index + 1}
      </div>
    );
  }
  return (
    <div className="customBootstrapTable">
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th width={50}>№</th>
              {headerColumn?.map((itemh, indexh) => {
                return <th key={indexh} className={itemh.className}>{itemh.title}</th>
              })}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {load ? <tr><td colSpan={headerColumn?.length + 1} className='text-center'>loading...</td></tr> : !isNullOrEmpty(data) && data.length > 0 ? data?.map((item, index) => {
              return <tr key={index}>
                <td width={50} className='text-center'>{indexN(index)}</td>
                {headerColumn?.map((itemhh, indexhh) => {
                  var field = headerColumn[indexhh]?.dataField;
                  return <td key={indexhh}>{isNullOrEmpty(itemhh.dataFormat) ? dataFormat(item[field]) : itemhh.dataFormat(item[field], item)}</td>
                })}
              </tr>
            }) : <tr><td colSpan={headerColumn?.length + 1} className='text-center'>no data</td></tr>}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
        {data?.length > 0 ? <Pagination totalData={PTotal}
          pageSize={props.table.sizePerPage}
          onChangePage={onChangePage}
          PID={props.PID} /> : null}
      </div>
    </div>
  )
}

export default DataTable
