import {FC, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {magicnum} from '../../../services/Request'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const LetterBanner: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState()
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const templateRowFormat = (cell: any, row: any) => {
    return (
      <Link to={`/letterbanner/${row.url}`}>
        <div className='text-center'>
          <img width={50} height={50} src={`/letters/${cell}.jpg`} alt='' />
        </div>
      </Link>
    )
  }

  const ugRowFormat = (cell: any, row: any) => {
    return <Link to={`/user/${row.uid}`}>{cell}</Link>
  }

  var table = {
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:LETTERBANNER.pid'),
        dataField: 'url',
        dataSort: false,
        dataFormat: templateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.id'),
        dataField: 'url',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.ug'),
        dataField: 'text',
        dataSort: false,
        dataFormat: ugRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:LETTERBANNER.pagetitle')}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}></div>
      </div>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:LETTERBANNER.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:LETTERBANNER.total', {count: PTotal})}
            </span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={magicnum.letters.filter((aa: any) => aa.status == magicnum.Status.Active)}
            table={table}
            PTotal={PTotal}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {LetterBanner}
