import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  DTempleteChangeStatusReq,
  DTempleteListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  DDesigners,
  DTemapleteChangeStatus,
  DTemapleteDelete,
  DTemapleteListWithTracking,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import {TemplateEdit} from './edit'
import {TemplateTag} from './tag'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Template: FC = () => {
  const [params, setParams] = useSearchParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [orgModal, setOrgModal] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [sub_category, setSub_category] = useState('')
  const [status, setStatus] = useState<any>(
    isNullOrEmpty(params.get('status')) ? '' : params.get('status')?.toString()
  )
  const [created_by, setCreated_by] = useState('')
  const [segment, setSegment] = useState('')
  const [tagModal, setTagModal] = useState(false)
  const [designerList, setDesignerList] = useState([])
  const [templateId, setTemplateId] = useState('')
  const [id, setId] = useState('')

  // console.log(designerList)

  useEffect(() => {
    getDesinerList()
  }, [PID])

  useEffect(() => {
    list()
  }, [designerList])

  const getDesinerList = () => {
    DDesigners(BaseRequest, onDesinerSuccess, onFailed, 'POST')
  }
  const onDesinerSuccess = (res: any) => {
    setDesignerList(res.data)
  }
  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DTempleteListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.desgin.id = id
    req.desgin.name = name
    req.desgin.skey = 'admin'
    req.desgin.category = category
    req.desgin.segment = segment
    req.desgin.sub_category = sub_category
    req.desgin.created_by = created_by
    req.desgin.status = status
    DTemapleteListWithTracking(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const designerRowFormat = (cell: any, row: any) => {
    var temp = ''
    console.log(designerList)
    if (designerList.length > 0) {
      designerList.map((el: any) => {
        if (el.created_by == cell) {
          temp = el.uname
        }
      })
    }
    return <div>{temp}</div>
  }
  const statRowFormat = (cell: any, row: any) => {
    return (
      <div style={{display: 'flex', gap: 15, fontSize: 11}}>
        <span>
          <i className='fa fa-info' /> : {row.view}
        </span>
        <span>
          <i className='fa fa-edit' /> : {row.edit}
        </span>
        <span>
          <i className='fa fa-download' /> : {row.download}
        </span>
      </div>
    )
  }
  const imgRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer', objectFit: 'contain'}}>
        <ModalImage
          className='max-h-100px max-w-150px'
          small={CDN_URL + cell}
          large={CDN_URL + cell}
          alt={row.id}
        />
      </div>
    )
  }
  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div>
        <div className='cursor-pointer' style={{display: 'flex', flexDirection: 'column', gap: 0}}>
          <Link to={`/templates/${row.id}`}>
            <span>{cell}</span>
          </Link>
          <span style={{fontSize:'12px'}}>Id:{row.id}</span>
          {isNullOrEmpty(row.temdeglelt_odor) ? null : (
            <span style={{fontSize: 10}}>
              {t(`common:magicnum.temdegleltOdor.${row.temdeglelt_odor}`)}
            </span>
          )}
          <div style={{display: 'flex', columnGap: 10}}>
            <span>{t(`common:magicnum.Category.${row.category}`)} - </span>
            <span>{t(`common:magicnum.SubCategory.${row.category}-${row.sub_category}`)}</span>
          </div>
        </div>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center gap-2 justify-center'>
        <span
          onClick={() => submit(row, 'changeStatus')}
          style={{cursor: 'pointer'}}
          className={`badge ${cell == '0' ? 'badge-light-warning' : 'badge-light-success'}`}
        >
          {t(`common:magicnum.BrandbookLogos.status.${cell}`)}
        </span>
        <span>{dateFormat(row.created_at)}</span>
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    var tag = row?.tags?.split(',')
    var tagToo = tag?.length > 0 ? tag?.length : 0
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <Link to={'/templates/' + row.id}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          onClick={() => {
            navigator.clipboard.writeText(templateId)
            return toast.success('Хуулагдлаа', {
              autoClose: 1000,
              hideProgressBar: true,
            })
          }}
        >
          <div
            onClick={() =>
              setTemplateId(
                'https://grado.mn/templates/CO' + Base64.encode(row.id?.toString(), true) + '-next'
              )
            }
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='copy' className='fs-3' />
          </div>
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Add template to Org'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setOrgModal(true)
          }}
        >
          {isNullOrEmpty(row.org_id) ? (
            <i className='fa-solid fa-briefcase fs-4'></i>
          ) : (
            <i className='fa-solid fa-briefcase fs-4' style={{color: 'green'}}></i>
          )}
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          style={{position: 'relative'}}
          onClick={() => {
            setItem(row)
            setTagModal(true)
          }}
        >
          <div
            className={
              tagToo < 8 ? 'tag-counter  tag-counter-red' : 'tag-counter tag-counter-green'
            }
          >
            {tagToo}
          </div>
          <KTIcon iconName='price-tag' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    DTemapleteDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = DTempleteChangeStatusReq
    req.desgin.id = row.id
    if (row.status == 0) {
      req.desgin.status = JSON.stringify(1)
    }
    if (row.status == 1) {
      req.desgin.status = JSON.stringify(0)
    }

    DTemapleteChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    setOrgModal(false)
    return toast.success(text)
  }
  const clearFilter = () => {
    setName('')
    setSegment('')
    setCategory('')
    setSub_category('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:TEMPLATE.templates'),
        dataField: 'preview',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.temp_info'),
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.designer'),
        dataField: 'created_by',
        dataSort: false,
        dataFormat: designerRowFormat,
        hidden: false,
      },
      {
        title: t('common:TEMPLATE.statistic'),
        dataField: 'created_by',
        dataSort: false,
        dataFormat: statRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <TemplateEdit t={t} item={item} type='edit' onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {orgModal ? (
        <CustomModal
          visible={orgModal}
          closeModal={() => setOrgModal(false)}
          className='VideoModal'
        >
          <TemplateEdit t={t} item={item} type='org' onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {tagModal ? (
        <CustomModal
          visible={tagModal}
          closeModal={() => setTagModal(false)}
          className='VideoModal'
        >
          <TemplateTag t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:TEMPLATE.templates')}</PageTitle>

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            onClick={() => setShowFilter(!showFilter)}
            className=' cursor-pointer filter flex items-center justify-center gap-0'
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row flex items-center filter-wrap'>
            <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  id='id'
                  type='number'
                  onChange={(e) => setId(e.target.value)}
                  placeholder='ID'
                  value={id}
                />
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  id='name'
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Name'
                  value={name}
                />
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='category'
                  value={category}
                >
                  <option value=''>{t('common:ELEMENT.select_cat')}</option>
                  {magicnum.Category.map((pitem) => {
                    return <option value={pitem}>{t(`common:magicnum.Category.${pitem}`)}</option>
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setSub_category(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='sub_category'
                  value={sub_category}
                >
                  <option value=''>{t('common:ELEMENT.select_sub_cat')}</option>
                  {magicnum.SubCategory.filter(
                    (sub: any) => sub.parent.toString() === category
                  ).map((pitem) => {
                    return (
                      <option value={pitem.id}>
                        {t(`common:magicnum.SubCategory.${category}-${pitem.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  value={status}
                >
                  <option value=''>{t('common:employee.selectStatus')}</option>
                  <option value={magicnum.Status.InActive}>
                    {t('common:magicnum.Status.InActive')}
                  </option>
                  <option value={magicnum.Status.Active}>
                    {t('common:magicnum.Status.Active')}
                  </option>
                </select>
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:TEMPLATE.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:TEMPLATE.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Template}
