import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {
  BaseRequest,
  OrgLogosChangeStatusReq,
  OrgLogosListReq,
  OrgPptChangeStatusReq,
  OrgPptListReq,
  magicnum,
} from '../../../../services/Request'
import {
  CDN_URL,
  OrgPptChangeStatus,
  OrgPptDelete,
  OrgPptList,
  OrglogosChangeStatus,
  OrglogosDelete,
  OrglogosList,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import LogoAdd from './add'
import {KTIcon} from '../../../../_metronic/helpers'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {SizeUnits, dateFormat, dateTimeFormat, isNullOrEmpty} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'
import {OrgLogoChange} from '../changelogo'
import ModalImage from 'react-modal-image'
import PptAdd from './add'
import Pictures from './pictures'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Organization',
    path: '/orgs',
    isSeparator: false,
    isActive: false,
  },
]

interface Props {
  uid?: any
  type?: string
}

const OrgPpt: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState('')
  const [data, setData] = useState<any>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState(false)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [org_id, setOrg_id] = useState('')
  const [status, setStatus] = useState('')
  const [pictureModal, setPictureModal] = useState(false)
  console.log(data)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = OrgPptListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.org_id = isNullOrEmpty(props.uid) ? org_id : props.uid
    OrgPptList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onAddSuccess = (response: any) => {
    setLoading(false)
    setShowModal(false)
    setPictureModal(false)
    list()
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    OrgPptDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = OrgPptChangeStatusReq
    req.org.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.org.status = JSON.stringify(magicnum.Status.Active)
    }
    if (row.status == magicnum.Status.Active) {
      req.org.status = JSON.stringify(magicnum.Status.InActive)
    }
    OrgPptChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setOrg_id('')
    setStatus('')
    list()
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='flex flex-col items-center'>{cell}</div>
  }
  const imageRowFormat = (cell: any, row: any) => {
    return isNullOrEmpty(cell) ? (
      <span className='text-center'>no image</span>
    ) : (
      <div className='w-80px max-h-80px max-w-100px overflow-hidden text-center'>
        <img className='max-h-80px object-cover' src={CDN_URL + cell} />
      </div>
    )
  }

  const sizeRowFormat = (cell: any, row: any) => {
    return <div className='flex flex-col items-center'>{SizeUnits(cell)}</div>
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        {' '}
        <span
          onClick={() => submit(row, 'changeStatus')}
          style={{cursor: 'pointer'}}
          className={`badge ${cell == '0' ? 'badge-light-warning' : 'badge-light-success'}`}
        >
          {t(`common:magicnum.BrandbookLogos.status.${cell}`)}
        </span>
      </div>
    )
  }
  const defaultdateFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>{dateFormat(cell)}</div>
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div style={{minWidth: '105px'}} className='text-center'>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setPictureModal(true)
          }}
        >
          <KTIcon iconName='picture' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'PrivNo',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:ORG.pptImage'),
        dataField: 'preview',
        dataSort: false,
        dataFormat: imageRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:ORG.pptname'),
        dataField: 'name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      props.type != 'component'
        ? {
            title: t('common:ORG.org_id'),
            dataField: 'org_id',
            dataSort: false,
            dataFormat: defaultRowFormat,
            hidden: false,
            filter: {
              type: 'TextFilter',
              placeholder: '...',
            },
          }
        : '',

      {
        title: t('common:USERS.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.size'),
        dataField: 'size',
        dataSort: false,
        dataFormat: sizeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.version'),
        dataField: 'version',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: defaultdateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <PptAdd t={t} id={props?.uid} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {pictureModal ? (
        <CustomModal
          visible={pictureModal}
          closeModal={() => setPictureModal(false)}
          className='VideoModal'
        >
          <Pictures t={t} id={props?.uid} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('ORG.ppt_list')}</PageTitle>
      {props.type != 'component' ? (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <a onClick={() => list()} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    type='number'
                    onChange={(e) => setOrg_id(e.target.value)}
                    placeholder='org id'
                    value={org_id}
                  />
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                  >
                    <option value=''>{t('common:ORG.select_status')}</option>
                    {/* {magicnum.Status.map((pitem:string) => {
                      return (
                        <option value={pitem}>
                          {t(`common:magicnum.Status.${pitem}`)}
                        </option>
                      )
                    })} */}
                  </select>
                  {/* <input className='form-control form-control-solid' type='text' /> */}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:ORG.ppt_list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:ORG.total_ppt', {count: data.length})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem('')
                setShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:ORG.add_ppt')}
            </a>
          </div>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {OrgPpt}
