import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {EmployeeAdd, EmployeeChangeInfo, NewsAdd, NewsUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  EmployeeAddReq,
  EmployeeChangeInfoReq,
  NewsAddReq,
  NewsUpdateReq,
  magicnum,
} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const EmployeeCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [email, setEmail] = useState(isNullOrEmpty(item) ? '' : item.email)
  const [password, setPassword] = useState(isNullOrEmpty(item) ? '' : item.password)
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [firstname, setFirstname] = useState(isNullOrEmpty(item) ? '' : item.firstname)
  const [lastname, setLastname] = useState(isNullOrEmpty(item) ? '' : item.lastname)
  const [typeid, setTypeid] = useState(isNullOrEmpty(item) ? 'super' : item.typeid)
  const [status, setStatus] = useState(isNullOrEmpty(item) ? '' : item.status)
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [noc, setNoc] = useState('')
  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = EmployeeAddReq
      req.Username = email
      req.Password = password
      req.FirstName = firstname
      req.LastName = lastname
      req.Status = magicnum.Status.InActive
      req.TypeID = typeid
      req.Noc = noc
      req.Photo = imageBase64
      EmployeeAdd(req, onSuccess, onFailed, 'POST')
    } else {
      return toast.warning(t('common:validForm'))
    }
  }

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaodingImageData(true)
    if (event.target.files) {
      var file = event.target.files[0]
      var reader = new FileReader()

      reader.onload = function (event) {
        setImageBase64(event?.target?.result)
        setLaodingImageData(false)
      }

      reader.readAsDataURL(file)
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = EmployeeChangeInfoReq
      req.Username = email
      req.FirstName = firstname
      req.LastName = lastname
      req.Status = status
      req.TypeID = typeid
      req.Noc = noc
      req.id = item.id
      EmployeeChangeInfo(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(imageBase64) && isNullOrEmpty(item)) {
      res.retType = 1
      validate['image'] = true
    }

    if (isNullOrEmpty(email)) {
      res.retType = 1
      validate['email'] = true
    }

    if (isNullOrEmpty(password) && isNullOrEmpty(item)) {
      res.retType = 1
      validate['password'] = true
    }

    if (isNullOrEmpty(firstname)) {
      res.retType = 1
      validate['firstname'] = true
    }

    if (isNullOrEmpty(lastname)) {
      res.retType = 1
      validate['lastname'] = true
    }

    if (isNullOrEmpty(typeid)) {
      res.retType = 1
      validate['typeid'] = true
    }
    console.log(validate)
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:employee.pagetitle')}</h1>
        </div>
        {isNullOrEmpty(item) ? (
          <div className='mb-8'>
            <div className='btn btn-light-primary fw-bolder w-100'>
              <label htmlFor='files'>
                {loadingImageData ? t('common:loading') : t('common:employee.photo')}
              </label>
            </div>
            {validate['image'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
        ) : null}

        <input
          type='file'
          name='image'
          id='files'
          style={{display: 'none'}}
          accept='.pdf,.jpg,.png,.jpeg'
          onChange={handleSelectFile}
        />
        {isNullOrEmpty(imageBase64) ? null : (
          <div className='d-flex flex-center'>
            <img src={imageBase64} width={200} height={100} />
          </div>
        )}
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>text</span>
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:employee.email')}
            onChange={(e) => {
              setEmail(e.target.value)
              validateChange('email', e)
            }}
            value={email}
          ></input>
          {validate['email'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        {isNullOrEmpty(item) ? (
          <div className='mb-8'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:employee.password')}
              onChange={(e) => {
                setPassword(e.target.value)
                validateChange('password', e)
              }}
              value={password}
            ></input>
            {validate['password'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:employee.firstname')}
            onChange={(e) => {
              setFirstname(e.target.value)
              validateChange('firstname', e)
            }}
            value={firstname}
          ></input>
          {validate['firstname'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:employee.lastname')}
            onChange={(e) => {
              setLastname(e.target.value)
              validateChange('lastname', e)
            }}
            value={lastname}
          ></input>
          {validate['lastname'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            value={status}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setStatus(e.target.value)
              validateChange('status', e)
            }}
          >
            <option value=''>{t('common:employee.selectStatus')}</option>
            <option value={magicnum.Status.InActive}>{t('common:magicnum.Status.InActive')}</option>
            <option value={magicnum.Status.Active}>{t('common:magicnum.Status.Active')}</option>
          </select>
          {validate['status'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-8'>
          <select
            name='typeid'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm'
            onChange={(e) => setTypeid(e.target.value)}
            value={typeid}
          >
            <option value='grado'>Grado</option>
            <option value='print'>Print</option>
          </select>
          {validate['typeid'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>

        

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EmployeeCreate}
