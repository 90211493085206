import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {
  dateFormat,
  dateTimeFormat,
  decodeString,
  encodeString,
  isNullOrEmpty,
} from '../../../services/Utils'
import {BaseRequest} from '../../../services/Request'
import {CDN_URL, EventGet, OrgGet} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Users} from '../users'
import { EventUsers } from './eventUsers'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Event',
    path: '/event',
    isSeparator: false,
    isActive: false,
  },
]

const EventMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [usersCount, setUsersCount] = useState('')

  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('users')

  console.log(data)
  useEffect(() => {
    get()
  }, [id])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    EventGet(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.name}</PageTitle>
      <div className={`card mb-5`}>
        <div style={{overflowX: 'auto'}} className='card-header border-1'>
          <div className='flex gap-8 col-lg-3 col-form-label w-full card-header-flex'>
            <div className='zurag flex items-center py-8'>
              {isNullOrEmpty(data?.preview) ? (
                <div
                  style={{
                    width: '80px',
                    height: '100px',
                    borderRadius: '10px',
                    backgroundColor: '#ecf0f1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  No Image
                </div>
              ) : (
                <img
                  src={CDN_URL + data?.preview}
                  style={{borderRadius: '15px', maxHeight: '100px', maxWidth: '200px'}}
                  alt=''
                />
              )}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'>
              <div className='w-200px flex gap-3 items-center'>
                <KTIcon iconName='user' iconType='outline' className='fs-2' />
                <Link to={'/user/' + data?.uid}><span>{data?.uid}</span></Link>
              </div>
              <div className='flex gap-3 items-center'>
                <KTIcon iconName='barcode' iconType='outline' className='fs-2' />
                <span>{encodeString(id) }</span>
              </div>
              <div className=' flex gap-3 items-center'>
                <KTIcon iconName='calendar-2' iconType='outline' className='fs-2' />
                <span>{dateFormat(data?.created_at)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div style={{overflowX: 'auto'}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'users',
                })}
                onClick={() => setTab('users')}
                role='tab'
              >
                Users
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'users' && !isNullOrEmpty(data?.id) ? <EventUsers/> : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {EventMore}
