import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateAgo, dateFormat, dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  NewsChangeStatusReq,
  OrgChangeStatusReq,
  OrgListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  NewsChangeStatus,
  OrgChangeStatus,
  OrgDelete,
  OrgList,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import {OrgCreate} from './add'
import {OrgLogoChange} from './changelogo'
import Subscription from '../users/subscription'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Orgs: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [umch, setUmch] = useState('')
  const [register, setRegister] = useState('')
  const [provider, setProvider] = useState('')
  const [status, setStatus] = useState('')
  const [changelogoModal, setChangelogoModal] = useState(false)
  const [banShowModal, setBanShowModal] = useState(false)
  const [showModalSungalt, setShowModalSungalt] = useState(false)
console.log(data)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = OrgListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.name = name
    req.filter.umch = umch
    req.filter.register = register
    req.filter.status = status
    OrgList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div style={{display: 'flex', gap: 10}}>
        <Link to={`/org/${row.id}`}>
          {isNullOrEmpty(row.logo) ? (
            <div style={{position: 'relative'}}>
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '10px',
                  backgroundColor: '#ecf0f1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {cell.substring(0, 1)}
              </div>
            </div>
          ) : (
            <div style={{position: 'relative'}}>
              <img src={CDN_URL + row.logo} width={52} height={52} style={{borderRadius: '5px'}} />
            </div>
          )}
        </Link>
        <div className='cursor-pointer' style={{display: 'flex', flexDirection: 'column', gap: 0}}>
          <Link to={`/org/${row.id}`}>
            <span>{cell}</span>
          </Link>
          <span style={{fontSize: 11}}>{row.register}</span>
        </div>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center'>
        <span
          onClick={() => submit(row, 'changeStatus')}
          style={{cursor: 'pointer'}}
          className={`badge ${cell == '0' ? 'badge-light-warning' : 'badge-light-success'}`}
        >
          {t(`common:magicnum.BrandbookLogos.status.${cell}`)}
        </span>
      </div>
    )
  }
  const rowDueDate = (cell: any, row: any) => {
    return isNullOrEmpty(cell) ? (
      <div
        onClick={() => {
          setShowModalSungalt(true)
          setItem(row)
        }}
        className='text-center cursor-pointer'
      >
        Сунгалт хийх
      </div>
    ) : (
      <div
        onClick={() => {
          setShowModalSungalt(true)
          setItem(row)
        }}
        className='text-center cursor-pointer'
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        {dateAgo(cell) < 1 ? (
          <span className='badge badge-light-warning'>Сунгалт дууссан : {dateAgo(cell)}</span>
        ) : (
          <span className='badge badge-light-success'>Хүчинтэй өдөр : {dateAgo(cell)}</span>
        )}
        <span style={{fontSize: 10}}>
          {row.bagts}-{dateFormat(cell)}
        </span>
      </div>
    )
  }
  const defaultdateFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>{dateFormat(cell)}</div>
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: '180px'}}>
        <div
          onClick={() => {
            setItem(row)
            setShowModalSungalt(true)
          }}
          title='xdssds'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='time' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setChangelogoModal(true)
          }}
        >
          <KTIcon iconName='picture' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
        {/* <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'changeStatus')}
        >
          <KTIcon iconName='setting-2' className='fs-3' />
        </div> */}
        <div
          onClick={() => {
            setItem(row)
            setBanShowModal(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='minus-circle' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    OrgDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = OrgChangeStatusReq
    req.org.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.org.status = JSON.stringify(magicnum.Status.Active)
    }
    if (row.status == magicnum.Status.Active) {
      req.org.status = JSON.stringify(magicnum.Status.InActive)
    }

    OrgChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    setChangelogoModal(false)
    return toast.success(text)
  }
  const clearFilter = () => {
    setName('')
    setUmch('')
    setStatus('')
    setRegister('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:USERS.pagetitle'),
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:magicnum:Status.Active'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'umch',
        dataField: 'umch',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:SUBSCRIPTION.duusah_hugatsaa'),
        dataField: 'bagts_hugatsaa',
        dataSort: false,
        dataFormat: rowDueDate,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: defaultdateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <OrgCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {changelogoModal ? (
        <CustomModal
          visible={changelogoModal}
          closeModal={() => setChangelogoModal(false)}
          className='VideoModal'
        >
          <OrgLogoChange t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {showModalSungalt ? (
        <CustomModal
          visible={showModalSungalt}
          closeModal={() => setShowModalSungalt(false)}
          className='VideoModal'
        >
          <Subscription type="fromorg" t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:ORG.pagetitle')}</PageTitle>

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            onClick={() => setShowFilter(!showFilter)}
            className=' cursor-pointer filter flex items-center justify-center gap-0'
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <a onClick={() => list()} className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </a>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row flex items-center filter-wrap'>
              <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  id='name'
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Name'
                  value={name}
                />
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  id='email'
                  type='text'
                  onChange={(e) => setRegister(e.target.value)}
                  placeholder='Register'
                  value={register}
                />
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setProvider(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='umch'
                  value={provider}
                >
                  <option value=''>{t('common:ORG.select')}</option>
                  <option value='tor'>{t('common:ORG.toriin')}</option>
                  <option value='huvi'>{t('common:ORG.huviin')}</option>
                </select>
              </div>
              <div className='col flex gap-4 items-center'>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='status'
                  value={status}
                  id=''
                >
                  <option value=''>{t('common:all')}</option>
                  <option value={magicnum.Status.InActive}>{t('common:USERS.inActive')}</option>
                  <option value={magicnum.Status.Active}>{t('common:USERS.active')}</option>
                </select>
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:ORG.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:ORG.total', {count: PTotal})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:ORG.add')}
            </a>
          </div>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Orgs}
