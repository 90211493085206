import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import './index.scss'
const propTypes = {
  totalData: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
}

const defaultProps = {
  totalData: 10,
  initialPage: 1,
  pageSize: 10,
}

class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pager: {},
      totalData: this.props.totalData,
      t: this.props.t,
    }
  }

  componentWillMount() {
    if (this.state.totalData > 0 && this.props.initialPage !== this.state.pager.currentPage) {
      var {pager} = this.state
      pager.currentPage = this.props.PID
      this.setState({pager}, function () {
        this.setPage(this.state.pager.currentPage, false)
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.totalData !== nextProps.totalData) {
      this.setState({totalData: nextProps.totalData}, function () {
        this.setPage(this.props.initialPage, false)
      })
    }
  }

  setPage(page, type) {
    var {pageSize} = this.props
    var pager = this.state.pager
    if (page < 1 || page > pager.totalPages) {
      return
    }

    pager = this.getPager(page, pageSize)
    this.setState({pager: pager})
    if (type) this.props.onChangePage(page)
  }

  getPager(currentPage, pageSize) {
    currentPage = currentPage || 1
    pageSize = pageSize || 10
    var totalDatas = this.state.totalData
    var totalPages = Math.ceil(totalDatas / pageSize)

    var startPage, endPage
    if (totalPages <= 10) {
      startPage = 1
      endPage = totalPages
    } else {
      if (currentPage <= 6) {
        startPage = 1
        endPage = 10
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }
    var startIndex = (currentPage - 1) * pageSize
    var endIndex = Math.min(startIndex + pageSize - 1, totalDatas - 1)
    var pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i)
    return {
      totalItems: totalDatas,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    }
  }

  render() {
    var pager = this.state.pager
    const {t} = this.state
    var {totalData, pageSize} = this.props
    if (!pager.pages || pager.pages.length <= 1) {
      return null
    }

    return (
      <div>
        {/* <ul className="pagination" style={{ float: "left" }}>
          <li
            style={{ padding: 0, color: "#3c414e", cursor: "pointer" }}
            className={pager.currentPage === 1 ? "disabled btn-sm" : "btn-sm"}
          >
            <span
              onClick={() => this.setPage(1, true)}
              style={{ color: "#3c414e" }}
            >
              {t("pagination.First")}
            </span>
          </li>
          <li
            style={{ padding: 0, color: "#3c414e", cursor: "pointer" }}
            className={pager.currentPage === 1 ? "disabled btn-sm" : "btn-sm"}
          >
            <span
              onClick={() => this.setPage(pager.currentPage - 1, true)}
              style={{ color: "#3c414e" }}
            >
              {t("pagination.Previous")}
            </span>
          </li>
          {pager?.pages?.map((page, index) => (
            <li
              style={{ padding: 0, cursor: "pointer" }}
              key={index}
              className={
                pager.currentPage === page ? "active btn-sm" : "btn-sm"
              }
            >
              <span
                onClick={() => this.setPage(page, true)}
                style={
                  pager.currentPage === page
                    ? { color: "#fff" }
                    : { color: "#3c414e" }
                }
              >
                {page}
              </span>
            </li>
          ))}
          <li
            style={{ padding: 0, color: "#3c414e", cursor: "pointer" }}
            className={
              pager.currentPage === pager.totalPages
                ? "disabled btn-sm"
                : "btn-sm"
            }
          >
            <span
              onClick={() => this.setPage(pager.currentPage + 1, true)}
              style={{ color: "#3c414e" }}
            >
              {t("pagination.Next")}
            </span>
          </li>
          <li
            style={{ padding: 0, color: "#3c414e", cursor: "pointer" }}
            className={
              pager.currentPage === pager.totalPages
                ? "disabled btn-sm"
                : "btn-sm"
            }
          >
            <span
              onClick={() => this.setPage(pager.totalPages, true)}
              style={{ color: "#3c414e" }}
            >
              {t("pagination.Last")}
            </span>
          </li>
          <li
            style={{ padding: 0 }}
            className="disabled btn-sm"
          >
            <span>
              {t("pagination.Total")} : {totalData}
            </span>
          </li>
        </ul> */}
        <div className='d-flex flex-stack flex-wrap pt-10'>
          <div className='fs-6 text-gray-600'>
            {t('pagination.Showing', {
              total: totalData,
              from: (this.props.PID - 1) * this.props.pageSize + 1,
              to: this.props.PID * this.props.pageSize,
            })}
          </div>
          <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
            <ul className='pagination '>
              <li className='page-item'>
                <input
                  min={1}
                  className='form-control form-control-sm w-100px form-control-solid'
                  onChange={(e) => this.setPage(e.target.value, true)}
                  type='number'
                />
              </li>
              <li className='page-item' onClick={() => this.setPage(1, true)}>
                <a href='#' className='page-link'>
                  {t('pagination.First')}
                </a>
              </li>
              <li
                className='page-item previous'
                onClick={() => this.setPage(pager.currentPage - 1, true)}
              >
                <a href='#' className='page-link'>
                  <i className='previous'></i>
                </a>
              </li>
              {pager?.pages?.map((page, index) => (
                <li
                  className={`page-item ${pager.currentPage === page ? 'active' : ''}`}
                  key={index}
                  onClick={() => this.setPage(page, true)}
                >
                  <a href='#' className='page-link'>
                    {page}
                  </a>
                </li>
              ))}

              <li
                className='page-item next'
                onClick={() => this.setPage(pager.currentPage + 1, true)}
              >
                <a href='#' className='page-link'>
                  <i className='next'></i>
                </a>
              </li>
              <li className='page-item next' onClick={() => this.setPage(pager.totalPages, true)}>
                <a href='#' className='page-link'>
                  {t('pagination.Last')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

Pagination.propTypes = propTypes
Pagination.defaultProps = defaultProps
export default withTranslation('main')(Pagination)
