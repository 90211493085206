import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {OrgColorsAddReq, OrgColorsUpdateReq, magicnum} from '../../../../services/Request'
import {OrgColorsAdd, OrgColorsUpdate} from '../../../../services/main'
import {isNullOrEmpty} from '../../../../services/Utils'
interface IProps {
  t: any
  id: any
  item: any
  onAddSuccess: any
}
const ColorAdd: FC<IProps> = ({t, id, item, onAddSuccess}) => {
  const [color, setColor] = useState('')
  const [org_id, setOrg_id] = useState('')
  const [option, setOption] = useState('')
  const [validate, setValidate] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setOrg_id(item?.org_id)
    setColor(item?.color)
    setOption(item?.color_type)
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(id)) {
      setOrg_id(id)
    }
  }, [id])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = OrgColorsAddReq
      req.org.org_id = isNullOrEmpty(id) ? org_id : id
      req.org.color = color
      req.org.color_type = option
      OrgColorsAdd(req, onSuccess, onFailed, 'POST')
    }
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    onAddSuccess()
    return toast.success(t('common:successCreate'))
  }
  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = OrgColorsUpdateReq
      req.org.color = color
      req.org.color_type = option
      req.org.id = item?.id
      OrgColorsUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }
  const onUpdateSuccess = () => {
    setLoading(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(org_id)) {
      res.retType = 1
      validate['org_id'] = true
    }
    if (isNullOrEmpty(color)) {
      res.retType = 1
      validate['color'] = true
    }
    if (isNullOrEmpty(option)) {
      res.retType = 1
      validate['option'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  return (
    <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
      <span style={{visibility: 'hidden'}}></span>
      <div className='text-center mb-13'>
        <h1 className='mb-3'>
          {isNullOrEmpty(item) ? t('common:ORG.add') : t('common:ORG.edit_color')}
        </h1>
      </div>
      <div className='separator mt-8 d-flex flex-center mb-8'>
        <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
          {isNullOrEmpty(item) ? t('common:ORG.add') : t('common:ORG.edit_color')}
        </span>
      </div>
      {isNullOrEmpty(item) ? (
        isNullOrEmpty(id) ? (
          <div className='mb-4'>
            <input
              className='form-control form-control-solid'
              value={org_id}
              placeholder={t('common:ORG.org_id')}
              onChange={(e) => {
                setOrg_id(e.target.value)
                validateChange('org_id', e)
              }}
            ></input>
            {validate['org_id'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}

      <div className='mb-4' style={{position: 'relative'}}>
        <input
          className='form-control form-control-solid w-300px'
          value={color}
          placeholder={t('common:ORG.color')}
          onChange={(e) => {
            setColor(e.target.value)
            validateChange('color', e)
          }}
        ></input>
        <div
          style={{
            width: '50px',
            height: '40px',
            position: 'absolute',
            backgroundColor: `${color}`,
            top: '2px',
            right: '5px',
            borderRadius: '10px',
          }}
        ></div>
        {validate['color'] ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{t('common:validInsert')}</div>
          </div>
        ) : null}
      </div>

      <div className='mb-4'>
        <select
          onChange={(e) => {
            setOption(e.target.value)
            validateChange('option', e)
          }}
          className='form-select form-select-sm form-select-solid'
          name='option'
          id=''
          value={option}
        >
          <option value=''>{t(`common:all`)}</option>
          {magicnum.BrandbookColors.torol.map((pitem) => {
            return (
              <option value={pitem}>{t(`common:magicnum.BrandbookColors.torol.${pitem}`)}</option>
            )
          })}
        </select>
        {validate['option'] ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{t('common:validInsert')}</div>
          </div>
        ) : null}
      </div>

      <div className='pt-10' style={{textAlign: 'right'}}>
        <button
          className='btn btn-primary align-self-center'
          onClick={isNullOrEmpty(item) ? add : update}
          disabled={loading}
        >
          {isNullOrEmpty(item)
            ? loading
              ? t('common:loading')
              : t('common:save')
            : t('common:edit')}
        </button>
      </div>
    </div>
  )
}

export default ColorAdd
