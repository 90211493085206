import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {OrgPdfAddImageReq, OrgPptAddImageReq} from '../../../../services/Request'
import {OrgPdfAddImage, OrgPptAddImage} from '../../../../services/main'
import {isNullOrEmpty} from '../../../../services/Utils'
interface IProps {
  t: any
  id: any
  onAddSuccess: any
  item: any
}
const Pictures: FC<IProps> = ({t, id, onAddSuccess, item}) => {
  const [org_id, setOrg_id] = useState('')
  const [loading, setLoading] = useState(false)
  const [imageBase64, setImageBase64] = React.useState<any>('')

  const add = () => {
    setLoading(true)
    var req = OrgPdfAddImageReq
    req.org.preview = imageBase64
    req.org.old_preview = item?.preview
    req.org.id = item?.id
    OrgPdfAddImage(req, onSuccess, onFailed, 'PUT')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    onAddSuccess()
    return toast.success(t('common:successCreate'))
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const getBase64 = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = function () {
      setImageBase64(reader.result)
    }
  }

  return (
    <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
      <span style={{visibility: 'hidden'}}></span>
      <div className='text-center mb-13'>
        {isNullOrEmpty(item) ? (
          <h1 className='mb-3'>{t('common:ORG.add_ppt')}</h1>
        ) : (
          <h1 className='mb-3'>{t('common:ORG.edit_ppt')}</h1>
        )}
      </div>
      <div className='separator mt-8 d-flex flex-center mb-8'>
        <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
          {isNullOrEmpty(item) ? t('common:ORG.add_ppt') : t('common:ORG.edit_ppt')}
        </span>
      </div>

      {isNullOrEmpty(item) ? (
        isNullOrEmpty(id) ? (
          <div className='mb-4'>
            <input
              className='form-control form-control-solid'
              value={org_id}
              placeholder={t('common:ORG.org_id')}
              onChange={(e) => {
                setOrg_id(e.target.value)
              }}
            ></input>
          </div>
        ) : null
      ) : null}

      <div className='mb-4'>
        <input
          type='file'
          className='form-control form-control-solid'
          onChange={(e) => {
            getBase64(e.target.files)
          }}
        ></input>
      </div>

      <div className='pt-10' style={{textAlign: 'right'}}>
        <button className='btn btn-primary align-self-center' onClick={add} disabled={loading}>
          {loading ? t('common:loading') : t('common:save')}
        </button>
      </div>
    </div>
  )
}

export default Pictures
