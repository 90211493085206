import React, { FC, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../../modules/CustomModal/modal'
import { isNullOrEmpty } from '../../../../services/Utils'
import { AddMPriv_GroupPrivReq, BaseRequest, ListBase, ListMPriv_GroupPrivReq } from '../../../../services/Request'
import {
  AddMPriv_GroupPriv,
  ListMPriv_GroupPriv,
  PrivilegeDelete,
  PrivilegeGroupDelete,
  PrivilegeGroupList,
  PrivilegeList,
  UpdateMPriv_GroupPriv,
} from '../../../../services/main'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { PrivCreate } from './add'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useParams } from 'react-router-dom'

const dashboardBreadCrumbs = [
  {
    title: 'Priv Group',
    path: '/privilege/privgrouplist',
    isSeparator: false,
    isActive: false,
  },
]

const PrivGroupMore = () => {
  const { t } = useTranslation()
  const [isAdd, setIsAdd] = useState(true)
  const [data2, setData2] = useState([])
  const [privs, setPrivs] = useState([])
  const [ckeckedpriv, setCkeckedpriv] = useState([])
  const [count, setCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  useEffect(() => {
    getAllPrivs()
    setActivePrivs()
  }, [])

  const setActivePrivs = () => {
    var req = ListMPriv_GroupPrivReq
    ListMPriv_GroupPriv(id, req, onGetPGSuccess, onFailed, 'GET')
  }

  const onGetPGSuccess = (response) => {
    var k = 0
    response.mPriv_GroupForLists.map((i, index) => {
      var priv = {}
      priv.privNo = i.PrivNo
      priv.isCheck = i.ischeck
      privs.push(priv)
      if (i.ischeck == 1) {
        ckeckedpriv.push(priv)
      }
      k++
    })
    if (k > 0) {
      setIsAdd(false)
    }
    setCkeckedpriv(ckeckedpriv)
  }

  const onFailed = (error) => {
    setLoading(false)
    return toast.error(error)
  }

  const getAllPrivs = () => {
    var req = ListBase
    PrivilegeList(req, onPrivSuccess, onFailed, 'GET')
  }

  const onPrivSuccess = (response) => {
    setData2(response.mPrivs)
  }

  const onCheckBox = (privs, privNo) => {
    var dd = false;
    if (privs != undefined) {
      privs.map((i, index) => {
        if (i.privNo == privNo) {
          dd = true;
        }
      });
    }
    return dd;
  }

  const onChangeCheckBox = (privNo, event) => {
    var dd = false;
    if (ckeckedpriv != undefined) {
      ckeckedpriv.map((i, index) => {
        if (i.PrivNo == privNo && i.isCheck == 1) {
          dd = true;
        }
      });
    }
    var priv = {};
    priv.privNo = privNo;
    priv.isCheck = 1;
    if (!dd && event.target.checked) {
      ckeckedpriv.push(priv);
      setCkeckedpriv(ckeckedpriv)
    } else {
      if (!event.target.checked) {
        ckeckedpriv.map((pri, index) => {
          if (pri.privNo === privNo) {
            ckeckedpriv.splice(index, 1);
            setCkeckedpriv(ckeckedpriv)
            return false;
          }
        });
      }
    }
    setCount(count + 1)
  }

  const save = () => {
    var req = AddMPriv_GroupPrivReq;
    req.privGroupId = id;
    req.mPrivs = ckeckedpriv;
    setLoading(true)
    isAdd
      ? AddMPriv_GroupPriv(
        req,
        onPrivAddSuccess,
        onFailed,
        "POST"
      )
      : UpdateMPriv_GroupPriv(
        req,
        onPrivAddSuccess,
        onFailed,
        "PUT"
      );
  }
  const onPrivAddSuccess = (response) => {
    setLoading(false)
    return toast.success(t("common:successUpdate"));
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:privgroup.pagetitle')}</PageTitle>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:privgroup.more')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {id} <div style={{ visibility: "hidden" }}>{count}</div>
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {data2.map(priv => (
            <label key={priv?.PrivNo} className="form-check form-switch form-check-custom form-check-solid pt-3">
              <input
                className="form-check-input cursor-pointer"
                checked={onCheckBox(ckeckedpriv, priv.PrivNo)}
                onChange={(e) => onChangeCheckBox(priv.PrivNo, e)}
                type="checkbox"
              />
              <span className="form-check-label fw-bold text-gray-400">({priv?.PrivNo}) - {priv?.PrivName}</span>
            </label>
          ))}
          <div style={{ textAlign: 'right' }}>
            <button className='btn btn-primary align-self-center' onClick={save} disabled={loading}>
              {loading ? t('common:loading') : t('common:save')}
            </button>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export { PrivGroupMore }
