import React from "react";
import { Chart } from "react-google-charts";



export const options = {
  // backgroundColor: "#81d4fa",
  title: "QR уншуулсан байршил /хот/",
  curveType: "function",
  colors: ['#f46a7e', '#c742b0', '#c742b0', '#9b43e1', '#8869e8','#7492f0','#5ebef9'],

};

export function ScanningLocation({data}) {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={data}
      options={options}
    />
  );
}
