// import { NavigationArrow, CaretDown, CaretUp } from "@phosphor-icons/react";
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FlipbookStatListReq, QRStatListReq} from '../../../../services/Request'
import {FlipbookStatCityList, QRStatCityList} from '../../../../services/main'
import {ScanningLocation} from '../../../../_metronic/layout/components/Charts/scanningLocations'

type Props = {
  flipID?: any
}

const ScanedLocation: FC<Props> = (props) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  const [loadingList, setLoadingList] = useState(false)
  const [locdata, setLocdata] = useState<any>([])
  useEffect(() => {
    statlist(props.flipID)
  }, [props.flipID])

  const statlist = (id: any) => {
    setLoadingList(true)
    var req = QRStatListReq
    req.filter.qr.id = props.flipID
    QRStatCityList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    if (response.data.length > 0) {
      setLocdata([])
      locdata.push(['Байршил', 'Уншуулсан'])
      response.data.map((el: any) => {
        return locdata.push([el.city == ' ' ? 'Ulan Bator' : el.city, parseInt(el.niit)])
      })
      setLocdata(locdata)
    }
    setLoadingList(false)
  }
  const onFailed = (error: any) => {
    setLoadingList(false)
    console.log('error:', error)
  }

  return (
    <div className='total-card shadow-smShadow flex flex-col cursor-pointer'>
      {locdata.length > 0 ? (
        <div className='top-20'>
          <ScanningLocation data={locdata} />
        </div>
      ) : loadingList == true ? (
        <span className='text-center mt-5'>{t('common:loading')}</span>
      ) : !loadingList && locdata.length == 0 ? (
        <div className='no-stat'>
          <div className='ex-mark '>!</div>
          <span className='text-textTri text-lg w-[400px] font-medium'>Have not read yet</span>
        </div>
      ) : null}
    </div>
  )
}
export default ScanedLocation
