// import { SunDim, CaretDown, CaretUp } from "@phosphor-icons/react";
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FlipbookStatListReq} from '../../../../services/Request'
import {FlipbookStatList} from '../../../../services/main'
import {dateFormat} from '../../../../services/Utils'
import MonthlyStatsChart from '../../../../_metronic/layout/components/Charts/montlyStats'
// import { FlipbookStatList } from "../../../services/main";
// import { FlipbookStatListReq } from "../../../services/Request";
// import MonthlyStatsChart from "../../Charts/montlyStats";
// import { dateFormat } from "../../../services/Utils";
type Props = {
  flipID?: any
}
const MonthlyStat: FC<Props> = (props) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  const [loadingList, setLoadingList] = useState(false)
  const [monthlyData, setMonthlyData] = useState<any>([])
  console.log(monthlyData)
  // useEffect(() => {
  //   if (!show) {
  //     setMonthlyData([]);
  //   }
  // }, [show]);

  useEffect(() => {
    statlist(props.flipID)
  }, [props.flipID])

  const statlist = (id: any) => {
    setLoadingList(true)
    var req = FlipbookStatListReq
    req.filter.flipbook.id = props.flipID
    FlipbookStatList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    if (response.data.length > 0) {
      setMonthlyData([])
      monthlyData.push(['Өдөр', 'Уншуулсан'])
      response.data.map((el: any) => {
        return monthlyData.push([dateFormat(el.day), parseInt(el.niit)])
      })
      setMonthlyData(monthlyData)
    }
    setLoadingList(false)
  }
  const onFailed = (error: any) => {
    setLoadingList(false)
    console.log('error:', error)
  }
  return (
    <div className='total-card shadow-smShadow flex flex-col cursor-pointer'>
      {monthlyData.length > 0 ? (
        <div className='top-20'>
          <MonthlyStatsChart data={monthlyData} />
        </div>
      ) : loadingList == true ? (
        <span className='text-center mt-5'>{t('common:loading')}</span>
      ) : !loadingList && monthlyData.length == 0 ? (
        <div className='no-stat'>
          <div className='ex-mark '>!</div>
          <span className='text-textTri text-lg w-[400px] font-medium'>Have not read yet</span>
        </div>
      ) : null}
    </div>
  )
}
export default MonthlyStat
