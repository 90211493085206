import {FC, useEffect, useState} from 'react'
import {isNullOrEmpty, isValidEmail} from '../../../../services/Utils'
// import { QRTravelRouteUpdate} from '../../../../services/main'
import {toast} from 'react-toastify'
import {QRTravelRouteUpdateReq, magicnum} from '../../../../services/Request'
import useCookie from '../../../../services/useCookie'
import {QRTravelRouteUpdate} from '../../../../services/main'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const EditRoute: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [count, setCount] = useState(0)
  const [city, setCity] = useState(item?.city)
  const [district, setDistrict] = useState(item?.district)
  const [color, setColor] = useState('')

  useEffect(() => {
    setName(item?.name)
    setCity(item?.city)
    setDistrict(item?.district)
    setColor(item?.color)
  }, [item])

  const edit = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = QRTravelRouteUpdateReq
      req.route.name = name
      req.route.color = color
      req.route.city = city
      req.route.district = district
      req.route.id = item?.id
      QRTravelRouteUpdate(req, onRegisterSuccess, onFailed, 'PUT')
    }
  }
  const onRegisterSuccess = () => {
    setLoading(false)
    onAddSuccess('Амжилттай засагдлаа')
  }

  const onFailed = (err: string) => {
    setLoading(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }
    if (isNullOrEmpty(city)) {
      res.retType = 1
      validate['city'] = true
    }
    if (isNullOrEmpty(district)) {
      res.retType = 1
      validate['district'] = true
    }
    if (isNullOrEmpty(color)) {
      res.retType = 1
      validate['color'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Edit Route</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>edit route</span>
        </div>

        <div className='mb-4'>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              validateChange('name', e)
            }}
            type='text'
            placeholder='Маршрутын нэр'
            className='form-control form-control-solid w-300px'
          />
          {validate['name'] ? (
            <div className='fv-plugins-message-container'>
              <p className='fv-help-block'>Маршрутын нэрийг заавал оруулна оруулна уу !</p>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <select
            value={city}
            onChange={(e) => {
              setCity(e.target.value)
              validateChange('city', e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
          >
            <option value=''>Аймаг, хот</option>
            {magicnum.address_city.map((el) => {
              return <option value={el.id}>{el.name}</option>
            })}
          </select>
          {validate['city'] ? (
            <div className='fv-plugins-message-container'>
              <p className='fv-help-block'>Аймаг, хот сонгоно уу !</p>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <select
            value={district}
            onChange={(e) => {
              setDistrict(e.target.value)
              validateChange('district', e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
          >
            <option value=''>Сум, дүүрэг</option>
            {magicnum.address_district
              .filter((l: any) => l.city_id == city)
              .map((el, index) => {
                return (
                  <option value={el.id} key={index}>
                    {el.name}
                  </option>
                )
              })}
          </select>
          {validate['district'] ? (
            <div className='fv-plugins-message-container'>
              <p className='fv-help-block'>Сум, дүүрэг сонгоно уу !</p>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <input
            value={color}
            onChange={(e) => {
              setColor(e.target.value)
              validateChange('color', e)
            }}
            type='text'
            placeholder='Маршрутын өнгө'
            className='form-control form-control-solid w-300px'
          />
          {validate['color'] ? (
            <div className='fv-plugins-message-container'>
              <p className='fv-help-block'>Маршрутын өнгө заавал оруулна оруулна уу !</p>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={edit} disabled={loading}>
            {loading ? t('common:loading') : 'Edit'}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EditRoute}
