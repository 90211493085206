import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {BaseRequest, FeedbackListReq, magicnum} from '../../../services/Request'
import {FeedbackDelete, FeedbackList} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {FeedbackMore} from './more'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
  relID?: any
}
const Feedback: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showFilter, setShowFilter] = useState(false)
  const [main_type, setMain_type] = useState('')
  const [rel_type, setRel_type] = useState('')
  const [showModal, setShowModal] = useState(false)
  console.log(data)
  useEffect(() => {
    list()
  }, [PID])
  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = FeedbackListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.main_type = main_type
    req.filter.rel_type = rel_type
    if (!isNullOrEmpty(props.uid)) {
      req.filter.uid = props.uid
    } else {
      req.filter.uid = ''
    }
    if (!isNullOrEmpty(props.relID)) {
      req.filter.rel_id = props.relID
    } else {
      req.filter.rel_id = ''
    }
    FeedbackList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }

  const mainTypeFormat = (cell: any, row: any) => {
    return (
      <div className='text-center'>
        {cell == 0 ? (
          <span className='badge badge-light-danger'>{t('common:FEEDBACK.complain')}</span>
        ) : (
          <span className='badge badge-light-primary'>{t('common:FEEDBACK.feedback')}</span>
        )}
      </div>
    )
  }
  const relTypeFormat = (cell: any, row: any) => {
    return (
      <div className='text-center'>
        {cell == null ? (
          <span className='badge badge-light-success'>{t('common:FEEDBACK.main')}</span>
        ) : cell == 'template' ? (
          <span className='badge badge-light-warning'>{t('common:FEEDBACK.template')}</span>
        ) : (
          <span className='badge badge-light-info'>{t('common:FEEDBACK.element')}</span>
        )}
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div style={{minWidth: '166px'}} className='text-center'>
        {isNullOrEmpty(row.attach) ? null : (
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='paper-clip' className='fs-3' />
          </div>
        )}

        <div
          onClick={() => {
            setShowModal(true)
            setItem(row)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='switch' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    FeedbackDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  const closeModalFunc = () => {
    setShowModal(false)
    setLoading(false)
  }

  const clearFilter = () => {
    setMain_type('')
    setRel_type('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:FEEDBACK.type'),
        dataField: 'main_type',
        dataSort: false,
        dataFormat: mainTypeFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FEEDBACK.rel_type'),
        dataField: 'rel_type',
        dataSort: false,
        dataFormat: relTypeFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FEEDBACK.rel_id'),
        dataField: 'rel_id',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:NEWS.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateTimeFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:NEWS.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <FeedbackMore t={t} item={item} closeModalFunc={closeModalFunc} />
        </CustomModal>
      ) : null}
      {props.type === 'component' ? null : (
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:FEEDBACK.pagetitle')}</PageTitle>
      )}

      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              className='filter flex items-center justify-center gap-0 cursor-pointer'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <div onClick={list} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setMain_type(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='main_type'
                    value={main_type}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='1'>{t('common:FEEDBACK.feedback')}</option>
                    <option value='0'>{t('common:FEEDBACK.complain')}</option>
                  </select>
                </div>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setRel_type(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='rel_type'
                    value={rel_type}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='elements'>{t('common:FEEDBACK.element')}</option>
                    <option value='template'>{t('common:FEEDBACK.template')}</option>
                  </select>
                </div>
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:FEEDBACK.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:FEEDBACK.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {Feedback}
