import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {NewsCreate} from './add'
import {dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  NewsChangeStatusReq,
  NewsContentAddReq,
  NewsContentGetReq,
  NewsListReq,
  PagesContentAddReq,
  PagesContentGetReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  NewsChangeStatus,
  NewsContentAdd,
  NewsContentGet,
  NewsContentUpdate,
  NewsDelete,
  NewsList,
  PagesContentAdd,
  PagesContentGet,
  PagesContentUpdate,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams, useSearchParams} from 'react-router-dom'
import EditorComponent from '../../modules/CustomEditor/EditorCompoenent'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Pages',
    path: '/pages',
    isSeparator: false,
    isActive: false,
  },
]

const PageMore: FC = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState('')
  const [lang, setLang] = useState('mn')
  const [bodyContent, setBodyContent] = useState('')
  useEffect(() => {
    get()
  }, [lang])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = PagesContentGetReq
    req.page.pageid = id ? id : ''
    req.page.lang = lang
    PagesContentGet(req, onGetSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    var data = response?.data?.body
    if (!isNullOrEmpty(data) && isNullOrEmpty(bodyContent)) {
      setBodyContent(JSON.parse(data))
    }
  }

  const save = () => {
    if (!isNullOrEmpty(body)) {
      if (!loading) {
        setLoading(true)
      }
      var req = PagesContentAddReq
      req.page.pageid = id ? id : ''
      req.page.type = 'page'
      req.page.lang = lang
      req.page.body = JSON.stringify(body)
      if (isNullOrEmpty(bodyContent)) {
        PagesContentAdd(req, onSuccess, onFailed, 'POST')
      } else {
        PagesContentUpdate(req, onSuccess, onFailed, 'PUT')
      }
    }
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    return toast.success(t('common:successUpdate'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const editorJson = (data: any) => {
    if (!isNullOrEmpty(data)) {
      setBody(data)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{title}</PageTitle>
      <div style={{textAlign: 'right'}}>
        <button className='btn btn-primary align-self-center' onClick={save} disabled={loading}>
          {loading ? t('common:loading') : t('common:save')}
        </button>
      </div>
      <div className='card mt-5'>
        <div className='card-toolbar p-10'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#mn'
                onClick={() => {
                  setLang('mn')
                  setBodyContent('')
                }}
              >
                mn
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#en'
                onClick={() => {
                  setLang('en')
                  setBodyContent('')
                }}
              >
                en
              </a>
            </li>
          </ul>
        </div>
        <div className='tab-content'>
          {lang == 'mn' ? (
            <div className='mn'>
              <EditorComponent data={bodyContent} editorJson={editorJson} />
            </div>
          ) : null}
          {lang == 'en' ? (
            <div className='en'>
              <EditorComponent data={bodyContent} editorJson={editorJson} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export {PageMore}
