import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty, isValidEmail} from '../../../services/Utils'
import {
  DesignerAdd,
  DesignerChangePass,
  DesignerUpdate,
  NewsAdd,
  NewsChangeImage,
  NewsUpdate,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  DesignerAddReq,
  DesignerChangePassReq,
  DesignerUpdateReq,
  NewsAddReq,
  NewsChangeImageReq,
  NewsUpdateReq,
  magicnum,
} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const ChangePass: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>([])

  useEffect(() => {
    setId(item?.id)
  }, [item])
  
  const update = () => {
    var res = validateFunc();
    if (res.retType === 0) {
      setLaoding(true)
      var req = DesignerChangePassReq;
      req.designerID = id;
      req.newPassword = password;
      req.IsAdmin = true;
      DesignerChangePass(req, onUpdatSuccess, onFailed, "POST");
    }
  };

  const onUpdatSuccess = () => {
    setLaoding(false)
    onAddSuccess('Хэрэглэгчийн нууц үг амжилттай солигдлоо.')
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(id)) {
      res.retType = 1
      validate['id'] = true
    }

    if (isNullOrEmpty(password)){
      res.retType = 1
      validate['password'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:USERS.change_pass')}</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {t('common:USERS.change_pass')}
          </span>
        </div>

        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:USERS.name')}
            value={id}
            disabled
          ></input>
          {validate['id'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:USERS.password')}
            onChange={(e) => {
              validateChange('password', e)
              setPassword(e.target.value)
            }}
            value={password}
          ></input>
          {validate['password'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : t('common:submit')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {ChangePass}
