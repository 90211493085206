import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {dateFormat, dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {BaseRequest} from '../../../services/Request'
import {CDN_URL, OrgGet} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import {OrgLogos} from './logo'
import {OrgColors} from './colors'
import {OrgFonts} from './fonts'
import {OrgElements} from './elements'
import { Users } from '../users'
import { OrgPpt } from './ppt'
import { OrgPdf } from './pdf'

const OrgMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('users')
  useEffect(() => {
    get()
  }, [id])
  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    OrgGet(id, req, onGetSuccess, onFailed, 'GET')
  }
  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response?.Org)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  return (
    <>
      <div className={`card mb-5`}>
        <div style={{overflowX:"auto"}} className='card-header border-1'>
          <div className='flex gap-8 col-lg-3 col-form-label w-full card-header-flex'>
            <div className='zurag py-8'>
              {isNullOrEmpty(data?.logo) ? (
                <div
                  style={{
                    width: '80px',
                    height: '100px',
                    borderRadius: '10px',
                    backgroundColor: '#ecf0f1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  No Image
                </div>
              ) : (
                <img
                  src={CDN_URL + data?.logo}
                  style={{borderRadius: '15px', maxHeight: '100px'}}
                  alt=''
                />
              )}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'>
              <div className='w-200px flex gap-3 items-center'>
                <KTIcon iconName='user' iconType='outline' className='fs-2' />
                <span>{data?.name}</span>
              </div>
              <div className='w-200px flex gap-3 items-center'>
                <KTIcon iconName='user' iconType='outline' className='fs-2' />
                <span>{data?.register}</span>
              </div>
              <div className='flex gap-3 items-center'>
                <KTIcon iconName='barcode' iconType='outline' className='fs-2' />
                <span>{data?.id}</span>
              </div>
              <div className='flex gap-3 items-center'>
                <KTIcon iconName='barcode' iconType='outline' className='fs-2' />
                <span>{data?.url}</span>
              </div>
            </div>

            <div className='flex flex-col gap-4 p-8 infos'>
              <div className=' flex gap-3 items-center'>
                <KTIcon iconName='calendar-2' iconType='outline' className='fs-2' />
                <span>{dateFormat(data?.created_at)}</span>
              </div>
              <div className='flex gap-3 items-center'>
                <KTIcon iconName='dropbox' iconType='outline' className='fs-2' />
                <span>{data?.bagts}</span>
              </div>
              <div className='flex gap-3 items-center'>
                <KTIcon iconName='time' iconType='outline' className='fs-2' />
                {isNullOrEmpty(data?.bagts_hugatsaa) ? (
                  <span className='badge badge-light-warning'>{t('common:USERS:inActive')}</span>
                ) : (
                  <>
                    <span className='badge badge-light-success'>{t('common:USERS:active')}</span>
                    <span>{dateFormat(data?.bagts_hugatsaa)}</span>
                  </>
                )}
              </div>
              {/* <button onClick={() => setShowModal(true)} className='btn btn-primary'>
                activate
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div  className='card'>
        <div style={{overflowX:"auto"}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'users',
                })}
                onClick={() => setTab('users')}
                role='tab'
              >
                Users
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'logo',
                })}
                onClick={() => setTab('logo')}
                role='tab'
              >
                Logo
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'colors',
                })}
                onClick={() => setTab('colors')}
                role='tab'
              >
                Colors
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'fonts',
                })}
                onClick={() => setTab('fonts')}
                role='tab'
              >
                Fonts
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'elements',
                })}
                onClick={() => setTab('elements')}
                role='tab'
              >
                Elements
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'ppt',
                })}
                onClick={() => setTab('ppt')}
                role='tab'
              >
                PPT
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'pdf',
                })}
                onClick={() => setTab('pdf')}
                role='tab'
              >
                Brandbook
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'users' && !isNullOrEmpty(data?.id) ? (
              <Users type='component' orgId={id}/>
            ) : null}
            {tab === 'logo' && !isNullOrEmpty(data?.id) ? (
              <OrgLogos type='component' uid={data?.id} />
            ) : null}
            {tab === 'colors' && !isNullOrEmpty(data?.id) ? (
              <OrgColors type='component' uid={data?.id} />
            ) : null}
            {tab === 'fonts' && !isNullOrEmpty(data?.id) ? (
              <OrgFonts type='component' uid={data?.id} />
            ) : null}
            {tab === 'elements' && !isNullOrEmpty(data?.id) ? (
              <OrgElements type='component' uid={data?.id} />
            ) : null}
            {tab === 'ppt' && !isNullOrEmpty(data?.id) ? (
              <OrgPpt type='component' uid={data?.id} />
            ) : null}
             {tab === 'pdf' && !isNullOrEmpty(data?.id) ? (
              <OrgPdf type='component' uid={data?.id} />
            ) : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {OrgMore}
