import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import CustomModal from '../../../modules/CustomModal/modal'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  BaseRequest,
  DElementChangeBBStatusReq,
  DElementChangeStatusReq,
  DElementListReq,
  magicnum,
} from '../../../../services/Request'
import {
  CDN_URL,
  DElementChangeBBStatus,
  DElementChangeStatus,
  DElementDelete,
  DElementList,
} from '../../../../services/main'
import {KTIcon} from '../../../../_metronic/helpers'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {NewsCreate} from '../../news/add'
import ModalImage from 'react-modal-image'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Element: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [created_by, setCreated_by] = useState('')
  const [category, setCategory] = useState('')
  const [sub_category, setSub_category] = useState('')
  const [is_public, setIs_public] = useState('')
  const [name, setName] = useState('')
  console.log(data)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DElementListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.skey = 'admin'
    req.filter.created_by = created_by
    req.filter.category = category
    req.filter.sub_category = sub_category
    req.filter.is_public = is_public
    req.filter.name = name
    DElementList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const PublicRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer'}}>
        <span className={cell === 1 ? 'badge badge-light-success' : 'badge badge-light-warning'}>
          {t(`common:magicnum.Public.${cell}`)}
        </span>
      </div>
    )
  }
  const StatusRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer'}}>
        <span
          onClick={() => submit(row, 'changeStatus')}
          className={cell === 2 ? 'badge badge-light-primary' : 'badge badge-light-warning'}
        >
          {t(`common:magicnum.StatusE.${cell}`)}
        </span>
      </div>
    )
  }
  const onlyBBRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer'}}>
        <span
          onClick={() => submit(row, 'changeonlyBB')}
          className={cell === 0 ? 'badge badge-light-primary' : 'badge badge-light-warning'}
        >
          {t(`common:magicnum.StatusBBE.${cell}`)}
        </span>
      </div>
    )
  }
  const imgRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer', objectFit: 'contain'}}>
        <ModalImage
          className='max-w-200px max-h-100px'
          small={CDN_URL + cell}
          large={CDN_URL + cell}
          alt={row.id}
        />
      </div>
    )
  }
  const categoryRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <span>{t(`common:magicnum.ElementCategory.${cell}`)}</span>
        <span>{t(`common:magicnum.ElementSubCategory.${cell}-${row.sub_category}`)}</span>
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: '105px'}}>
        <Link to={'/element/' + row.id}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain'
                    ? deleteFunc(row)
                    : type == 'changeonlyBB'
                    ? changeBBStatus(row)
                    : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    DElementDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = DElementChangeStatusReq
    req.element.id = row.id
    if (row.status == 0) {
      req.element.status = '2'
    }
    if (row.status == 2) {
      req.element.status = '0'
    }

    DElementChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const changeBBStatus = (row: any) => {
    var req = DElementChangeBBStatusReq
    req.element.id = row.id
    if (row.level == 0) {
      req.element.level = '1'
    }
    if (row.level == 1) {
      req.element.level = '0'
    }

    DElementChangeBBStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    setCreated_by('')
    setIs_public('')
    setCategory('')
    setSub_category('')
    list()
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:ELEMENT.id'),
        dataField: 'id',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:ELEMENT.element'),
        dataField: 'preview',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.category'),
        dataField: 'category',
        dataSort: false,
        dataFormat: categoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.is_public'),
        dataField: 'is_public',
        dataSort: false,
        dataFormat: PublicRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: StatusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Org',
        dataField: 'level',
        dataSort: false,
        dataFormat: onlyBBRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.designer'),
        dataField: 'created_by',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:NEWS.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:ELEMENT.element')}</PageTitle>

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            style={{cursor: 'pointer'}}
            className='filter flex items-center justify-center gap-0'
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <div onClick={list} className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </div>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row flex items-center filter-wrap'>
              <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Name'
                  value={name}
                />
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='category'
                  value={category}
                >
                  <option value=''>{t('common:ELEMENT.select_cat')}</option>
                  {magicnum.ElementCategory.map((pitem) => {
                    return (
                      <option value={pitem}>{t(`common:magicnum.ElementCategory.${pitem}`)}</option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setSub_category(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='sub_category'
                  value={sub_category}
                >
                  <option value=''>{t('common:ELEMENT.select_sub_cat')}</option>
                  {magicnum.ElementSubCategory.filter(
                    (sub: any) => sub.parent.toString() === category
                  ).map((pitem) => {
                    return (
                      <option value={pitem.id}>
                        {t(`common:magicnum.ElementSubCategory.${category}-${pitem.id}`)}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  type='text'
                  onChange={(e) => setCreated_by(e.target.value)}
                  placeholder='Created by'
                  value={created_by}
                />
              </div>
              <div className='col'>
                <select
                  onChange={(e) => {
                    setIs_public(e.target.value)
                  }}
                  className='form-select form-select-sm form-select-solid'
                  name='is_public'
                  value={is_public}
                >
                  <option value=''>{t('common:ELEMENT.select_pub')}</option>
                  <option value='1'>{t(`common:magicnum.Public.1`)}</option>
                  <option value='0'>{t(`common:magicnum.Public.0`)}</option>
                </select>
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <NewsCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:ELEMENT.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:ELEMENT.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Element}
