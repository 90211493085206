import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  dateFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
} from '../../../services/Utils'
import {
  BaseRequest,
  DTempleteChangeStatusReq,
  EventListReq,
  EventUserListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  DTemapleteChangeStatus,
  EventList,
  EventUserList,
  deleteEvent,
  deleteQRCode,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import ModalImage from 'react-modal-image'
import {useSearchParams} from 'react-router-dom'
import {EventEdit} from './edit'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const Event: FC<Props> = (props) => {
  const [params, setParams] = useSearchParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [users, setusers] = useState(0)

  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [uid, setUid] = useState('')
  useEffect(() => {
    list()
  }, [PID])
  
  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = EventListReq
    req.pid = PID
    req.pSize = sizePerPage
    // req.filter.qr_type = qr_type
    // req.filter.status = status
    req.filter.uid = props.type == 'component' ? props.uid : uid
    EventList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const imgRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer', objectFit: 'contain'}}>
        <ModalImage
          className='max-h-100px max-w-150px'
          small={CDN_URL + cell}
          large={CDN_URL + cell}
          alt={row.id}
        />
      </div>
    )
  }
  const usersRowFormat = (cell: any, row: any) => {
    return <span>{cell}</span>
  }

  const dateRowFormat = (cell: any, row: any) => {
    return <div className='flex justify-center'>{dateFormat(cell)}</div>
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <Link to={'/event/' + row.id}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p> {t('common:confirmDelete')}</p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    deleteEvent(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const clearFilter = () => {
    setUid('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Preview',
        dataField: 'preview',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Event name',
        dataField: 'name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Event users',
        dataField: 'totalview',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Created at',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <EventEdit t={t} item={item} type='edit' onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {/* {orgModal ? (
        <CustomModal
          visible={orgModal}
          closeModal={() => setOrgModal(false)}
          className='VideoModal'
        >
          <TemplateEdit t={t} item={item} type="org" onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}

      {/* {tagModal ? (
        <CustomModal
          visible={tagModal}
          closeModal={() => setTagModal(false)}
          className='VideoModal'
        >
          <TemplateTag t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}
      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Event</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setUid(e.target.value)}
                      placeholder='User id'
                      value={uid}
                    />
                  </div>
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Event list</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} events</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Event}
