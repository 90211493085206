import {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../../services/Utils'
import {CDN_URL, QRTravelRouteChangemap} from '../../../../services/main'
import {toast} from 'react-toastify'
import {QRTravelRouteChangemapReq} from '../../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const AddMap: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [base64img, setBase64img] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [validate, setValidate] = useState<any>([])
console.log(item)
  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = QRTravelRouteChangemapReq
      req.route.map = base64img
      req.route.old_map = item?.map
      req.route.id = item?.id
      QRTravelRouteChangemap(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }
  const onUpdateSuccess = () => {
    setLoading(false)
    onAddSuccess('Map амжилттай нэмлээ')
  }
  const onFailed = (err: string) => {
    setLoading(false)
    return toast.error(err)
  }

  const getBase64 = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBase64img(reader.result)
    }
  }
  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(base64img)) {
      res.retType = 1
      validate['image'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{isNullOrEmpty(item?.map) ? 'Add Map Image' : 'Edit Map Image'}</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {isNullOrEmpty(item?.map) ? 'Add Map Image' : 'Edit Map Image'}
          </span>
        </div>
        {isNullOrEmpty(item?.map) ? (
          <div className='mb-4'>
            <input onChange={(e) => getBase64(e.target.files?.[0])} type='file' />
          </div>
        ) : (
          <div className='flex flex-col'>
            <div className='mb-4'>
              <h4>Old map image</h4>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img height={200} src={CDN_URL + item?.map} alt='old_map' />
              </div>
            </div>
            <div className='my-4'>
              <h4>New map image</h4>
              {isNullOrEmpty(base64img) ? (
                <input onChange={(e) => getBase64(e.target.files?.[0])} type='file' />
              ) : (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img className='mx-auto' height={200} src={base64img} alt='old_map' />
                  <div
                    onClick={() => setBase64img('')}
                    style={{
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: '#00000090',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <i style={{color: 'white'}} className='fa-solid fa-x fs-3 '></i>
                  </div>
                </div>
              )}
              {validate['image'] ? (
                <div className='fv-plugins-message-container'>
                  <p className='fv-help-block'>Зураг оруулна уу !</p>
                </div>
              ) : null}
            </div>
          </div>
        )}

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : 'Save'}
          </button>
        </div>
      </div>
    </div>
  )
}

export {AddMap}
