import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  dateAgo,
  dateFormat,
  dateTimeFormat,
  isNullOrEmpty,
  moneyFormat,
} from '../../../services/Utils'
import {
  BaseRequest,
  DesignerListReq,
  NewsChangeStatusReq,
  magicnum,
} from '../../../services/Request'
import {CDN_URL, DesignerDelete, DesignerList, NewsChangeStatus} from '../../../services/main'
import {UserCreate} from './add'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import Subscription from './subscription'
import {UserBan} from './ban'
import {ChangePass} from './changePass'
import {PutOrg} from './putorg'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  orgId?: any
}

const Users: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [provider, setProvider] = useState('')
  const [status, setStatus] = useState('')
  const [showModalSungalt, setShowModalSungalt] = useState(false)
  const [banShowModal, setBanShowModal] = useState(false)
  const [changePassShowModal, setChangePassShowModal] = useState(false)
  const [orgidModal, setOrgidModal] = useState(false)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DesignerListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.name = name
    req.filter.email = email
    req.filter.mobile = mobile
    req.filter.provider = provider
    req.filter.id = id
    req.filter.org_id = props.orgId
    DesignerList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <span>{cell}</span>
  }

  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-4'>
        {row.level == '-1' ? (
          <KTIcon iconName='cross' className='fs-1 text-danger' iconType='solid' />
        ) : null}

        <div style={{display: 'flex', gap: 10}}>
          <Link to={`/user/${row.id}`}>
            {isNullOrEmpty(row.image) ? (
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '10px',
                  backgroundColor: '#ecf0f1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {cell.substring(0, 1)}
              </div>
            ) : (
              <img src={row.image} width={53} height={53} style={{borderRadius: '6px'}} />
            )}
          </Link>
          <div
            className='cursor-pointer'
            style={{display: 'flex', flexDirection: 'column', gap: 0}}
          >
            <Link to={`/user/${row.id}`}>
              <span>{cell}</span>
            </Link>
            <span style={{fontSize: 11}}>{row.mobile}</span>
            <span style={{fontSize: 11}}>{row.email}</span>
          </div>
        </div>
      </div>
    )
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: 215}}>
        <div
          onClick={() => {
            setItem(row)
            setShowModalSungalt(true)
          }}
          title='subscribe'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='time' className='fs-3' />
        </div>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Add employee to Org'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setOrgidModal(true)
          }}
        >
          {isNullOrEmpty(row.org_id) ? (
            <i className='fa-solid fa-briefcase fs-4'></i>
          ) : (
            <i className='fa-solid fa-briefcase fs-4' style={{color: 'green'}}></i>
          )}
        </div>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
        <div
          title='change password'
          onClick={() => {
            setItem(row)
            setChangePassShowModal(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='key' className='fs-3' />
        </div>
        <div
          title='user ban'
          onClick={() => {
            setItem(row)
            setBanShowModal(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='minus-circle' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    DesignerDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = NewsChangeStatusReq
    req.News.id = row.id
    if (row.status == magicnum.Status.InActive) {
      req.News.status = magicnum.Status.Active
    }
    if (row.status == magicnum.Status.Active) {
      req.News.status = magicnum.Status.InActive
    }

    NewsChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }

  const rowDueDate = (cell: any, row: any) => {
    return isNullOrEmpty(cell) ? (
      <div
        onClick={() => {
          setShowModalSungalt(true)
          setItem(row)
        }}
        className='text-center cursor-pointer'
      >
        Сунгалт хийх
      </div>
    ) : (
      <div
        onClick={() => {
          setShowModalSungalt(true)
          setItem(row)
        }}
        className='text-center cursor-pointer'
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        {dateAgo(cell) < 1 ? (
          <span className='badge badge-light-warning'>Сунгалт дууссан : {dateAgo(cell)}</span>
        ) : (
          <span className='badge badge-light-success'>Хүчинтэй өдөр : {dateAgo(cell)}</span>
        )}
        <span style={{fontSize: 10}}>
          {row.bagts}-{dateFormat(cell)}
        </span>
      </div>
    )
  }
  const rowProviderFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    setBanShowModal(false)
    setChangePassShowModal(false)
    return toast.success(text)
  }
  const onBanSuccess = (text: string) => {
    list()
    setBanShowModal(false)
    setOrgidModal(false)
    return toast.success(text)
  }
  const clearFilter = () => {
    setName('')
    setEmail('')
    setProvider('')
    setStatus('')
    setMobile('')
    setId('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      // {
      //   // title: t('common:ORG.org_id'),
      //   dataField: 'level',
      //   dataSort: false,
      //   dataFormat: defaultRowFormat,
      //   hidden: false,
      //   filter: {
      //     type: 'TextFilter',
      //     placeholder: '...',
      //   },
      // },

      {
        title: t('common:USERS.pagetitle'),
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:USERS.provider'),
        dataField: 'provider',
        dataSort: false,
        dataFormat: rowProviderFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:SUBSCRIPTION.duusah_hugatsaa'),
        dataField: 'bagts_hugatsaa',
        dataSort: false,
        dataFormat: rowDueDate,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  const plcName = t('common:USERS.name')
  const plcEmail = t('common:USERS.email')
  const plcMobile = t('common:USERS.pNumber')

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <UserCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}

      {showModalSungalt ? (
        <CustomModal
          visible={showModalSungalt}
          closeModal={() => setShowModalSungalt(false)}
          className='VideoModal'
        >
          <Subscription t={t} item={item} type='fromusers' onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {banShowModal ? (
        <CustomModal
          visible={banShowModal}
          closeModal={() => setBanShowModal(false)}
          className='VideoModal'
        >
          <UserBan
            t={t}
            item={item}
            onBanSuccess={onBanSuccess}
            setBanShowModal={setBanShowModal}
          />
        </CustomModal>
      ) : null}
      {changePassShowModal ? (
        <CustomModal
          visible={changePassShowModal}
          closeModal={() => setChangePassShowModal(false)}
          className='VideoModal'
        >
          <ChangePass t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      {orgidModal ? (
        <CustomModal
          visible={orgidModal}
          closeModal={() => setOrgidModal(false)}
          className='VideoModal'
        >
          <PutOrg t={t} item={item} onBanSuccess={onBanSuccess} setOrgidModal={setOrgidModal} />
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:USERS.pagetitle')}</PageTitle>
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              onClick={() => setShowFilter(!showFilter)}
              className='filter flex items-center justify-center gap-0 cursor-pointer'
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <a onClick={() => list()} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </a>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='id'
                    type='number'
                    onChange={(e) => setId(e.target.value)}
                    placeholder='ID'
                    value={id}
                  />
                </div>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='name'
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Name'
                    value={name}
                  />
                </div>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='mobile'
                    type='text'
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder={plcMobile}
                    value={mobile}
                  />
                </div>
                <div className='col'>
                  <input
                    className='form-control form-control-sm form-control-solid'
                    id='email'
                    type='text'
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={plcEmail}
                    value={email}
                  />
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setProvider(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='provider'
                    value={provider}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='google'>{t('common:USERS.google')}</option>
                    <option value='facebook'>{t('common:USERS.facebook')}</option>
                    <option value='grado'>{t('common:USERS.grado')}</option>
                    <option value='web'>{t('common:USERS.web')}</option>
                    <option value='admin'>{t('common:USERS.admin')}</option>
                  </select>
                </div>
                <div className='col flex gap-4 items-center'>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value={magicnum.Status.InActive}>{t('common:USERS.inActive')}</option>
                    <option value={magicnum.Status.Active}>{t('common:USERS.active')}</option>
                  </select>
                </div>
                <div className='col'>
                  <a
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS.clear')}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:USERS.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:USERS.total', {count: PTotal})}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                updateShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              {t('common:USERS.add')}
            </a>
          </div>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Users}
