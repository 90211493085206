import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {NewsCreate} from './add'
import {dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  NewsChangeStatusReq,
  NewsContentAddReq,
  NewsContentGetReq,
  NewsListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  NewsChangeStatus,
  NewsContentAdd,
  NewsContentGet,
  NewsContentUpdate,
  NewsDelete,
  NewsList,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams, useSearchParams} from 'react-router-dom'
import EditorComponent from '../../modules/CustomEditor/EditorCompoenent'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'News',
    path: '/news',
    isSeparator: false,
    isActive: false,
  },
]

const NewsMore: FC = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState('')
  const [bodyContent, setBodyContent] = useState('')
  useEffect(() => {
    get()
  }, [id])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = NewsContentGetReq
    req.News.rel_id = id ? id : ''
    req.News.type = 'news'
    NewsContentGet(req, onGetSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    var data = response?.data?.body
    if (!isNullOrEmpty(data)) {
      setBodyContent(JSON.parse(data))
    }
  }

  const save = () => {
    if (!isNullOrEmpty(body)) {
      if (!loading) {
        setLoading(true)
      }
      var req = NewsContentAddReq
      req.News.rel_id = id ? id : ''
      req.News.type = 'news'
      req.News.body = JSON.stringify(body)
      if(isNullOrEmpty(bodyContent)){
        NewsContentAdd(req, onSuccess, onFailed, 'POST')
      }else{
        NewsContentUpdate(req, onSuccess, onFailed, 'PUT')
      }
      
    }
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    return toast.success(t('common:successUpdate'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const editorJson = (data: any) => {
    if (!isNullOrEmpty(data)) {
      setBody(data)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{title}</PageTitle>
      <div style={{textAlign: 'right'}}>
        <button className='btn btn-primary align-self-center' onClick={save} disabled={loading}>
          {loading ? t('common:loading') : t('common:save')}
        </button>
      </div>
      <div className='card mt-5'>
        <EditorComponent data={bodyContent} editorJson={editorJson} />
      </div>
    </>
  )
}

export {NewsMore}
