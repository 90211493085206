import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {NewsAdd, NewsUpdate, PagesAdd, PagesUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {NewsAddReq, NewsUpdateReq, PagesAddReq, PagesUpdateReq, magicnum} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const NewsCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [title, setTitle] = useState(isNullOrEmpty(item) ? '' : item.title)
  const [pageid, setPageid] = useState(isNullOrEmpty(item) ? '' : item.pageid)  
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = PagesAddReq
      req.page.title = title
      req.page.pageid = pageid
      PagesAdd(req, onSuccess, onFailed, 'POST')
    }
  }


  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = PagesUpdateReq
      req.page.title = title
      req.page.pageid = pageid
      req.page.id = item.id
      PagesUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(title)) {
      res.retType = 1
      validate['title'] = true
    }

    if (isNullOrEmpty(pageid)) {
      res.retType = 1
      validate['pageid'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:PAGES.pagetitle')}</h1>
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:PAGES.title')}
            onChange={(e) => {
              setTitle(e.target.value)
              validateChange('title', e)
            }}
            value={title}
          ></input>
          {validate['title'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:PAGES.pageid')}
            onChange={(e) => {
              setPageid(e.target.value)
              validateChange('pageid', e)
            }}
            value={pageid}
          ></input>
          {validate['pageid'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {NewsCreate}
