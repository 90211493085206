import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import CustomModal from '../../../modules/CustomModal/modal'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {DTemplateMakeReq, magicnum} from '../../../../services/Request'
import {CDN_URL, DTemplateMake} from '../../../../services/main'
import {KTIcon} from '../../../../_metronic/helpers'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {isNullOrEmpty} from '../../../../services/Utils'
import ModalImage from 'react-modal-image'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const UserCreated: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState<any>(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [tid, setTid] = useState('')
  const [did, setDid] = useState('')
  const [uid, setUid] = useState('')
  const [type, setType] = useState('')
  const [prevImage, setPrevImage] = useState('')
  const [imageModal, setImageModal] = useState(false)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DTemplateMakeReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.tid = tid
    req.filter.did = did
    if (!isNullOrEmpty(props.uid)) {
      req.filter.uid = props.uid
    } else {
      req.filter.uid = uid
    }
    req.filter.type = type
    DTemplateMake(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  console.log(data)
  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const imgRowFormat = (cell: any, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer', objectFit: 'contain'}}>
        <ModalImage
          className='max-w-150px max-h-100px'
          small={CDN_URL + cell}
          large={CDN_URL + cell}
          alt={row.id}
        />
      </div>
    )
  }
  const userRowFormat = (cell: any, row: any) => {
    return (
      <Link to={'/user/' + row.uid}>
        <p className='text-center'>{cell}</p>
      </Link>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: '105px'}}>
        <Link to={`/newsmore/${cell}?title=${row.title}`}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            // setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  //   type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  //   const deleteFunc = (row: any) => {
  //     NewsDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  //   }
  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }
  //   const changeStatus = (row: any) => {
  //     var req = NewsChangeStatusReq
  //     req.News.id = row.id
  //     if (row.status == magicnum.Status.InActive) {
  //       req.News.status = magicnum.Status.Active
  //     }
  //     if (row.status == magicnum.Status.Active) {
  //       req.News.status = magicnum.Status.InActive
  //     }

  //     NewsChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  //   }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setDid('')
    setTid('')
    setUid('')
    setType('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:TEMPLATE.user_created'),
        dataField: 'preview',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.templates'),
        dataField: 'realimg',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.user'),
        dataField: 'name',
        dataSort: false,
        dataFormat: userRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:NEWS.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {props.type === 'component' ? null : (
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:TEMPLATE.templates')}</PageTitle>
      )}
      {imageModal ? (
        <CustomModal
          visible={imageModal}
          closeModal={() => setImageModal(false)}
          className='VideoModal'
        >
          <img style={{objectFit: 'cover'}} src={CDN_URL + item} width='100%' />
        </CustomModal>
      ) : null}
      {!isNullOrEmpty(prevImage) ? (
        <ModalImage small={prevImage} large={prevImage} alt='Hello World!' />
      ) : null}
      {props.type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              style={{cursor: 'pointer'}}
              className='filter flex items-center justify-center gap-0'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <div onClick={list} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body flex gap-4 py-3'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Tid'
                  value={tid}
                  onChange={(e) => setTid(e.target.value)}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Did'
                  value={did}
                  onChange={(e) => setDid(e.target.value)}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Uid'
                  value={uid}
                  onChange={(e) => setUid(e.target.value)}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Type'
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:TEMPLATE.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:TEMPLATE.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {UserCreated}
