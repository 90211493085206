import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {NewsAdd, NewsChangeImage, NewsUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {NewsAddReq, NewsChangeImageReq, NewsUpdateReq, magicnum} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const NewsCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [title, setTitle] = useState(isNullOrEmpty(item) ? '' : item.title)
  const [desc, setDesc] = useState(isNullOrEmpty(item) ? '' : item.description)
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [lang, setLang] = useState(isNullOrEmpty(item) ? 'mn' : item.lang)
  const [category, setCategory] = useState(isNullOrEmpty(item) ? '' : item.category)
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>([])

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = NewsAddReq
      req.news.title = title
      req.news.desc = desc
      req.news.lang = lang
      req.news.category = category
      req.news.image_link = imageBase64
      NewsAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaodingImageData(true)
    if (event.target.files) {
      var file = event.target.files[0]
      var reader = new FileReader()

      reader.onload = function (event) {
        setImageBase64(event?.target?.result)
        setLaodingImageData(false)
      }

      reader.readAsDataURL(file)
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const changeimage = () => {
    if (!isNullOrEmpty(imageBase64) && !isNullOrEmpty(item)) {
      setLaoding(true)
      var req = NewsChangeImageReq
      req.news.image_link = imageBase64
      req.news.old_image_link = item?.image_link
      req.news.id = item?.id
      NewsChangeImage(req, onChangeImageSuccess, onFailed, 'PUT')
    } else {
      return toast.warning(t('common:valid'))
    }
  }

  const onChangeImageSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successonChangeImage'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = NewsUpdateReq
      req.News.title = title
      req.News.desc = desc
      req.News.lang = lang
      req.News.category = category
      req.News.id = item.id
      NewsUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(imageBase64) && isNullOrEmpty(item)) {
      res.retType = 1
      validate['image'] = true
    }

    if (isNullOrEmpty(title)) {
      res.retType = 1
      validate['title'] = true
    }

    if (isNullOrEmpty(desc)) {
      res.retType = 1
      validate['desc'] = true
    }

    if (isNullOrEmpty(category)) {
      res.retType = 1
      validate['category'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:NEWS.pagetitle')}</h1>
        </div>

        <div className='row'>
          <div className='col-lg-6 fv-row mb-8'>
            <label htmlFor='files' className='btn btn-light-primary fw-bolder'>
              {loadingImageData ? t('common:loading') : t('common:NEWS.image_link')}
            </label>
            {validate['image'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validSelect')}</div>
              </div>
            ) : null}
          </div>
          {!isNullOrEmpty(item) ? (
            <div className='col-lg-6 fv-row mb-8'>
              <button
                className='btn btn-primary align-self-center'
                onClick={changeimage}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:changeimage')}
              </button>
            </div>
          ) : null}
        </div>

        <input
          type='file'
          name='image'
          id='files'
          style={{display: 'none'}}
          accept='.pdf,.jpg,.png,.jpeg'
          onChange={handleSelectFile}
        />
        {isNullOrEmpty(imageBase64) ? null : (
          <div className='d-flex flex-center'>
            <img src={imageBase64} width={200} height={100} />
          </div>
        )}
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>text</span>
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:NEWS.title')}
            onChange={(e) => {
              setTitle(e.target.value)
              validateChange('title', e)
            }}
            value={title}
          ></input>
          {validate['title'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <textarea
            className='form-control form-control-solid'
            rows={3}
            value={desc}
            placeholder={t('common:NEWS.desc')}
            onChange={(e) => {
              setDesc(e.target.value)
              validateChange('desc', e)
            }}
          ></textarea>
          {validate['desc'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name='category'
            data-control='select2'
            data-hide-search='true'
            value={category}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setCategory(e.target.value)
              validateChange('category', e)
            }}
          >
            <option value=''>{t('common:NEWS.selectcategory')}</option>
            {magicnum.news.map((item2, index) => {
              return (
                <option value={item2} key={index}>
                  {t(`common:magicnum.news.${item2}`)}
                </option>
              )
            })}
          </select>
          {validate['category'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>

        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-white form-select-sm'
          onChange={(e) => setLang(e.target.value)}
          value={lang}
        >
          <option value='mn'>mn</option>
          <option value='en'>en</option>
        </select>
        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {NewsCreate}
