import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {CDN_URL, DesignerUpdate, OrgAdd, OrgUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {DesignerUpdateReq, OrgAddReq, OrgUpdateReq, magicnum} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const OrgCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [register, setRegister] = useState('')
  const [isToriin, setIsToriin] = useState('')
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>(false)
  const [categories, setCategories] = useState<any>([])
  const [templates, settemplates] = useState<any>(false)
  const [brandbook, setbrandbook] = useState<any>(false)
  const [slides, setslides] = useState<any>(false)
  const [logo, setlogo] = useState<any>(false)
  const [color, setcolor] = useState<any>(false)
  const [fonts, setfonts] = useState<any>(false)
  const [elements, setelements] = useState<any>(false)
  const [characters, setcharacters] = useState<any>(false)
  console.log(categories)

  useEffect(() => {
    var temp = []
    if (templates) {
      temp.push("templates")
    } else {
      categories.filter((el: any) => el == 'templates')
    }

    if (slides) {
      temp.push('slides')
    } else {
      categories.filter((el: any) => el == 'slides')
    }

    if (brandbook) {
      temp.push('brandbook')
    } else {
      categories.filter((el: any) => el == 'brandbook')
    }

    if (logo) {
      temp.push('logo')
    } else {
      categories.filter((el: any) => el == 'logo')
    }

    if (color) {
      temp.push('color')
    } else {
      categories.filter((el: any) => el == 'color')
    }

    if (fonts) {
      temp.push('fonts')
    } else {
      categories.filter((el: any) => el == 'fonts')
    }

    if (elements) {
      temp.push('elements')
    } else {
      categories.filter((el: any) => el == 'elements')
    }

    if (characters) {
      temp.push('characters')
    } else {
      categories.filter((el: any) => el == 'characters')
    }
    setCategories(temp)
  }, [templates, brandbook, slides, logo, color, fonts, elements, characters])

  useEffect(() => {
    setName(item?.name)
    setUrl(item?.url)
    setRegister(item?.register)
    setIsToriin(item?.umch)
  }, [item])

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = OrgAddReq
      req.org.name = name
      req.org.url = url
      req.org.register = register
      req.org.logo = imageBase64
      req.org.umch = isToriin
      OrgAdd(req, onRegisterSuccess, onFailed, 'POST')
    }
  }
  const onRegisterSuccess = () => {
    setLaoding(false)
    onAddSuccess('Шинэ хэрэглэгч амжилттай нэмлээ')
  }
  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = OrgUpdateReq
      req.org.name = name
      req.org.register = register
      req.org.umch = isToriin
      req.org.url = url
      req.org.id = item?.id
      OrgUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }
  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }

    if (isNullOrEmpty(url)) {
      res.retType = 1
      validate['url'] = true
    }

    if (isNullOrEmpty(register)) {
      res.retType = 1
      validate['register'] = true
    }
    if (isNullOrEmpty(isToriin)) {
      res.retType = 1
      validate['isToriin'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }
  const getBase64 = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = function () {
      setImageBase64(reader.result)
    }
  }
  

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 modal-width' style={{paddingInline: '50px'}}>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>
            {isNullOrEmpty(item) ? t('common:ORG.add') : t('common:ORG.update')}
          </h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {isNullOrEmpty(item) ? t('common:ORG.add') : t('common:ORG.update')}
          </span>
        </div>
        {!isNullOrEmpty(item) ? (
          <div className='mb-4 flex justify-center'>
            <img width={100} src={CDN_URL + item.logo} alt='' />
          </div>
        ) : null}
        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:ORG.name')}
            onChange={(e) => {
              setName(e.target.value)
              validateChange('name', e)
            }}
            value={name}
          ></input>
          {validate['name'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid'
            value={url}
            placeholder={t('common:ORG.url')}
            onChange={(e) => {
              setUrl(e.target.value)
              validateChange('url', e)
            }}
          ></input>
          {validate['url'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <input
            type='register'
            className='form-control form-control-solid'
            placeholder={t('common:ORG.register')}
            onChange={(e) => {
              setRegister(e.target.value)
              validateChange('register', e)
            }}
            value={register}
          ></input>
          {validate['register'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        {isNullOrEmpty(item) ? (
          <div className='mb-4'>
            <div className='image-upload'>
              <input onChange={(e) => getBase64(e.target.files)} type='file' />
            </div>
          </div>
        ) : null}

        <div className='mb-4'>
          <select
            onChange={(e) => {
              setIsToriin(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='status'
            value={isToriin}
            id=''
          >
            <option value={''}>{t('common:validSelect')}</option>
            <option value={'tor'}>{t('common:ORG.toriin')}</option>
            <option value={'huvi'}>{t('common:ORG.huviin')}</option>
          </select>
          {validate['isToriin'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='flex flex-wrap gap-8'>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => settemplates(!templates)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Templates</span>
            </label>
          </div>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setbrandbook(!brandbook)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Brandbook</span>
            </label>
          </div>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setslides(!slides)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Slides</span>
            </label>
          </div>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setlogo(!logo)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Logos</span>
            </label>
          </div>
        </div>
        <div className='flex flex-wrap gap-8'>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setcolor(!color)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Colors</span>
            </label>
          </div>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setfonts(!fonts)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Fonts</span>
            </label>
          </div>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setelements(!elements)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Elements</span>
            </label>
          </div>
          <div className='mb-4'>
            <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                // checked={isExistonArr(1)}
                onChange={() => setcharacters(!characters)}
              />
              <span className='form-check-label fw-bold text-gray-400'>Characters</span>
            </label>
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {isNullOrEmpty(item)
              ? loading
                ? t('common:loading')
                : t('common:save')
              : t('common:edit')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {OrgCreate}
