import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import CustomModal from '../../../modules/CustomModal/modal'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {DFontsListReq, magicnum} from '../../../../services/Request'
import {CDN_URL, DFontsList} from '../../../../services/main'
import {KTIcon} from '../../../../_metronic/helpers'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {NewsCreate} from '../../news/add'
import {dateFormat} from '../../../../services/Utils'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Fonts: FC = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [name, setName] = useState('')
  const [created_by, setCreated_by] = useState('')

  console.log(data)
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DFontsListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.family = name
    req.filter.created_by = created_by
    req.filter.skey = 'admin'
    DFontsList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const defaultdateFormat = (cell: any, row: any) => {
    return <div className='text-center'>{dateFormat(cell)}</div>
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{minWidth: '105px'}}>
        <Link to={`/newsmore/${cell}?title=${row.title}`}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const onAddSuccess = (text: string) => {
    list()
    updateShowModal(false)
    return toast.success(text)
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  //   type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  //   const deleteFunc = (row: any) => {
  //     NewsDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  //   }
  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }
  //   const changeStatus = (row: any) => {
  //     var req = NewsChangeStatusReq
  //     req.News.id = row.id
  //     if (row.status == magicnum.Status.InActive) {
  //       req.News.status = magicnum.Status.Active
  //     }
  //     if (row.status == magicnum.Status.Active) {
  //       req.News.status = magicnum.Status.InActive
  //     }

  //     NewsChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  //   }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    setCreated_by('')
    list()
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:FONT.id'),
        dataField: 'id',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.fFamily'),
        dataField: 'family',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.designer'),
        dataField: 'created_by',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:FONT.date'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: defaultdateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:NEWS.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:FONT.font')}</PageTitle>

      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            className='filter flex items-center justify-center gap-0'
            style={{cursor: 'pointer'}}
            onClick={() => setShowFilter(!showFilter)}
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <div onClick={list} className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </div>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body'>
            <div className='row flex items-center filter-wrap'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Font name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Created by'
                  value={created_by}
                  onChange={(e) => setCreated_by(e.target.value)}
                />
              </div>
              <div className='col'>
                <a
                  onClick={() => clearFilter()}
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  {t('common:USERS.clear')}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <NewsCreate t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:FONT.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:FONT.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Fonts}
