import React, {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {CDN_URL} from '../../../services/main'
import {isNullOrEmpty} from '../../../services/Utils'
import {spawn} from 'child_process'
import {Link} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'News',
    path: '/news',
    isSeparator: false,
    isActive: false,
  },
]
interface IProps {
  t: any
  item: any
  closeModalFunc: any
}
const FeedbackMore: FC<IProps> = ({t, item, closeModalFunc}) => {
  const [loading, setLoading] = useState(false)
  const [desc, setDesc] = useState('')
  const [attach, setAttach] = useState('')
  const [main_type, setMain_type] = useState('')
  const [rel_type, setRel_type] = useState('')

  useEffect(() => {
    setDesc(item.desc)
    setAttach(item.attach)
    setMain_type(item.main_type)
    setRel_type(item.rel_type)
  }, [item])

  return (
    <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 w-600px'>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>asfasf</PageTitle>

      <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
        <div className='card mx-5 p-6'>
          <div style={{display: 'flex', gap: '8px', marginBottom: '12px'}}>
            {main_type == '0' ? (
              <span className='badge badge-light-danger'>{t('common:FEEDBACK.complain')}</span>
            ) : (
              <span className='badge badge-light-primary'>{t('common:FEEDBACK.feedback')}</span>
            )}
            {rel_type == null ? (
              <span className='badge badge-light-success'>{t('common:FEEDBACK.main')}</span>
            ) : rel_type == 'template' ? (
              <Link to={'/template/' + item?.rel_id}>
                <span className='badge badge-light-warning'>
                  {t('common:FEEDBACK.template')} ID: {item?.rel_id}
                </span>
              </Link>
            ) : (
              <Link to={'/element/' + item?.rel_id}>
                <span className='badge badge-light-info'>
                  {t('common:FEEDBACK.element')} ID: {item?.rel_id}
                </span>
              </Link>
            )}
            {!isNullOrEmpty(item.uid) ? (
              <Link to={'/user/' + item.uid} className='badge badge-light-info'>
                {t('common:FEEDBACK.userid')}: {item.uid}
              </Link>
            ) : (
              <div>
                <span className='badge badge-light-danger'>{item.uname}</span>
                <span className='badge badge-light-primary'>{item.uphone}</span>
              </div>
            )}
          </div>
          <div className=''>{desc}</div>
        </div>
        <div className='flex justify-center'>
          {isNullOrEmpty(attach) ? null : <img width={560} src={CDN_URL + attach} alt='' />}
        </div>
      </div>
    </div>
  )
}

export {FeedbackMore}
