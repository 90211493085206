import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {PrivList} from '../pages/privilege/Priv/PrivList'
import {PrivGroupList} from '../pages/privilege/PrivGroup/PrivGroupList'
import {PrivGroupMore} from '../pages/privilege/PrivGroup/PrivGroupMore'
import {News} from '../pages/news/index'
import {NewsMore} from '../pages/news/more'
import {Page} from '../pages/page/index'
import {Video} from '../pages/video/index'
import {PageMore} from '../pages/page/more'
import {Employee} from '../pages/employee/index'
import {Subscription} from '../pages/subscription/index'
import {LetterBanner} from '../pages/letterbanner/index'
import {School} from '../pages/school/index'
import {Users} from '../pages/users/index'
import {UsersMore} from '../pages/users/more'
import {EmployeeMore} from '../pages/employee/more'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { LetterBannerLast } from '../pages/letterbanner/last'
import { LetterBannerMore } from '../pages/letterbanner/more'
import { Feedback } from '../pages/feedback'
import { Orgs } from '../pages/org'
import { OrgMore } from '../pages/org/more'
import { OrgLogos } from '../pages/org/logo'
import { OrgColors } from '../pages/org/colors'
import { OrgFonts } from '../pages/org/fonts'
import { OrgElements } from '../pages/org/elements'
// import { Template } from 'webpack'
import { UserCreate } from '../pages/users/add'
import { UserCreated } from '../pages/template/user_created'
import { Fonts } from '../pages/template/font'
import { Element } from '../pages/template/element'
import { ElementsMore } from '../pages/template/element/more'
import { Template } from '../pages/template'
import { TemplateMore } from '../pages/template/more'
import { OrgPpt } from '../pages/org/ppt'
import { OrgPdf } from '../pages/org/pdf'
import { Qr } from '../pages/qr'
import { Event } from '../pages/event'
import { EventMore } from '../pages/event/more'
import { SearchPage } from '../pages/search'
import { Flipbook } from '../pages/flipbook'
import { SearchTimes } from '../pages/search/searchTimes'
import { FlipbookMore } from '../pages/flipbook/more'
import { QrMore } from '../pages/qr/more'
import { TravelRoute } from '../pages/travel/route'
import { TravelList } from '../pages/travel'
import { TravelMore } from '../pages/travel/more'
import { UsersTemplateCreated } from '../pages/template/usersTemplate'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/privilege/privlist' element={<PrivList />} />
        <Route path='/privilege/privgrouplist' element={<PrivGroupList />} />
        <Route path='/news' element={<News />} />
        <Route path='/subscription' element={<Subscription />} />
        <Route path='/letterbanners' element={<LetterBanner />} />
        <Route path='/letterbanner/last' element={<LetterBannerLast/>} />
        <Route path='/letterbanner/:id' element={<LetterBannerMore/>} />
        <Route path='/users' element={<Users />} />
        <Route path='/orgs' element={<Orgs />} />
        <Route path='/org/:id' element={<OrgMore/>} />
        <Route path='/org/logos' element={<OrgLogos/>} />
        <Route path='/org/colors' element={<OrgColors/>} />
        <Route path='/templates' element={<Template/>} />
        <Route path='/templates/:id' element={<TemplateMore/>} />
        <Route path='/org/fonts' element={<OrgFonts/>} />
        <Route path='/org/elements' element={<OrgElements/>} />
        <Route path='/org/powerpoints' element={<OrgPpt/>} />
        <Route path='/org/PDF' element={<OrgPdf/>} />
        <Route path='/userstemplate' element={<UserCreated/>} />
        <Route path='/userstemplate/:pid' element={<UserCreated/>} />
        <Route path='/fonts' element={<Fonts/>} />
        <Route path='/element' element={<Element/>} />
        <Route path='/element/:id' element={<ElementsMore/>} />
        <Route path='/user/:id' element={<UsersMore />} />
        <Route path='/feedback' element={<Feedback/>} />
        <Route path='/videos' element={<Video />} />
        <Route path='/school' element={<School />} />
        <Route path='/newsmore/:id' element={<NewsMore />} />
        <Route path='/privilege/privgroup/:id' element={<PrivGroupMore />} />
        <Route path='/pages' element={<Page />} />
        <Route path='/page/:id' element={<PageMore />} />
        <Route path='/employee' element={<Employee />} />
        <Route path='/employeemore/:id' element={<EmployeeMore />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='/qr' element={<Qr/>} />
        <Route path='/qr/:id' element={<QrMore/>} />
        <Route path='/event' element={<Event/>} />
        <Route path='/event/:id' element={<EventMore />} />
        <Route path='/search-result' element={<SearchPage />} />
        <Route path='/search-times' element={<SearchTimes />} />
        <Route path='/flipbook' element={<Flipbook />} />
        <Route path='/flipbook/:id' element={<FlipbookMore />} />
        <Route path='/route' element={<TravelRoute />} />
        <Route path='/travel' element={<TravelList />} />
        <Route path='/travel/:id' element={<TravelMore />} />
        <Route path='/userstemplatecreated' element={<UsersTemplateCreated />} />
        

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
