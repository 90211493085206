import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {OrgFontsAddReq, OrgFontsUpdateReq, OrgLogosAddReq, magicnum} from '../../../../services/Request'
import {OrgFontsAdd, OrgFontsUpdate, OrglogosAdd} from '../../../../services/main'
import {isNullOrEmpty} from '../../../../services/Utils'
interface IProps {
  t: any
  id: any
  onAddSuccess: any
  item: any
}
const FontsAdd: FC<IProps> = ({t, id, onAddSuccess, item}) => {
  
  const [org_id, setOrg_id] = useState('')
  const [font_id, setFont_id] = useState('')
  const [validate, setValidate] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  useEffect(() => {
    setOrg_id(item?.org_id)
    setFont_id(item?.font_id)
  }, [item])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = OrgFontsAddReq
      req.org.org_id = isNullOrEmpty(id) ? org_id : id
      req.org.font_id = font_id
      OrgFontsAdd(req, onSuccess, onFailed, 'POST')
    }
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    onAddSuccess()
    return toast.success(t('common:successCreate'))
  }
  const edit = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = OrgFontsUpdateReq
      req.org.org_id = isNullOrEmpty(id) ? item?.org_id : id
      req.org.font_id = font_id
      req.org.id = item?.id

      OrgFontsUpdate(req, onEditSuccess, onFailed, 'PUT')
    }
  }
  const onEditSuccess = (response: any) => {
    setLoading(false)
    onAddSuccess()
    return toast.success(t('common:successUpdate'))
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(font_id)) {
      res.retType = 1
      validate['font_id'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  return (
    <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
      <span style={{visibility: 'hidden'}}></span>
      <div className='text-center mb-13'>
        {isNullOrEmpty(item) ? (
          <h1 className='mb-3'>{t('common:ORG.add_fonts')}</h1>
        ) : (
          <h1 className='mb-3'>{t('common:ORG.edit_fonts')}</h1>
        )}
      </div>
      <div className='separator mt-8 d-flex flex-center mb-8'>
        <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
          {isNullOrEmpty(item) ? t('common:ORG.add_fonts') : t('common:ORG.edit_fonts')}
        </span>
      </div>

      {isNullOrEmpty(id) ? (
        <div className='mb-4'>
          <input
            className='form-control form-control-solid'
            value={org_id}
            disabled
            placeholder={t('common:ORG.org_id')}
            onChange={(e) => {
              setOrg_id(e.target.value)
              validateChange('org_id', e)
            }}
          ></input>
          {validate['org_id'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className='mb-4'>
        <input
          type='text'
          placeholder='Font id'
          className='form-control form-control-solid w-300px'
          onChange={(e) => setFont_id(e.target.value)}
          value={font_id}
        ></input>
        {validate['font_id'] ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{t('common:validInsert')}</div>
          </div>
        ) : null}
      </div>

      <div className='pt-10' style={{textAlign: 'right'}}>
        {isNullOrEmpty(item) ? (
          <button className='btn btn-primary align-self-center' onClick={add} disabled={loading}>
            {loading ? t('common:loading') : t('common:save')}
          </button>
        ) : (
          <button className='btn btn-primary align-self-center' onClick={edit} disabled={loading}>
            {loading ? t('common:loading') : t('common:edit')}
          </button>
        )}
      </div>
    </div>
  )
}

export default FontsAdd
