import React, {FC, useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {
  QRTravelTextAdd,
  QRTravelTextGetItem,
  QRTravelTextUpdate,
  QRTravelTextUpdateAudio,
} from '../../../services/main'
import {
  magicnum,
  QRTravelTextAddReq,
  QRTravelTextGetItemReq,
  QRTravelTextUpdateAudioReq,
  QRTravelTextUpdateReq,
} from '../../../services/Request'
import {isNullOrEmpty} from '../../../services/Utils'
import clsx from 'clsx'
import {text} from 'stream/consumers'
import {useParams} from 'react-router-dom'
interface IProps {
  item: any
  settextinfo: any
  langs: any
}
const TravelInfo: FC<IProps> = ({item, settextinfo, langs}) => {
  const router = useParams()
  const [loadingList, setLoadingList] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatebtn, setUpdatebtn] = useState(false)
  const [textarea, setTextarea] = useState('')
  const [otherLang, setOtherLang] = useState('en')
  const [tab, setTab] = useState('main')
  const [data, setData] = useState<any>(undefined)
  const [validate, setValidate] = useState<any>([])
  const [count, setCount] = useState(0)
  const {t} = useTranslation()

  useEffect(() => {
    if (!isNullOrEmpty(item?.id)) {
      get()
    }
  }, [item, tab, otherLang])

  useEffect(() => {
    if (tab === 'other') {
      const shouldUpdate = langs.some((el: any) => el.lang === otherLang);
      setUpdatebtn(shouldUpdate);
    }
    if (tab === 'mn') {
      const shouldUpdate = langs.some((el: any) => el.lang === "mn");
      setUpdatebtn(shouldUpdate);
    }
  }, [tab, otherLang, langs]);

  const get = () => {
    setLoadingList(true)
    var req = QRTravelTextGetItemReq
    req.text.tid = item?.id
    req.text.lang = tab === 'main' ? 'aaa' : tab === 'mn' ? 'mn' : otherLang
    QRTravelTextGetItem(req, onGetSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setLoadingList(false)
    setTextarea(response?.data?.text)
    setData(response?.data)
    settextinfo(response?.data)
  }

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = QRTravelTextAddReq
      req.text.tid = item?.id
      req.text.text = textarea
      req.text.lang = tab === 'main' ? 'aaa' : tab === 'mn' ? 'mn' : otherLang
      QRTravelTextAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    get()
    return toast.success(t('common:successCreate'))
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = QRTravelTextUpdateReq
      req.text.id = data?.id
      req.text.text = textarea
      QRTravelTextUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }
  const updateAudio = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = QRTravelTextUpdateAudioReq
      req.text.id = data?.id
      req.text.text = textarea
      req.text.tid = router.id as string
      req.text.voiceID =
        tab === 'mn' ? 'mn-MN-YesuiNeural' : otherLang === 'kk' ? 'kk-KZ-AigulNeural' : ''
      req.text.lang = tab === 'main' ? 'aaa' : tab === 'mn' ? 'mn' : otherLang
      QRTravelTextUpdateAudio(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = (response: any) => {
    setLoading(false)
    get()
    return toast.success(t('common:successUpdate'))
  }

  const onFailed = (error: string) => {
    setLoading(false)
    setLoadingList(false)
    return toast.error(error)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(textarea)) {
      res.retType = 1
      validate['textarea'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div style={{marginTop: '-8px'}} className='edit-modal mobile:w-full w-[600px]'>
      <div className='px-5'>
        <ul
          className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
          role='tablist'
        >
          <li className='nav-item'>
            <div
              className={clsx(`nav-link cursor-pointer`, {
                active: tab === 'main',
              })}
              onClick={() => {
                setTab('main')
                setTextarea('')
              }}
              role='tab'
            >
              Үндсэн
            </div>
          </li>
          <li className='nav-item'>
            <div
              className={clsx(`nav-link cursor-pointer`, {
                active: tab === 'mn',
              })}
              onClick={() => {
                setTab('mn')
                setTextarea('')
              }}
              role='tab'
            >
              Монгол
            </div>
          </li>
          <li className='nav-item'>
            <div
              className={clsx(`nav-link cursor-pointer`, {
                active: tab === 'other',
              })}
              onClick={() => {
                setTab('other')
                setTextarea('')
              }}
              role='tab'
            >
              Бусад
            </div>
          </li>
        </ul>
      </div>
      <div className='form' style={{marginTop: '-20px'}}>
        <div className='card-body'>
          <div className='w-100% flex flex-col gap-3 items-end'>
            {tab === 'other' ? (
              <select
                onChange={(e) => setOtherLang(e.target.value)}
                className='form-select form-select-sm form-select-solid'
                value={otherLang}
              >
                {magicnum.travelLanguage
                  .filter((el) => el.id != 1)
                  .map((el) => {
                    return <option value={el.short_code}>{el.name}</option>
                  })}
              </select>
            ) : null}
            {loading ? (
              <span>loading</span>
            ) : (
              <textarea
                style={{width: '100%', height: '400px', resize: 'none'}}
                value={textarea}
                onChange={(e) => {
                  setTextarea(e.target.value)
                  validateChange('textarea', e)
                }}
              />
            )}
            {validate['textarea'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>текст оруулна уу</div>
              </div>
            ) : null}
            <div className='flex gap-3'>
              {tab !== 'main' && updatebtn ? (
                <div
                  onClick={() => {
                    updateAudio()
                  }}
                  className='btn btn-secondary align-self-center'
                >
                  <span>Хадгалаад mp3 үүсгэх</span>
                </div>
              ) : null}

              <div
                onClick={() => {
                  isNullOrEmpty(data) ? add() : update()
                }}
                className='btn btn-primary align-self-center'
              >
                <span>Хадгалах</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TravelInfo
