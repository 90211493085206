import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'
import {BaseRequest, DesignerListReq, SearchListReq, magicnum} from '../../../services/Request'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {
  DashboardByFlipbook,
  DashboardByLetterBanner,
  DashboardByQR,
  DashboardBySearch,
  DashboardByStatus,
  DashboardBySubs,
  DashboardByTracking,
  DashboardByUser,
  DesignerList,
  SearchList,
} from '../../../services/main'
import {isNullOrEmpty, moneyFormat} from '../../../services/Utils'
import {Link} from 'react-router-dom'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type query = {
  status: 'string'
}
type link = {pathname: string; query: {status: string}}

// const DashboardPage = () => {
//   useEffect(() => {
//     // We have to show toolbar only for dashboard page
//     document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
//     return () => {
//       document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
//     }
//   }, [])

//   return <></>
// }

const DashboardWrapper = () => {
  const intl = useIntl()
  const {t} = useTranslation()
  const [loadinguser, setLoadinguser] = useState(false)
  const [loadingtemplate, setLoadingtemplate] = useState(false)
  const [loadingsubs, setLoadingsubs] = useState(false)
  const [loadingbanner, setLoadingbanner] = useState(false)
  const [loadingstat, setLoadingstat] = useState(false)
  const [loadingStatistic, setLoadingStatistic] = useState(false)
  const [loadingQr, setLoadingQr] = useState(false)
  const [loadingFlipbook, setLoadingFlipbook] = useState(false)

  const [userlastday, setUserlastday] = useState(0)
  const [designerlastday, setDesignerlastday] = useState(0)
  const [userlastweek, setUserlastweek] = useState(0)
  const [designerlastweek, setDesignerlastweek] = useState(0)
  const [userlastmonth, setUserlastmonth] = useState(0)
  const [designerlastmonth, setDesignerlastmonth] = useState(0)
  const [userAll, setUserAll] = useState(0)
  const [designerAll, setDesignerAll] = useState(0)

  const [activelastday, setActivelastday] = useState(0)
  const [inactivelastday, setInactivelastday] = useState(0)
  const [activelastweek, setActivelastweek] = useState(0)
  const [inactivelastweek, setInactivelastweek] = useState(0)
  const [activelastmonth, setActivelastmonth] = useState(0)
  const [inactivelastmonth, setInactivelastmonth] = useState(0)
  const [activeAll, setActiveAll] = useState(0)
  const [inactiveAll, setInactiveAll] = useState(0)

  const [substoday, setSubstoday] = useState(0)
  const [subsweek, setSubsweek] = useState(0)
  const [subsmonth, setSubsmonth] = useState(0)
  const [subsalltime, setSubsalltime] = useState(0)

  const [showToday, setShowToday] = useState(true)
  const [showWeek, setShowWeek] = useState(true)
  const [showMonth, setShowMonth] = useState(false)
  const [showAlltime, setShowAlltime] = useState(false)

  const [letterBannertoday, setLetterBannertoday] = useState(0)
  const [letterBannerweek, setLetterBannerweek] = useState(0)
  const [letterBannermonth, setLetterBannermonth] = useState(0)
  const [letterBanneralltime, setLetterBanneralltime] = useState(0)

  const [download, setDownload] = useState(0)
  const [view, setView] = useState(0)
  const [edit, setEdit] = useState(0)

  const [alltotalprice, setalltotalprice] = useState<any>(0)
  const [lastdaytotalprice, setlastdaytotalprice] = useState<any>(0)
  const [lastweektotalprice, setlastweektotalprice] = useState<any>(0)
  const [lastmonthtotalprice, setlastmonthtotalprice] = useState<any>(0)

  const [bannerlastdayprice, setBannerlastdayprice] = useState<any>(0)
  const [bannerlastweekprice, setBannerlastweekprice] = useState<any>(0)
  const [bannerlastmonthprice, setBannerlastmonthprice] = useState<any>(0)
  const [bannerallprice, setBannerallprice] = useState<any>(0)

  const [qrlastday, setQrlastday] = useState<any>({count: 0, totalscan: 0})
  const [qrlastweek, setQrlastweek] = useState<any>({count: 0, totalscan: 0})
  const [qrlastmonth, setQrlastmonth] = useState<any>({count: 0, totalscan: 0})
  const [qrall, setQrall] = useState<any>({count: 0, totalscan: 0})

  const [flipbooklastday, setFlipbooklastday] = useState<any>({count: 0, totalscan: 0})
  const [flipbooklastweek, setFlipbooklastweek] = useState<any>({count: 0, totalscan: 0})
  const [flipbooklastmonth, setFlipbooklastmonth] = useState<any>({count: 0, totalscan: 0})
  const [flipbookall, setFlipbookall] = useState<any>({count: 0, totalscan: 0})

  const [lastdayData, setLastdayData] = useState([])
  const [lastdayDataNoData, setLastdayDataNoData] = useState([])
  const [lastweekData, setLastweekData] = useState([])
  const [lastweekDataNoData, setLastweekDataNoData] = useState([])

  useEffect(() => {
    getUser()
    SearchListFunc()
  }, [])

  const getUser = () => {
    if (!loadinguser) {
      setLoadinguser(true)
    }
    var req = BaseRequest
    DashboardByUser(req, onUserSuccess, onFailed, 'POST')
  }
  const onUserSuccess = (response: any) => {
    if (response.lastday?.length > 0) {
      if (response.lastday[0]?.is_designer == 0) {
        setUserlastday(response.lastday[0]?.count)
      }
      if (response.lastday[0]?.is_designer == 1) {
        setDesignerlastday(response.lastday[0]?.count)
      }
      if (response.lastday[1]?.is_designer == 0) {
        setUserlastday(response.lastday[1]?.count)
      }
      if (response.lastday[1]?.is_designer == 1) {
        setDesignerlastday(response.lastday[1]?.count)
      }
    }

    if (response.lastweek?.length > 0) {
      if (response.lastweek[0]?.is_designer == 0) {
        setUserlastweek(response.lastweek[0]?.count)
      }
      if (response.lastweek[0]?.is_designer == 1) {
        setDesignerlastweek(response.lastweek[0]?.count)
      }
      if (response.lastweek[1]?.is_designer == 0) {
        setUserlastweek(response.lastweek[1]?.count)
      }
      if (response.lastweek[1]?.is_designer == 1) {
        setDesignerlastweek(response.lastweek[1]?.count)
      }
    }

    if (response.lastmonth?.length > 0) {
      if (response.lastmonth[0]?.is_designer == 0) {
        setUserlastmonth(response.lastmonth[0]?.count)
      }
      if (response.lastmonth[0]?.is_designer == 1) {
        setDesignerlastmonth(response.lastmonth[0]?.count)
      }
      if (response.lastmonth[1]?.is_designer == 0) {
        setUserlastmonth(response.lastmonth[1]?.count)
      }
      if (response.lastmonth[1]?.is_designer == 1) {
        setDesignerlastmonth(response.lastmonth[1]?.count)
      }
    }

    if (response.all?.length > 0) {
      if (response.all[0]?.is_designer == 0) {
        setUserAll(response.all[0]?.count)
      }
      if (response.all[0]?.is_designer == 1) {
        setDesignerAll(response.all[0]?.count)
      }
      if (response.all[1]?.is_designer == 0) {
        setUserAll(response.all[1]?.count)
      }
      if (response.all[1]?.is_designer == 1) {
        setDesignerAll(response.all[1]?.count)
      }
    }
    getTemplates()
    setLoadinguser(false)
  }

  const getTemplates = () => {
    if (!loadingstat) {
      setLoadingtemplate(true)
    }
    var req = BaseRequest
    DashboardByStatus(req, onTemplateSuccess, onFailed, 'POST')
  }
  const onTemplateSuccess = (response: any) => {
    if (response.lastday?.length > 0) {
      if (response.lastday[0]?.status == 0) {
        setInactivelastday(response.lastday[0]?.count)
      }
      if (response.lastday[0]?.status == 1) {
        setActivelastday(response.lastday[0]?.count)
      }
      if (response.lastday[1]?.status == 0) {
        setInactivelastday(response.lastday[1]?.count)
      }
      if (response.lastday[1]?.status == 1) {
        setActivelastday(response.lastday[1]?.count)
      }
    }
    if (response.lastweek?.length > 0) {
      if (response.lastweek[0]?.status == 0) {
        setInactivelastweek(response.lastweek[0]?.count)
      }
      if (response.lastweek[0]?.status == 1) {
        setActivelastweek(response.lastweek[0]?.count)
      }
      if (response.lastweek[1]?.status == 0) {
        setInactivelastweek(response.lastweek[1]?.count)
      }
      if (response.lastweek[1]?.status == 1) {
        setActivelastweek(response.lastweek[1]?.count)
      }
    }
    if (response.lastmonth?.length > 0) {
      if (response.lastmonth[0]?.status == 0) {
        setInactivelastmonth(response.lastmonth[0]?.count)
      }
      if (response.lastmonth[0]?.status == 1) {
        setActivelastmonth(response.lastmonth[0]?.count)
      }
      if (response.lastmonth[1]?.status == 0) {
        setInactivelastmonth(response.lastmonth[1]?.count)
      }
      if (response.lastmonth[1]?.status == 1) {
        setActivelastmonth(response.lastmonth[1]?.count)
      }
    }
    if (response.all?.length > 0) {
      if (response.all[0]?.status == 0) {
        setInactiveAll(response.all[0]?.count)
      }
      if (response.all[0]?.status == 1) {
        setActiveAll(response.all[0]?.count)
      }
      if (response.all[1]?.status == 0) {
        setInactiveAll(response.all[1]?.count)
      }
      if (response.all[1]?.status == 1) {
        setActiveAll(response.all[1]?.count)
      }
    }
    getLetterBanner()
    setLoadingtemplate(false)
  }

  const getLetterBanner = () => {
    if (!loadingbanner) {
      setLoadingbanner(true)
    }
    var req = BaseRequest
    DashboardByLetterBanner(req, onLetterBannerSuccess, onFailed, 'POST')
  }
  const onLetterBannerSuccess = (response: any) => {
    setBannerlastdayprice(response.lastday[0].totalprice)
    setBannerlastweekprice(response.lastweek[0].totalprice)
    setBannerlastmonthprice(response.lastmonth[0].totalprice)
    setBannerallprice(response.all[0].totalprice)

    setLetterBannertoday(response.lastday[0].count)
    setLetterBannerweek(response.lastweek[0].count)
    setLetterBannermonth(response.lastmonth[0].count)
    setLetterBanneralltime(response.all[0].count)
    setLoadingbanner(false)
    getQR()
  }

  const getQR = () => {
    if (!loadingQr) {
      setLoadingQr(true)
    }
    var req = BaseRequest
    DashboardByQR(req, onQRSuccess, onFailed, 'POST')
  }
  const onQRSuccess = (response: any) => {
    setQrlastday(response.lastday[0])
    setQrlastweek(response.lastweek[0])
    setQrlastmonth(response.lastmonth[0])
    setQrall(response.all[0])
    setLoadingQr(false)
    getFlipbook()
  }

  const getFlipbook = () => {
    if (!loadingQr) {
      setLoadingFlipbook(true)
    }
    var req = BaseRequest
    DashboardByFlipbook(req, onFlipbookSuccess, onFailed, 'POST')
  }
  const onFlipbookSuccess = (response: any) => {
    setFlipbooklastday(response.lastday[0])
    setFlipbooklastweek(response.lastweek[0])
    setFlipbooklastmonth(response.lastmonth[0])
    setFlipbookall(response.all[0])
    setLoadingFlipbook(false)
    getSubs()
  }

  const getSubs = () => {
    if (!loadingsubs) {
      setLoadingsubs(true)
    }
    var req = BaseRequest
    DashboardBySubs(req, onSubsSuccess, onFailed, 'POST')
  }
  const onSubsSuccess = (response: any) => {
    setlastdaytotalprice(response.lastday[0].totalprice)
    setlastweektotalprice(response.lastweek[0].totalprice)
    setlastmonthtotalprice(response.lastmonth[0].totalprice)
    setalltotalprice(response.all[0].totalprice)

    setSubstoday(response.lastday[0].count)
    setSubsweek(response.lastweek[0].count)
    setSubsmonth(response.lastmonth[0].count)
    setSubsalltime(response.all[0].count)
    setLoadingsubs(false)
    getTracking()
  }

  const getTracking = () => {
    if (!loadingStatistic) {
      setLoadingStatistic(true)
    }
    var req = BaseRequest
    DashboardByTracking(req, onTrackingSuccess, onFailed, 'POST')
  }
  const onTrackingSuccess = (response: any) => {
    setDownload(response.all[0].total_download)
    setView(response.all[0].total_view)
    setEdit(response.all[0].total_edit)
    setLoadingStatistic(false)
  }

  const SearchListFunc = () => {
    var req = SearchListReq
    // req.pid = PID
    // req.pSize = sizePerPage
    req.filter.uid = ''
    DashboardBySearch(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLastdayData(response.lastday)
    setLastdayDataNoData(response.lastdayNoResult)
    setLastweekData(response.lastweek)
    setLastweekDataNoData(response.lastweekNoResult)
  }

  const onFailed = (error: any) => {
    return toast.error(error)
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col' style={{rowGap: 50}}>
          <div className='bot-border'>
            <div className='card gap-4 flex flex-row' style={{padding: '15px 25px'}}>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowToday(!showToday)}
                    checked={showToday}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>today</span>
                </label>
              </div>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowWeek(!showWeek)}
                    checked={showWeek}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>week</span>
                </label>
              </div>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowMonth(!showMonth)}
                    checked={showMonth}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>month</span>
                </label>
              </div>
              <div className='mb-4'>
                <label className='form-check form-switch form-check-custom form-check-solid pt-3'>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    onChange={() => setShowAlltime(!showAlltime)}
                    checked={showAlltime}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>all time</span>
                </label>
              </div>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Users</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/users'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <KTIcon className='fs-2x success' iconName='user-tick' />
                </div>
                {showToday ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        {userlastday + designerlastday == 0 ? null : (
                          <span className='fs-2x'>+</span>
                        )}
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {userlastday + designerlastday}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                    </div>
                    {designerlastday == 0 ? null : (
                      <span className='text-primary fs-6' style={{fontWeight: '500'}}>
                        {moneyFormat(designerlastday) + ' designer'}
                      </span>
                    )}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/users'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showWeek ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        {userlastweek + designerlastweek == 0 ? null : (
                          <span className='fs-2x'>+</span>
                        )}
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {userlastweek + designerlastweek}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                    </div>
                    {designerlastweek == 0 ? null : (
                      <span className='text-primary fs-6' style={{fontWeight: '500'}}>
                        {moneyFormat(designerlastweek) + ' designer'}
                      </span>
                    )}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/users'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Monthly</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showMonth ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        {userlastmonth + designerlastmonth == 0 ? null : (
                          <span className='fs-2x'>+</span>
                        )}
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {userlastmonth + designerlastmonth}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                    </div>
                    {designerlastmonth == 0 ? null : (
                      <span className='text-primary fs-6' style={{fontWeight: '500'}}>
                        {moneyFormat(designerlastmonth) + ' designer'}
                      </span>
                    )}
                  </div>
                ) : null}
              </Link>
              <Link
                to='/users'
                className={`card gap-4 ${loadinguser ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <KTIcon className='fs-2x' iconName='user-tick' />
                </div>
                {showAlltime ? (
                  <div>
                    <div className='flex' style={{alignItems: 'baseline'}}>
                      <div className='flex items-center'>
                        <span className='fs-2x'>+</span>
                        <span className='fs-3x' style={{fontWeight: '500'}}>
                          {moneyFormat(userAll + designerAll)}
                        </span>
                      </div>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        new
                      </span>
                    </div>
                    <Link to={'/users'}>
                      <span className='text-primary fs-6' style={{fontWeight: '500'}}>
                        {designerAll == 0 ? null : moneyFormat(designerAll) + ' designer'}
                      </span>
                    </Link>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Templates</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/templates'
                className={`card gap-4 ${loadingtemplate ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <KTIcon className='fs-1 success' iconName='folder-added' />
                </div>
                {showToday ? (
                  <div>
                    <div>
                      <span className='fs-2x'>
                        {activelastday + inactivelastday == 0 ? null : '+'}
                      </span>

                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {activelastday + inactivelastday}
                      </span>
                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {t(`common:DASHBOARD.new_template`)}
                      </span>
                    </div>
                    <Link to={`/templates?status=0`}>
                      {inactivelastday == 0 ? null : (
                        <span className='text-warning fs-6' style={{fontWeight: '500'}}>
                          {moneyFormat(inactivelastday) + ' ' + t(`common:DASHBOARD.inactive_temp`)}
                        </span>
                      )}
                    </Link>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/templates'
                className={`card gap-4 ${loadingtemplate ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <KTIcon className='fs-1' iconName='folder-added' />
                </div>
                {showWeek ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{activeAll + inactiveAll == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {activelastweek + inactivelastweek}
                      </span>
                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {t(`common:DASHBOARD.new_template`)}
                      </span>
                    </div>
                    <Link to={`/templates?status=0`}>
                      {inactivelastweek == 0 ? null : (
                        <span className='text-warning fs-6' style={{fontWeight: '500'}}>
                          {moneyFormat(inactivelastweek) +
                            ' ' +
                            t(`common:DASHBOARD.inactive_temp`)}
                        </span>
                      )}
                    </Link>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/templates'
                className={`card gap-4 ${loadingtemplate ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Monthly</span>
                  <KTIcon className='fs-1' iconName='folder-added' />
                </div>
                {showMonth ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{activeAll + inactiveAll == 0 ? null : '+'}</span>

                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {activelastmonth + inactivelastmonth}
                      </span>

                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {t(`common:DASHBOARD.new_template`)}
                      </span>
                    </div>
                    <Link to={`/templates?status=0`}>
                      {inactivelastmonth == 0 ? null : (
                        <span className='text-warning fs-6' style={{fontWeight: '500'}}>
                          {moneyFormat(inactivelastmonth) +
                            ' ' +
                            t(`common:DASHBOARD.inactive_temp`)}
                        </span>
                      )}
                    </Link>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/templates'
                className={`card gap-4 ${loadingtemplate ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <KTIcon className='fs-1' iconName='folder-added' />
                </div>
                {showAlltime ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{activeAll + inactiveAll == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {moneyFormat(activeAll + inactiveAll)}
                      </span>
                      <span
                        className='text-muted'
                        style={{fontSize: '14px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {t(`common:DASHBOARD.new_template`)}
                      </span>
                    </div>
                    <Link to={`/templates?status=0`}>
                      <span className='text-warning fs-6' style={{fontWeight: '500'}}>
                        {inactiveAll == 0
                          ? null
                          : moneyFormat(inactiveAll) + ' ' + t(`common:DASHBOARD.inactive_temp`)}
                      </span>
                    </Link>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Letterbanner</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/letterbanner/last'
                className={`card gap-4 ${loadingbanner ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <i className='fa-solid fa-a'></i>
                </div>
                {showToday ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{letterBannertoday == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {letterBannertoday}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {bannerlastdayprice == null ? null : moneyFormat(bannerlastdayprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/letterbanner/last'
                className={`card gap-4 ${loadingbanner ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <i className='fa-solid fa-a'></i>
                </div>
                {showWeek ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{letterBannerweek == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {letterBannerweek}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {bannerlastweekprice == null
                          ? null
                          : moneyFormat(bannerlastweekprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/letterbanner/last'
                className={`card gap-4 ${loadingbanner ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Monthly</span>
                  <i className='fa-solid fa-a'></i>
                </div>
                {showMonth ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{letterBannermonth == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {letterBannermonth}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {bannerlastmonthprice == null
                          ? null
                          : moneyFormat(bannerlastmonthprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/letterbanner/last'
                className={`card gap-4 ${loadingbanner ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <i className='fa-solid fa-a'></i>
                </div>
                {showAlltime ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{letterBanneralltime == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {letterBanneralltime}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {bannerallprice == null ? null : moneyFormat(bannerallprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>QR Code</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/qr'
                className={`card gap-4 ${loadingQr ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showToday ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{qrlastday.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {qrlastday.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {qrlastday.totalscan == null ? null : moneyFormat(qrlastday.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/qr'
                className={`card gap-4 ${loadingQr ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showWeek ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{qrlastweek.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {qrlastweek.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {qrlastweek.totalscan == null ? null : moneyFormat(qrlastweek.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/qr'
                className={`card gap-4 ${loadingQr ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>monthly</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showMonth ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{qrlastmonth.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {qrlastmonth.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {qrlastmonth.totalscan == null ? null : moneyFormat(qrlastmonth.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/qr'
                className={`card gap-4 ${loadingQr ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showAlltime ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{qrall.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {qrall.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {qrall.totalscan == null ? null : moneyFormat(qrall.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Flipbook</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/flipbook'
                className={`card gap-4 ${loadingFlipbook ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showToday ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{flipbooklastday.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {flipbooklastday.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {flipbooklastday.totalscan == null ? null : moneyFormat(flipbooklastday.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/flipbook'
                className={`card gap-4 ${loadingFlipbook ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showWeek ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{flipbooklastweek.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {flipbooklastweek.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {flipbooklastweek.totalscan == null ? null : moneyFormat(flipbooklastweek.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/flipbook'
                className={`card gap-4 ${loadingFlipbook ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>monthly</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showMonth ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{flipbooklastmonth.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {flipbooklastmonth.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {flipbooklastmonth.totalscan == null ? null : moneyFormat(flipbooklastmonth.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/flipbook'
                className={`card gap-4 ${loadingFlipbook ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <KTIcon className='fs-1 success' iconName='scan-barcode' />
                </div>
                {showAlltime ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{flipbookall.count == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {flipbookall.count}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {flipbookall.totalscan == null ? null : moneyFormat(flipbookall.totalscan)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Subscribers</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <Link
                to='/subscription'
                className={`card gap-4 ${loadingsubs ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Today</span>
                  <KTIcon className='fs-1 success' iconName='dollar' />
                </div>
                {showToday ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{substoday == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {substoday}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {lastdaytotalprice == null ? null : moneyFormat(lastdaytotalprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/subscription'
                className={`card gap-4 ${loadingsubs ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>Weekly</span>
                  <KTIcon className='fs-1 success' iconName='dollar' />
                </div>
                {showWeek ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{subsweek == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {subsweek}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {lastweektotalprice == null ? null : moneyFormat(lastweektotalprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/subscription'
                className={`card gap-4 ${loadingsubs ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>monthly</span>
                  <KTIcon className='fs-1 success' iconName='dollar' />
                </div>
                {showMonth ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{subsmonth == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {subsmonth}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {lastmonthtotalprice == null
                          ? null
                          : moneyFormat(lastmonthtotalprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
              <Link
                to='/subscription'
                className={`card gap-4 ${loadingsubs ? 'load' : null}`}
                style={{width: '33%', padding: '15px 25px'}}
              >
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-4 mb-1'>All time</span>
                  <KTIcon className='fs-1 success' iconName='dollar' />
                </div>
                {showAlltime ? (
                  <div>
                    <div>
                      <span className='fs-2x'>{subsalltime == 0 ? null : '+'}</span>
                      <span className='fs-3x' style={{fontWeight: '500'}}>
                        {subsalltime}
                      </span>
                      <span
                        className='text-primary'
                        style={{fontSize: '16px', fontWeight: '500', marginLeft: '12px'}}
                      >
                        {alltotalprice == null ? null : moneyFormat(alltotalprice, 'MNT')}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Link>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>{t(`common:DASHBOARD.statistic`)}</h3>
            <div className='row-xxl-4 flex gap-4 dashboard-bot'>
              <div className='card gap-4' style={{width: '33%', padding: '20px 30px'}}>
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-3 mb-1'>
                    {t(`common:DASHBOARD.t_view`)}
                  </span>
                  <KTIcon className='fs-2x' iconName='file' />
                </div>
                <span className='fs-3x' style={{fontWeight: '500'}}>
                  {moneyFormat(view)}
                </span>
              </div>
              <div className='card gap-4' style={{width: '33%', padding: '20px 30px'}}>
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-3 mb-1'>
                    {t(`common:DASHBOARD.t_edit`)}
                  </span>
                  <KTIcon className='fs-2x' iconName='update-file' />
                </div>
                <span className='fs-3x' style={{fontWeight: '500'}}>
                  {moneyFormat(edit)}
                </span>
              </div>
              <div className='card gap-4' style={{width: '33%', padding: '20px 30px'}}>
                <div className='w-100 flex items-center justify-between'>
                  <span className='card-label fw-bold fs-3 mb-1'>
                    {t(`common:DASHBOARD.t_download`)}
                  </span>
                  <KTIcon className='fs-2x success' iconName='file-down' />
                </div>
                <span className='fs-3x' style={{fontWeight: '500'}}>
                  {moneyFormat(download)}
                </span>
              </div>
            </div>
          </div>
          <div className='bot-border'>
            <h3 className='title-dashboard'>Searching history</h3>
            <div className='row-xxl-4 flex gap-4  dashboard-bot'>
              <div className='card gap-4 ' style={{width: '50%', padding: '20px 30px'}}>
                <h3>Today</h3>
                <div className='flex flex-wrap gap-2'>
                  {lastdayData.map((el: any, index: any) => {
                    return (
                      <div key={index} className='custom-tag'>
                        <span className='count'>{el.niit}</span>
                        <span className='tag-name'>{el.text}</span>
                        <span className='result'>({el.result})</span>
                      </div>
                    )
                  })}
                  {lastdayDataNoData.map((el: any, index: any) => {
                    return (
                      <div key={index} className='custom-tag-yellow'>
                        <span className='count'>{el.niit}</span>
                        <span className='tag-name'>{el.text}</span>
                        <span className='result'>({el.result})</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='card gap-4 ' style={{width: '50%', padding: '20px 30px'}}>
                <h3>This week</h3>
                <div className='flex flex-wrap gap-2'>
                  {lastweekData.map((el: any, index: any) => {
                    return (
                      <div key={index} className='custom-tag'>
                        <span className='count'>{el.niit}</span>
                        <span className='tag-name'>{el.text}</span>
                        <span className='result'>({el.result})</span>
                      </div>
                    )
                  })}
                  {lastweekDataNoData.map((el: any, index: any) => {
                    return (
                      <div key={index} className='custom-tag-yellow'>
                        <span className='count'>{el.niit}</span>
                        <span className='tag-name'>{el.text}</span>
                        <span className='result'>({el.result})</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
