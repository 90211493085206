import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {CDN_URL, DesignerUpdate, OrgAdd, OrgChangelogo, OrgUpdate, OrglogosChangelogo} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  DesignerUpdateReq,
  OrgAddReq,
  OrgChangelogoReq,
  OrgLogosChangelogoReq,
  OrgUpdateReq,
  magicnum,
} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const OrgLogoChange: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [validate, setValidate] = useState<any>([])

  console.log(item)
  const updateOrglogo = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = OrgChangelogoReq
      req.org.id = item.id
      req.org.logo = imageBase64
      req.org.old_logo = item.logo
      OrgChangelogo(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }
  const updateOrglogosLogo = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = OrgLogosChangelogoReq
      req.org.id = item.id
      req.org.logo = imageBase64
      req.org.old_logo = item.logo
      OrglogosChangelogo(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }
  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(imageBase64)) {
      res.retType = 1
      validate['imageBase64'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }
  const getBase64 = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = function () {
      setImageBase64(reader.result)
    }
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:ORG.update')}</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3 w-300px'>
            {t('common:ORG.update')}
          </span>
        </div>

        <div className='mb-4 flex justify-center'>
          <img width={100} src={CDN_URL + item.logo} alt='' />
        </div>

        <div className='mb-4'>
          <div className='image-upload'>
            <input
              onChange={(e) => {
                getBase64(e.target.files)
                validateChange('imageBase64', e)
              }}
              type='file'
            />
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={isNullOrEmpty(item.org_id) ? updateOrglogo : updateOrglogosLogo} disabled={loading}>
            {loading ? t('common:loading') : t('common:edit')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {OrgLogoChange}
