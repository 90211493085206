import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {BaseRequest} from '../../../services/Request'
import {LetterBannerGet} from '../../../services/main'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'LetterBanner',
    path: '/letterbanner',
    isSeparator: false,
    isActive: false,
  },
]

const LetterBannerMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>('')
console.log(data)
  useEffect(() => {
    get()
  }, [id])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    LetterBannerGet(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response?.data)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.pid}</PageTitle>

      <div className='card mt-5'></div>
    </>
  )
}

export {LetterBannerMore}
