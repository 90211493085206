import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {
  dateFormat,
  encodeString,
  isNullOrEmpty,
} from '../../../services/Utils'
import {BaseRequest} from '../../../services/Request'
import {CDN_URL, QrGetByID} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import MonthlyStat from './stats/time'
import LocationStat from './stats/location'
import ScanedDevices from './stats/device'
import ScanedOs from './stats/os'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Qr',
    path: '/qr',
    isSeparator: false,
    isActive: false,
  },
]

const QrMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('Time')

  console.log(data)

  useEffect(() => {
    get()
  }, [])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    QrGetByID(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.Org)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.name}</PageTitle>
      <div className={`card mb-5`}>
        <div style={{overflowX: 'auto'}} className='card-header border-1'>
          <div className='flex gap-8 col-lg-3 col-form-label w-full card-header-flex'>
            <div className='flex flex-col gap-4 p-8 infos'>
              <div className='w-200px flex gap-3 items-center'>
                <KTIcon iconName='user' iconType='outline' className='fs-2' />
                <Link to={'/user/' + data?.uid}><span>{data?.uid}</span></Link>
              </div>
              <div className='flex gap-3 items-center'>
                <KTIcon iconName='barcode' iconType='outline' className='fs-2' />
                <span>{encodeString(id) }</span>
              </div>
              <div className=' flex gap-3 items-center'>
                <KTIcon iconName='calendar-2' iconType='outline' className='fs-2' />
                <span>{dateFormat(data?.created_at)}</span>
              </div>
              <div className=' flex gap-3 items-center'>
                <KTIcon iconName='wanchain' iconType='outline' className='fs-2' />
                <a target='_blank' href={data.qr_url}>{data.qr_url}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div style={{overflowX: 'auto'}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'time',
                })}
                onClick={() => setTab('time')}
                role='tab'
              >
                Time
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Location',
                })}
                onClick={() => setTab('Location')}
                role='tab'
              >
                Location
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Device',
                })}
                onClick={() => setTab('Device')}
                role='tab'
              >
                Device
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Operation',
                })}
                onClick={() => setTab('Operation')}
                role='tab'
              >
                Operation system
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'time'? <MonthlyStat flipID={id}/> : null}
            {tab === 'Location'? <LocationStat flipID={id}/> : null}
            {tab === 'Device'? <ScanedDevices flipID={id}/> : null}
            {tab === 'Operation'? <ScanedOs flipID={id}/> : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {QrMore}
