import {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {DTemapleteUpdateInfo, DTemplateChangeOrg, FlipbookEditName, OrgList} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  DTemplateChangeOrgReq,
  DTempleteUpdateInfoReq,
  FlipbookEditNameReq,
  OrgListReq,
  magicnum,
} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
  type: any
}
const FlipbookEdit: FC<IProps> = ({t, item, type, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [orglist, setOrglist] = useState<any>([])
  const [orgInp, setOrgInp] = useState('')
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [bayar, setBayar] = useState('')
  const [level, setLevel] = useState('')
  const [validate, setValidate] = useState<any>([])
  console.log(item)

  useEffect(() => {
    setName(item?.name)
  }, [item])

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = FlipbookEditNameReq
      req.qr.name = name
      req.qr.id = item.id
      FlipbookEditName(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const updateOrg = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = DTemplateChangeOrgReq
      req.desgin.id = item?.id
      req.desgin.org_id = orgInp
      DTemplateChangeOrg(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    setValidate(validate)
    setCount(count + 1)
    return res
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 modal-width' style={{paddingInline: '50px'}}>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>
            {type == 'editname' ? "Flipbook update name" : t('common:TEMPLATE.update_org')}
          </h1>
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {type == 'editname' ? "Flipbook update name" : t('common:TEMPLATE.update_org')}
          </span>
        </div>
        {type == 'editname' ? (
          <div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                placeholder={t('common:ORG.name')}
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {type == 'org' ? (
          <div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                placeholder={t('common:ORG.name')}
                value={id}
                disabled
              ></input>
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setOrgInp(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                value={orgInp}
              >
                <option value={''}>{t('common:validSelect')}</option>
                {orglist.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              {validate['orgInp'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className='pt-10' style={{textAlign: 'right'}}>
          {type == 'editname' ? (
            <button
              className='btn btn-primary align-self-center'
              onClick={update}
              disabled={loading}
            >
              {loading ? t('common:loading') : t('common:edit')}
            </button>
          ) : null}
          {type == 'org' ? (
            <button
              className='btn btn-primary align-self-center'
              onClick={updateOrg}
              disabled={loading}
            >
              {loading ? t('common:loading') : t('common:save')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export {FlipbookEdit}
