import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {SizeUnits, dateFormat, encodeString, isNullOrEmpty} from '../../../services/Utils'
import {BaseRequest, QRGetReq, magicnum} from '../../../services/Request'
import {CDN_URL, FlipbookGetByID, QRTravelGet} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import TravelInfo from './text'
import TravelLang from './addLang'
import TravelImages from './images'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Travel',
    path: '/travel',
    isSeparator: false,
    isActive: false,
  },
]

const TravelMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>(undefined)
  const [text, setText] = useState<any>('')
  const [langs, setLangs] = useState<any>([])
  const [tab, setTab] = useState('Text')

  useEffect(() => {
    if (!isNullOrEmpty(id)) {
      get(id)
    }
  }, [id])

  const get = (id: any) => {
    if (!loading) {
      setLoading(true)
    }
    setLangs([])
    setData(undefined)
    var req = QRGetReq
    req.qr.mac = 'admin'
    req.qr.id = id
    QRTravelGet(req, onGetSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    const qrData = response?.qrData
    const qrLangs = response?.qrLangs
    setData(qrData)
    setLangs(qrLangs)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const getCity = (id: any) => {
    var name = ''
    magicnum.address_city.map((el) => {
      if (el.id == id) {
        name = el.name
      }
    })
    return name
  }
  const getDistrict = (id: any, cityId: any) => {
    var name = ''
    magicnum.address_district.map((el) => {
      if (el.id == id && el.city_id == cityId) {
        name = el.name
      }
    })
    return name
  }
  
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.name}</PageTitle>
      <div className={`card mb-5`}>
        <div style={{overflowX: 'auto'}} className='card-header border-1 w-full'>
          <div className='flex gap-8 col-form-label w-full card-header-flex'>
            <div className='flex gap-16 p-4 infos w-full'>
              <img style={{width: '180px'}} src={CDN_URL + data?.cover} alt='' />

              <div className='flex flex-col gap-2'>
                <span>
                  Байршил: {getCity(data?.city)}, {getDistrict(data?.district, data?.city)}
                </span>
                <span>Ангилал: {t(`common:magicnum.travelCategory.${data?.category}`)}</span>
                <span>Үүсгэсэн огноо: {dateFormat(data?.created_at)}</span>
                <span>Map холбоос: <a target='_blank' href={data?.map == '' ? 'empty' : data?.map}>{data?.map == '' ? 'empty' : data?.map}</a></span>
                <span>Өргөрөг: {data?.latit == '' ? 'empty' : data?.latit}</span>
                <span>Уртраг: {data?.longi == '' ? 'empty' : data?.longi}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div style={{overflowX: 'auto'}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Text',
                })}
                onClick={() => setTab('Text')}
                role='tab'
              >
                Text
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Languages',
                })}
                onClick={() => setTab('Languages')}
                role='tab'
              >
                Languages
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Images',
                })}
                onClick={() => setTab('Images')}
                role='tab'
              >
                Images
              </a>
            </li>
          </ul>
        </div>
        <div className='form'>
          <div className='card-body py-6'>
            {tab == 'Text' ? (
              <TravelInfo langs={langs} item={data} settextinfo={setText}/>
            ) : tab == 'Languages' ? (
              <TravelLang langs={langs} text={text} success={()=>{get(id)}}/>
            ) : tab == 'Images' ? (
              <TravelImages item={data} success={()=>{get(id)}}/>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export {TravelMore}
