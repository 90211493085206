import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CustomModal from '../../modules/CustomModal/modal'
import {dateFormat, dateTimeFormat, isNullOrEmpty, isValidEmail} from '../../../services/Utils'
import {BaseRequest} from '../../../services/Request'
import {DesignerGet} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import {Subscription} from '../subscription/index'
import {LetterBannerLast} from '../letterbanner/last'
import {Feedback} from '../feedback'
import {UserCreated} from '../template/user_created'
import {Qr} from '../qr'
import {Event} from '../event'
import {Flipbook} from '../flipbook'
import { Search } from '../../../_metronic/partials'
import { SearchPage } from '../search'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Users',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: any
}

const UsersMore: FC<Props> = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('subscription')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [validate, setValidate] = useState<any>([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [pNumber, setPNumber] = useState('')
  const [password, setPassword] = useState('')
  const [provider, setProvider] = useState('')
  const [isDesigner, setIsDesigner] = useState('')
  const [count, setCount] = useState(0)

  useEffect(() => {
    get()
  }, [id])
  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    DesignerGet(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response?.data)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }

    if (isNullOrEmpty(email) || !isValidEmail(email)) {
      res.retType = 1
      validate['email'] = true
    }

    if (isNullOrEmpty(pNumber)) {
      res.retType = 1
      validate['pNumber'] = true
    }

    if (isNullOrEmpty(password) && isNullOrEmpty(data)) {
      res.retType = 1
      validate['password'] = true
    }
    if (isNullOrEmpty(provider)) {
      res.retType = 1
      validate['provider'] = true
    }
    if (isNullOrEmpty(isDesigner)) {
      res.retType = 1
      validate['isDesigner'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
            <span style={{visibility: 'hidden'}}></span>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{t('common:USERS.activate')}</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                {t('common:USERS.activate')}
              </span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                placeholder='name'
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                value={email}
                placeholder='email'
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateChange('email', e)
                }}
              ></input>
              {validate['email'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='mobile'
                onChange={(e) => {
                  setPNumber(e.target.value)
                  validateChange('pNumber', e)
                }}
                value={pNumber}
              ></input>
              {validate['pNumber'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setProvider(e.target.value)
                  validateChange('provider', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='provider'
                id=''
                value={provider}
              >
                <option value=''>{t('common:validSelect')}</option>
                <option value='google'>{t('common:USERS.google')}</option>
                <option value='facebook'>{t('common:USERS.facebook')}</option>
                <option value='grado'>{t('common:USERS.grado')}</option>
                <option value='web'>{t('common:USERS.web')}</option>
                <option value='admin'>{t('common:USERS.admin')}</option>
              </select>
              {validate['provider'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setIsDesigner(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='status'
                value={isDesigner}
                id=''
              >
                <option value={''}>{t('common:validSelect')}</option>

                <option value={'1'}>{t('common:USERS.designer')}</option>
                <option value={'0'}>{t('common:USERS.notDesigner')}</option>
              </select>
              {validate['isDesigner'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='pt-10' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                // onClick={isNullOrEmpty(data) ? save : update}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data.name}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header card-header-overflow border-1'>
          <div className='flex gap-8 col-lg-3 col-form-label w-full card-header-flex'>
            <div className='zurag py-8'>
              {isNullOrEmpty(data.image) ? (
                <div
                  className='no-zurag'
                  style={{
                    width: '120px',
                    height: '150px',
                    borderRadius: '10px',
                    backgroundColor: '#ecf0f1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  No Image
                </div>
              ) : (
                <img
                  src={data.image}
                  style={{borderRadius: '15px'}}
                  width={120}
                  height={150}
                  alt=''
                />
              )}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'>
              {!isNullOrEmpty(data.name) ? (
                <div className='w-200px flex gap-3 items-center'>
                  <KTIcon iconName='user' iconType='outline' className='fs-2' />
                  <span>{data.name}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.id) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='barcode' iconType='outline' className='fs-2' />
                  <span>{data.id}</span>
                </div>
              ) : null}

              {!isNullOrEmpty(data.email) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='message-text-2' iconType='outline' className='fs-2' />

                  <span>{data.email}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.mobile) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='phone' iconType='outline' className='fs-2' />
                  <span>{data.mobile}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.provider) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='social-media' iconType='outline' className='fs-2' />
                  <span>{data.provider}</span>
                </div>
              ) : null}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'>
              {!isNullOrEmpty(data.is_designer) ? (
                <div className='w-250px flex gap-3 items-center'>
                  <KTIcon iconName='design' iconType='outline' className='fs-2' />
                  <span>{data.is_designer == 1 ? 'Designer' : 'Not designer'}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.created_at) ? (
                <div className=' flex gap-3 items-center'>
                  <KTIcon iconName='calendar-2' iconType='outline' className='fs-2' />
                  <span>{dateTimeFormat(data.created_at)}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.bagts) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='dropbox' iconType='outline' className='fs-2' />
                  <span>{data.bagts}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.bagts_hugatsaa) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='time' iconType='outline' className='fs-2' />
                  {isNullOrEmpty(data.bagts_hugatsaa) ? (
                    <span className='badge badge-light-warning'>{t('common:USERS:inActive')}</span>
                  ) : (
                    <>
                      <span className='badge badge-light-success'>{t('common:USERS:active')}</span>
                      <span>{dateFormat(data.bagts_hugatsaa)}</span>
                    </>
                  )}
                </div>
              ) : null}

              {/* <button onClick={() => setShowModal(true)} className='btn btn-primary'>
                activate
              </button> */}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'></div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'subscription',
                })}
                onClick={() => setTab('subscription')}
                role='tab'
              >
                Subscription
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'letterbanner',
                })}
                onClick={() => setTab('letterbanner')}
                role='tab'
              >
                Letter banner
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'feedback',
                })}
                onClick={() => setTab('feedback')}
                role='tab'
              >
                Feedback
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'template',
                })}
                onClick={() => setTab('template')}
                role='tab'
              >
                Template
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'qr',
                })}
                onClick={() => setTab('qr')}
                role='tab'
              >
                QR
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'event',
                })}
                onClick={() => setTab('event')}
                role='tab'
              >
                Event
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Flipbook',
                })}
                onClick={() => setTab('Flipbook')}
                role='tab'
              >
                Flipbook
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'search',
                })}
                onClick={() => setTab('search')}
                role='tab'
              >
                Search
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'subscription' && !isNullOrEmpty(data?.id) ? (
              <Subscription type='component' uid={data?.id} />
            ) : null}
            {tab === 'letterbanner' && !isNullOrEmpty(data?.id) ? (
              <LetterBannerLast type='component' uid={data?.id} />
            ) : null}
            {tab === 'feedback' && !isNullOrEmpty(data?.id) ? (
              <Feedback type='component' uid={data?.id} />
            ) : null}
            {tab === 'template' && !isNullOrEmpty(data?.id) ? (
              <UserCreated type='component' uid={data?.id} />
            ) : null}
            {tab === 'qr' && !isNullOrEmpty(data?.id) ? (
              <Qr type='component' uid={data?.id} />
            ) : null}
            {tab === 'event' && !isNullOrEmpty(data?.id) ? (
              <Event type='component' uid={data?.id} />
            ) : null}
            {tab === 'Flipbook' && !isNullOrEmpty(data?.id) ? (
              <Flipbook type='component' uid={data?.id} />
            ) : null}
            {tab === 'search' && !isNullOrEmpty(data?.id) ? (
              <SearchPage type='component' uid={data?.id} />
            ) : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {UsersMore}
