import React, {FC, useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {isNullOrEmpty} from '../../../services/Utils'
import {
  CDN_URL,
  QRTravelImagesAdd,
  QRTravelImagesDelete,
  QRTravelImagesList,
  QRTravelLangAdd,
  QRTravelLangDelete,
  QRTravelLangUpdate,
  QRTravelText2Audio,
} from '../../../services/main'
import {
  BaseRequest,
  QRTravelImagesAddReq,
  QRTravelImagesListReq,
  QRTravelText2AudioReq,
  addQRTravelLangReq,
  editQRTravelLangReq,
  magicnum,
} from '../../../services/Request'
import {useParams} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import AddAudio from './addAudio'

interface IProps {
  item: any
  success: any
}
const TravelImages: FC<IProps> = ({item, success}) => {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  const [loadingadd, setLoadingadd] = useState(false)
  const [loading, setLoading] = useState(false)
  const [base64img, setBase64img] = useState<any>('')
  console.log(id)
  useEffect(() => {
    list()
  }, [])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = QRTravelImagesListReq
    req.filter.tid = id ?? ""
    QRTravelImagesList(req, onlistSuccess, onFailed, 'POST')
  }

  const onlistSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }

  const upload = () => {
    setLoading(true)
    var req = QRTravelImagesAddReq
    req.travel.tid = id ?? ''
    req.travel.image = base64img
    QRTravelImagesAdd(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setBase64img('')
    toast.success('Амжилттай')
    setLoading(false)
    list()
  }

  const deleteFunc = (id: any) => {
    console.log(id)
    QRTravelImagesDelete(id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = (response: any) => {
    list()
    toast.success('Амжилттай устгагдлаа')
  }
  const onFailed = (error: any) => {
    setLoading(false)
    console.log('error:', error)
  }

  //   const issetLang = (lang: any) => {
  //     var isset = false
  //     data.map((el: any) => {
  //       if (el?.lang == lang) {
  //         isset = true
  //         return
  //       }
  //     })
  //     return isset
  //   }

  const getBase64 = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBase64img(reader.result)
    }
  }
  return (
    <>
      <div className='edit-modal mobile:w-full w-800px' style={{marginLeft: '20px'}}>
        <div className='flex gap-10 items-center'>
          {base64img ? (
            <img style={{objectFit:"cover"}} height={150} width={240} src={base64img} alt='' />
          ) : (
            <input type='file' onChange={(e) => getBase64(e.target.files?.[0])} />
          )}

          {base64img.length > 0 ? (
            <div className='flex gap-4'>
              <button
                onClick={() => setBase64img('')}
                className='btn btn-light-warning h-40px flex items-center'
              >
                cancel
              </button>
              <button
                onClick={upload}
                disabled={loadingadd}
                className='btn btn-primary h-40px flex items-center'
              >
                {loadingadd ? 'uploading' : 'upload'}
              </button>
            </div>
          ) : null}
        </div>
        <div className='flex flex-col gap-2'>
          {data.map((el: any) => {
            return (
              <div className='flex gap-8 items-center'>
                <img style={{objectFit:"cover"}} height={150} width={240} src={CDN_URL + el.image} alt='' />
                <button
                  onClick={() => deleteFunc(el.id)}
                  className='btn btn-light-warning h-40px flex items-center'
                >
                  delete
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default TravelImages
