import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  dateFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
} from '../../../services/Utils'
import {
  BaseRequest,
  DTempleteChangeStatusReq,
  DTempleteListReq,
  QRListReq,
  QRTravelListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  DDesigners,
  DTemapleteChangeStatus,
  DTemapleteDelete,
  DTemapleteListWithTracking,
  QRList,
  QRTravelList,
  deleteQRCode,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
import { TravelEdit } from './edit'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const TravelList: FC<Props> = (props) => {
  const [params, setParams] = useSearchParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = QRTravelListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.category = category
    req.filter.name = name
    QRTravelList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
    updateShowModal(false)
  }
  const closeModal = (response: any) => {
    updateShowModal(false)
    list()
    toast.success("Амжилттай заслаа")
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const categoryRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{t(`common:magicnum.travelCategory.${cell}`)}</span>
  }
  
  const dateRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{dateFormat(cell)}</span>
  }
  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex items-center gap-4'>
        <div style={{display: 'flex', gap: 10}}>
          {isNullOrEmpty(row.cover) ? (
            <div
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '10px',
                backgroundColor: '#ecf0f1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {cell.substring(0, 1)}
            </div>
          ) : (
            <div style={{position: 'relative'}}>
              <img
                src={CDN_URL + row.cover}
                width={60}
                height={60}
                style={{borderRadius: '6px', cursor: 'pointer', objectFit:"cover"}}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-8px',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor: '#00000090',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <i
                  onClick={() => {
                    // setEditCoverModal(true)
                    setItem(row)
                  }}
                  className='fa-solid fa-rotate fa-xl cursor-pointer '
                  style={{
                    color: 'white',
                  }}
                ></i>
              </div>
            </div>
          )}
          <div
            style={{display: 'flex', flexDirection: 'column', gap: '2px', justifyContent: 'center'}}
          >
            <span style={{fontWeight: '600', fontSize: '14px'}}>{cell}</span>
            <span>
              {getCity(row.city)}- {getDistrict(row.district, row.city)}
            </span>
          </div>
        </div>
      </div>
    )
  }
  const idRowFormat = (cell: any, row: any) => {
    return <span style={{backgroundColor:"yellow"}} className='flex justify-center'> {cell} </span>
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <Link to={'/travel/' + row.id}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    deleteQRCode(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = DTempleteChangeStatusReq
    req.desgin.id = row.id
    if (row.status == 0) {
      req.desgin.status = JSON.stringify(1)
    }
    if (row.status == 1) {
      req.desgin.status = JSON.stringify(0)
    }

    DTemapleteChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const getCity = (id: any) => {
    var name = ''
    magicnum.address_city.map((el) => {
      if (el.id == id) {
        name = el.name
      }
    })
    return name
  }
  const getDistrict = (id: any, cityId: any) => {
    var name = ''
    magicnum.address_district.map((el) => {
      if (el.id == id && el.city_id == cityId) {
        name = el.name
      }
    })
    return name
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'QR name',
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Category',
        dataField: 'category',
        dataSort: false,
        dataFormat: categoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'ID',
        dataField: 'id',
        dataSort: false,
        dataFormat: idRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Langs',
        dataField: 'totallangs',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Total views',
        dataField: 'totalview',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Date',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <TravelEdit t={t} item={item} type='edit' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Travel</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Name'
                      value={name}
                    />
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={category}
                    >
                      <option value=''>{t('common:ELEMENT.select_cat')}</option>
                      {magicnum.travel_category.map((pitem) => {
                        return (
                          <option value={pitem}>{t(`common:magicnum.travelCategory.${pitem}`)}</option>
                        )
                      })}
                    </select>
                  </div>
                  
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Travel list</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} spots</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {TravelList}
