import {FC, useEffect, useState} from 'react'
import {isNullOrEmpty, isValidEmail} from '../../../services/Utils'
import {
  DesignerAdd,
  DesignerUpdate,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  DesignerAddReq,
  DesignerUpdateReq,
  magicnum,
} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const UserCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [pNumber, setPNumber] = useState('')
  const [password, setPassword] = useState('')
  const [provider, setProvider] = useState('')
  const [isDesigner, setIsDesigner] = useState('')
  const [validate, setValidate] = useState<any>([])

  useEffect(() => {
    setName(item?.name)
    setEmail(item?.email)
    setPNumber(item?.mobile)
    setProvider(item?.provider)
    setIsDesigner(item?.is_designer)
  }, [item])

  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = DesignerAddReq
      req.designer.mobile = pNumber
      req.designer.email = email
      req.designer.password = password
      req.designer.name = name
      req.designer.provider = provider
      req.designer.is_designer = isDesigner
      DesignerAdd(req, onRegisterSuccess, onFailed, 'POST')
    }
  }
  const onRegisterSuccess = () => {
    setLaoding(false)
    onAddSuccess('Шинэ хэрэглэгч амжилттай нэмлээ')
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = DesignerUpdateReq
      req.designer.name = name
      req.designer.mobile = pNumber
      req.designer.email = email
      req.designer.provider = provider
      req.designer.is_designer = isDesigner
      req.designer.id = item.id
      DesignerUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }

    if (isNullOrEmpty(email) || !isValidEmail(email)) {
      res.retType = 1
      validate['email'] = true
    }

    if (isNullOrEmpty(pNumber)) {
      res.retType = 1
      validate['pNumber'] = true
    }

    if (isNullOrEmpty(password) && isNullOrEmpty(item)) {
      res.retType = 1
      validate['password'] = true
    }
    if (isNullOrEmpty(provider)) {
      res.retType = 1
      validate['provider'] = true
    }
    if (isNullOrEmpty(isDesigner)) {
      res.retType = 1
      validate['isDesigner'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>
            {isNullOrEmpty(item) ? t('common:USERS.add') : t('common:USERS.update')}
          </h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {isNullOrEmpty(item) ? t('common:USERS.add') : t('common:USERS.update')}
          </span>
        </div>

        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:USERS.name')}
            onChange={(e) => {
              setName(e.target.value)
              validateChange('name', e)
            }}
            value={name}
          ></input>
          {validate['name'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid'
            value={email}
            placeholder={t('common:USERS.email')}
            onChange={(e) => {
              setEmail(e.target.value)
              validateChange('email', e)
            }}
          ></input>
          {validate['email'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <input
            type='number'
            className='form-control form-control-solid'
            placeholder={t('common:USERS.pNumber')}
            onChange={(e) => {
              setPNumber(e.target.value)
              validateChange('pNumber', e)
            }}
            value={pNumber}
          ></input>
          {validate['pNumber'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        {isNullOrEmpty(item) ? (
          <div className='mb-4'>
            <input
              className='form-control form-control-solid'
              placeholder={t('common:USERS.password')}
              onChange={(e) => {
                setPassword(e.target.value)
                validateChange('password', e)
              }}
              value={password}
            ></input>
            {validate['password'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        ) : null}
        
        <div className='mb-4'>
          <select
            onChange={(e) => {
              setProvider(e.target.value)
              validateChange('provider', e)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            id=''
            value={provider}
          >
            <option value=''>{t('common:validSelect')}</option>
            <option value='google'>{t('common:USERS.google')}</option>
            <option value='facebook'>{t('common:USERS.facebook')}</option>
            <option value='grado'>{t('common:USERS.grado')}</option>
            <option value='web'>{t('common:USERS.web')}</option>
            <option value='admin'>{t('common:USERS.admin')}</option>
          </select>
          {validate['provider'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-4'>
          <select
            onChange={(e) => {
              setIsDesigner(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='status'
            value={isDesigner}
            id=''
          >
            <option value={''}>{t('common:validSelect')}</option>

            <option value={'1'}>{t('common:USERS.designer')}</option>
            <option value={'0'}>{t('common:USERS.notDesigner')}</option>
          </select>
          {validate['isDesigner'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {UserCreate}
