import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FlipbookStatListReq} from '../../../../services/Request'
import {FlipbookStatOSList} from '../../../../services/main'
import DeviceScanChart from '../../../../_metronic/layout/components/Charts/deviceStats'

type Props = {
  flipID?: any
}
const ScanedOs: FC<Props> = (props) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()
  const [loadingList, setLoadingList] = useState(false)
  const [osData, setOsData] = useState<any>([])
  const colors = ['#555555', '#f65314', '#3DDC84']

  useEffect(() => {
    statlist(props.flipID)
  }, [props.flipID])

  const statlist = (id: any) => {
    setLoadingList(true)
    var req = FlipbookStatListReq
    req.filter.flipbook.id = id
    FlipbookStatOSList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    if (response.data.length > 0) {
      setOsData([])
      osData.push(['Үйлдийн системийн төрөл', 'Уншуулсан тоо', {role: 'style'}])
      response.data.map((el: any, i: any) => {
        return osData.push([el.ios, el.niit, colors[i]])
      })
      setOsData(osData)
    }
    setLoadingList(false)
  }
  const onFailed = (error: any) => {
    setLoadingList(false)
    console.log('error:', error)
  }

  return (
    <div className='total-card shadow-smShadow flex flex-col cursor-pointer'>
      {osData.length > 0 ? (
        <div className='top-20'>
          <DeviceScanChart data={osData} />
        </div>
      ) : loadingList == true ? (
        <span className='text-center mt-5'>{t('common:loading')}</span>
      ) : !loadingList ? (
        <div className='no-stat'>
          <div className='ex-mark '>!</div>
          <span className='text-textTri text-lg w-[400px] font-medium'>Have not read yet</span>
        </div>
      ) : null}
    </div>
  )
}
export default ScanedOs
