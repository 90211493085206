import {FC, useState} from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {KTIcon} from '../../../../_metronic/helpers'
import {dateFormat, dateTimeFormat, isNullOrEmpty, isValidEmail} from '../../../../services/Utils'
import {Feedback} from '../../feedback'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Elements',
    path: '/element',
    isSeparator: false,
    isActive: false,
  },
]

const ElementsMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [tab, setTab] = useState('feedback')

  return (
    <>
      {/* {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
            <span style={{visibility: 'hidden'}}></span>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{t('common:USERS.activate')}</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                {t('common:USERS.activate')}
              </span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                placeholder='name'
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                value={email}
                placeholder='email'
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateChange('email', e)
                }}
              ></input>
              {validate['email'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='mobile'
                onChange={(e) => {
                  setPNumber(e.target.value)
                  validateChange('pNumber', e)
                }}
                value={pNumber}
              ></input>
              {validate['pNumber'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setProvider(e.target.value)
                  validateChange('provider', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='provider'
                id=''
                value={provider}
              >
                <option value=''>{t('common:validSelect')}</option>
                <option value='google'>{t('common:USERS.google')}</option>
                <option value='facebook'>{t('common:USERS.facebook')}</option>
                <option value='grado'>{t('common:USERS.grado')}</option>
                <option value='web'>{t('common:USERS.web')}</option>
                <option value='admin'>{t('common:USERS.admin')}</option>
              </select>
              {validate['provider'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setIsDesigner(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='status'
                value={isDesigner}
                id=''
              >
                <option value={''}>{t('common:validSelect')}</option>

                <option value={'1'}>{t('common:USERS.designer')}</option>
                <option value={'0'}>{t('common:USERS.notDesigner')}</option>
              </select>
              {validate['isDesigner'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='pt-10' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                // onClick={isNullOrEmpty(data) ? save : update}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null} */}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{id}</PageTitle>
      <div className='card'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'feedback',
                })}
                onClick={() => setTab('feedback')}
                role='tab'
              >
                Feedback
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'feedback' && !isNullOrEmpty(id) ? (
              <Feedback type='component' relID={id} />
            ) : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {ElementsMore}
